import 'assets/fonts/icomoon-ultimate/icomoon-ultimate-webfont.css';
import 'assets/fonts/icons.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'promise-polyfill/src/polyfill';
import Navigation from 'components/Navigation/Navigation';
import CalendarCard from 'components/Pages/CalendarCard';
import Footer from 'components/Pages/Footer';
import Frontpage from 'components/Pages/Frontpage';
import Header from 'components/Pages/Header';
import Login from 'components/Pages/Login';
import NotFound from 'components/Pages/NotFound';
import Page from 'components/Pages/Page';
import Recover from 'components/Pages/Recover';
import TableCard from 'components/Pages/TableCard';
import * as Cookies from 'js-cookie';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UserType } from 'store';
import { Post, Put } from 'utils/Api';
import { WebSock } from 'WebSocket';
import './button.css';
import './fonts.css';
import './styles.css';

import moment from 'moment';
import 'moment/locale/fi';
moment.locale('fi');

export const ws = new WebSock();

type Props = {
  setStore: (data: any) => void;
  user: UserType;
};
type State = {
  logged: number;
};

class App extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      logged: 0
    };
  }

  async updateData() {
    const { user } = this.props;
    try {
      const translations = await Post('translation', {}, { limit: 1000 });
      this.props.setStore({ translations });
    } catch (e) {
      // Jatka
    }

    try {
      const types = await Post('types');
      const offices = await Post('office', { active: 'active' });
      types.settings = {
        ...user?.settings,
        company: types?.user?.company,
        ...(offices?.data?.length === 1 && { office: offices?.data?.[0] })
      };
      this.setState({ logged: 1 });
      this.props.setStore({ user: types });
    } catch (e) {
      this.setState({ logged: -1 });
    }
  }

  componentDidMount() {
    this.updateData();

    // Collect errors for debugging
    window.onerror = (msg, url, lineNo, columnNo, error) => {
      Put('errorlog', { msg, url, lineNo, columnNo, error });
      return false;
    };
  }

  render() {
    const { logged } = this.state;
    const { user } = this.props;

    return (
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/recover/:secret" component={Recover} />
          {(logged === 1 && (
            <React.Fragment>
              <Header />
              <Navigation />
              <ToastContainer />
              <Switch>
                <Route exact path="/schedule">
                  <Page>
                    <CalendarCard />
                  </Page>
                </Route>
                {_.map(user?.links, (v, k) => (
                  <Route key={k} exact path={'/' + k}>
                    <Page>
                      <TableCard table={k} />
                    </Page>
                  </Route>
                ))}
                <Route exact path="/">
                  <Page>
                    <Frontpage />
                  </Page>
                </Route>
                <Route path="*">
                  <Page>
                    <NotFound />
                  </Page>
                </Route>
              </Switch>
              <Footer />
            </React.Fragment>
          )) ||
            (logged === -1 && (
              <Route exact path="*">
                <Redirect to="/login" />
              </Route>
            ))}
        </Switch>
      </BrowserRouter>
    );
  }
}

export default connect(
  (state: any) => ({
    user: state?.payload?.user
  }),
  {
    setStore: (payload: any) => ({ type: 'set', payload: payload })
  }
)(App);
