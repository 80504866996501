import styled, { css } from 'styled-components';

export const Ellipsis = styled.div<{ nowrap?: boolean }>`
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: initial;
  ${(props) =>
    props?.nowrap &&
    css`
      white-space: nowrap;
    `}
`;
