import BaseDropdown from 'components/BaseDropdown/BaseDropdown';
import Drop from 'components/UI/Drop';
import Image from 'components/UI/Image';
import * as Cookies from 'js-cookie';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { UserType } from 'store';
import styled from 'styled-components';
import { ButtonUI } from 'ui/Button';
import { ClearButton } from 'ui/ClearButton';
import { Post } from 'utils/Api';
import { DateTime, SqlDate } from 'utils/Dates';
import Lang from 'utils/Lang';

import logo_light from 'assets/images/logo_light.svg';
import { ws } from 'App';

export const NotifyRow = styled.div`
  margin: 0;
  padding: 1rem 1rem 0 1rem;

  :hover {
    background: #f8f8f8;
    cursor: pointer;
  }
`;

type Props = {
  user: UserType;
  setStore: (data: any) => void;
};
type State = {
  open: boolean;
  nextSchedule: any;
  notifys: any;
};

export class Header extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      open: false,
      nextSchedule: null,
      notifys: null
    };
  }

  async updateData() {
    const { user } = this.props;

    const nextSchedule = await Post(
      'schedule',
      {
        active: 'active',
        office: user?.settings?.office?.id,
        start: '>=' + SqlDate(Date.now())
      },
      {
        sort: 'start',
        dir: 'asc',
        limit: 1
      }
    );

    const notifys = await Post(
      'notify',
      {
        active: 'active',
        office: user?.settings?.office?.id,
        mode: 2
      },
      {
        sort: 'modified',
        dir: 'desc'
      }
    );

    this.setState({ nextSchedule, notifys });
  }

  componentDidMount() {
    this.updateData();

    ws.onMessage((data: any) => {
      if (data?.update === 'schedule' || data?.update === 'notify') {
        this.updateData();
        console.log('WebSocket -> Header updated');
      }
    });
  }

  render() {
    const { setStore, user } = this.props;
    const { open, nextSchedule, notifys } = this.state;

    return (
      <nav className="navbar navbar-expand-md navbar-expand-lg bg-dark p-2 pl-4 pr-4 d-flex">
        <div className="col-auto pl-2 mr-md-5">
          <img
            src={logo_light}
            alt="Oitis.fi"
            className="d-inline-block"
            style={{ height: 26 }}
          />
        </div>
        <ClearButton
          className="navbar-toggler"
          onClick={() => {
            this.setState({ open: !open });
          }}
        >
          <i className="icon-menu7 text-light" />
        </ClearButton>
        <div className={'navbar-collapse' + (!open ? ' collapse' : '')}>
          <div className="mr-auto navbar-nav">
            {user?.user?.super === true && (
              <ClearButton
                onClick={() => {
                  setStore({
                    user: {
                      ...user,
                      super: { ...user?.super, sidebar: !user?.super?.sidebar }
                    }
                  });
                }}
              >
                <i className="icon-menu7 text-light" />
              </ClearButton>
            )}
          </div>
          <div className="navbar-nav pr-1">
            <div className="mt-auto mb-auto">
              <NavLink
                to="schedule"
                className="btn text-white p-0 mr-3"
                style={{ fontSize: '13px' }}
              >
                <ButtonUI clear style={{ height: '100%', color: '#fff' }}>
                  {(nextSchedule?.data?.[0] && (
                    <>
                      <Lang>next reservation</Lang>:{' '}
                      {DateTime(nextSchedule?.data?.[0].start)}
                    </>
                  )) || <Lang>no upcoming reservations</Lang>}
                </ButtonUI>
              </NavLink>
            </div>

            <BaseDropdown
              style={{ width: 'auto' }}
              header={(onClose) => (
                <>
                  <i className="icon-bell2" />
                  <span className="d-md-none ml-2">
                    <Lang>notifys</Lang>
                  </span>
                  <span className="badge badge-pill badge-mark bg-orange-400 border-orange-400 ml-auto ml-md-0" />
                </>
              )}
              content={(onClose) => (
                <>
                  <div
                    className="dropdown-content-header p-3"
                    style={{ width: '400px' }}
                  >
                    <span className="font-weight-bold mt-2" style={{ color: '#555', fontSize: '14px' }}>
                      <Lang>notifys</Lang>
                    </span>
                    <div>
                      <i className="icon-compose" />
                    </div>
                  </div>
                  <div className="p-0">
                    {_.map(notifys?.data, (v: any, k: any) => (
                      <NotifyRow key={k} className="row">
                        <div className="mr-3 position-relative pt-2">
                          <i
                            className="icon-notification icon-2x"
                            style={{ color: '#e65240' }}
                          />
                        </div>
                        <div
                          className="media-body"
                          style={{
                            borderBottom: '1px solid #eee',
                            paddingBottom: '0.5rem'
                          }}
                        >
                          <div className="media-title">
                            <span style={{ fontSize: '0.85rem' }}>
                              {/* <span className="font-weight-semibold">James Alexander</span> */}
                              <span className="text-muted float-right ml-3">
                                {moment(v?.created).format('HH:mm')}
                              </span>
                            </span>
                          </div>
                          <span
                            className="text-muted"
                            style={{ fontSize: '0.85rem' }}
                          >
                            {
                              _.map((v?.message || '').split('\n'), v => <div>{v}</div>)
                            }
                          </span>
                        </div>
                      </NotifyRow>
                    ))}
                  </div>
                </>
              )}
            />

            <div style={{ minWidth: '100px' }}>
              <BaseDropdown
                header={(onClose) => (
                  <>
                    <Image
                      thumb
                      style={{ width: 20, height: 20 }}
                      className="rounded-circle mr-2 gray-icon"
                      src={user?.user?.company?.picture}
                    />
                    {user?.user?.name}
                  </>
                )}
                content={(onClose) => (
                  <>
                    <button className="dropdown-item">
                      <div className="flex-auto">
                        <i className="icon-arrow-right5 mr-2 dropdown-icon" />
                        <Lang>profile</Lang>
                      </div>
                      <small />
                    </button>

                    <button
                      className="dropdown-item"
                      onClick={() => {
                        Cookies.remove('token');
                        window.location.replace('login');
                      }}
                    >
                      <div className="flex-auto">
                        <i className="icon-arrow-right5 mr-2 dropdown-icon" />
                        <Lang>log out</Lang>
                      </div>
                      <small />
                    </button>
                  </>
                )}
              />
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default connect(
  (state: any) => ({
    user: state?.payload?.user
  }),
  {
    setStore: (payload: any) => ({ type: 'set', payload: payload })
  }
)(Header);
