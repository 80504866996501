import styled, { css } from 'styled-components';

export const ModalBackdrop = styled.div`
  opacity: 0.5;
  background: #333;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100vmax;
  height: 100vmax;
  transform: none !important;
`;
