import { Fade } from 'components/UI/Fade';
import _ from 'lodash';
import React from 'react';
import {
  Dropdown,
  DropdownArrow,
  DropdownField,
  DropdownMenu,
  DropdownMenuOuter,
  DropdownOuter
} from './BaseDropdownElements';

type Props = {
  header: (onClose: () => void) => any;
  content: (onClose: () => void) => any;
  onFocus?: () => void;
  onBlur?: () => void;
  style?: React.CSSProperties;
};

type State = {
  open: boolean;
};

export default class BaseDropdown extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      open: false
    };
  }

  close() {
    const { onBlur } = this.props;
    this.setState({ open: false }, () => {
      onBlur && onBlur();
    });
  }

  render() {
    const { content, header, onFocus, onBlur, style } = this.props;
    const { open } = this.state;

    return (
      <DropdownOuter>
        <Dropdown
          show={open}
          tabIndex={0}
          onFocus={(e) => {
            console.log("focus");
            this.setState({ open: true }, () => {
              onFocus && onFocus();
            });
          }}
          onBlur={(e) => {
            const next = e.relatedTarget || document.activeElement;
            if (next instanceof Element) {
              if (!e.currentTarget.contains(next)) {
                this.close();
              }
            }
          }}
        >
          <DropdownField onMouseDown={(e)=>{
            console.log("open", open);
            if (open) {
              e.preventDefault(); e.stopPropagation();
              this.close();
            } else {
              this.setState({ open: true }, () => {
                onFocus && onFocus();
              });
            }
            console.log("click btn");
          }}>
            <DropdownArrow>
              <i
                className={
                  'icon-arrow-down5' + (open ? ' icon-rotate-180' : '')
                }
              />
            </DropdownArrow>
            {header(() => {
              this.close();
            })}
          </DropdownField>
          <Fade
            show={open}
            duration={100}
            transition="slidedown"
            content={(fade) => (
              <DropdownMenuOuter
                onClick={(e) => {
                  console.log("click");
                  if (e.currentTarget === e.target) this.setState({ open: false });
                }}
              >
                <DropdownMenu style={{ ...style, ...fade }}>
                  {content(() => {
                    this.close();
                  })}
                </DropdownMenu>
              </DropdownMenuOuter>
            )}
          />
        </Dropdown>
      </DropdownOuter>
    );
  }
}
