import BaseDropdown from 'components/BaseDropdown/BaseDropdown';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { InputUI } from 'ui/InputUI';
import { SqlFormat, TryDateFormat } from 'utils/Dates';
import { lang } from 'utils/Lang';
import {
  DatepickerButton,
  DatepickerDay,
  DatepickerHeader,
  DatepickerTime,
  DatepickerWeekday
} from './DatePickerElements';

import 'moment/locale/fi';
moment.locale('fi');

type Props = {
  value: any;
  range?: any;
  min?: any;
  max?: any;
  onChange: (data: any) => void;
  readOnly?: boolean;

  onlyDate?: boolean;
  onlyTime?: boolean;
};

type State = {
  viewdate: moment.Moment;
  timestep: number;
  show: boolean;
};

export default class DatePickerV2 extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      viewdate: moment(),
      timestep: 15,
      show: false
    };
  }
  timeRef = React.createRef<HTMLDivElement>();

  render() {
    const {
      value,
      onChange,
      min,
      max,
      onlyDate,
      onlyTime,
      readOnly
    } = this.props;
    const { viewdate, timestep, show } = this.state;

    let datetime = moment(value);
    if (onlyTime) datetime = moment(value, [moment.ISO_8601, 'HH:mm:ss']);
    const dateStart = viewdate.clone().startOf('month').weekday(0);
    const dateEnd = viewdate.clone().endOf('month').weekday(7);

    return (
      <BaseDropdown
        style={{
          ...((!onlyTime && { minWidth: '350px' }) || { maxWidth: '100px' })
        }}
        onBlur={() => {
          this.setState({ show: false });
        }}
        onFocus={() => {
          if (show === true) return;
          if (!datetime.isValid()) {
            datetime = moment();
            if (onlyTime) {
              // onChange(datetime.format('HH:mm'));
              return;
            }
            // onChange(datetime);
          }
          this.setState({ show: true, viewdate: datetime }, () => {
            const scrollY =
              ((datetime.hour() * 60) / timestep +
                datetime.minute() / timestep) *
              35;
            this.timeRef.current?.scrollTo(0, scrollY - (250 - 35) / 2);
          });
        }}
        header={(onClose) => (
          <>
            {!onlyDate && (
              <InputUI
                tabIndex={-1}
                readOnly={readOnly}
                value={TryDateFormat(datetime, 'HH:mm', lang('not set'))}
                style={{
                  ...(!onlyTime && { maxWidth: '101px', borderRight: 0 })
                }}
                onChange={(e) => {
                  // console.log('changed', e);
                  const time = moment(e.target.value, [moment.ISO_8601, 'HH:mm:ss']);
                  const newValue = (datetime?.isValid() ? datetime : moment())
                    .clone()
                    .hour(time.hour())
                    .minute(time.minute());
                  onChange(newValue.format(SqlFormat));
                }}
                spellCheck={false}
              />
            )}
            {!onlyTime && (
              <InputUI
                tabIndex={-1}
                readOnly={readOnly}
                value={TryDateFormat(
                  datetime,
                  'ddd, D. MMMM y',
                  lang('not set')
                )}
                onChange={(e) => {
                  console.log('changed', e);
                }}
                spellCheck={false}
              />
            )}
          </>
        )}
        content={(onClose) => (
          <>
            {!onlyTime && (
              <DatepickerHeader>
                <DatepickerButton
                  onClick={() => {
                    this.setState({ viewdate: viewdate.subtract(1, 'month') });
                  }}
                >
                  <i className="icon-arrow-left5" />
                </DatepickerButton>
                <div style={{ flex: 1, textAlign: 'center', padding: '6px' }}>
                  {viewdate.format('MMMM')}
                  <input
                    type="text"
                    style={{
                      outline: 0,
                      border: 0,
                      background: 0,
                      width: '40px',
                      marginLeft: '10px'
                    }}
                    value={viewdate.format('Y')}
                    onChange={(e) => {
                      this.setState({
                        viewdate: viewdate.year(_.toInteger(e.target.value))
                      });
                    }}
                    onKeyDown={(e) => {
                      // e.preventDefault();
                      // e.stopPropagation();
                    }}
                  />
                </div>
                <DatepickerButton
                  onClick={() => {
                    this.setState({ viewdate: viewdate.add(1, 'month') });
                  }}
                >
                  <i className="icon-arrow-right5" />
                </DatepickerButton>
              </DatepickerHeader>
            )}

            <div
              style={{
                display: 'flex',
                position: 'relative',
                maxHeight: '250px'
              }}
            >
              {!onlyDate && (
                <div
                  style={{ minWidth: '100px', overflow: 'auto' }}
                  ref={this.timeRef}
                >
                  {show &&
                    _.map(_.range(Math.floor((24 * 60) / timestep)), (v, k) => {
                      const hour = Math.floor((v * timestep) / 60);
                      const minute = (v * timestep) % 60;
                      const time = datetime.clone().hour(hour).minute(minute);
                      return (
                        <DatepickerTime
                          key={k}
                          active={
                            datetime.hour() === hour &&
                            datetime.minute() >= minute &&
                            datetime.minute() < minute + timestep
                          }
                          range={
                            (min &&
                              time.isSameOrAfter(moment(min), 'minute') &&
                              time.isSameOrBefore(datetime, 'minute')) ||
                            (max &&
                              time.isSameOrBefore(moment(max), 'minute') &&
                              time.isSameOrAfter(datetime, 'minute'))
                          }
                          isStart={
                            (min && time.isSame(moment(min), 'minute')) ||
                            (!min && max && time.isSame(datetime, 'minute'))
                          }
                          isEnd={
                            (max && time.isSame(moment(max), 'minute')) ||
                            (!max && min && time.isSame(datetime, 'minute'))
                          }
                          onClick={() => {
                            const newValue = (datetime?.isValid() ? datetime : moment())
                              .clone()
                              .hour(hour)
                              .minute(minute);
                            if (onlyTime) {
                              onChange(newValue.format('HH:mm'));
                              return;
                            }
                            onChange(newValue.format(SqlFormat));
                          }}
                        >
                          <div>
                            {_.padStart('' + hour, 2, '0')}:
                            {_.padStart('' + minute, 2, '0')}
                          </div>
                        </DatepickerTime>
                      );
                    })}
                </div>
              )}

              {!onlyTime && (
                <div
                  style={{
                    display: 'flex',
                    borderLeft: '1px solid #00000022',
                    flexWrap: 'wrap'
                  }}
                >
                  {show &&
                    _.map(_.range(7), (v, k) => (
                      <DatepickerWeekday
                        key={k}
                        style={{ height: '30px', fontWeight: 'bold' }}
                      >
                        <div>
                          {moment()
                            .days(v + 1)
                            .format('ddd')}
                        </div>
                      </DatepickerWeekday>
                    ))}
                  {show &&
                    _.map(_.range(dateEnd.diff(dateStart, 'd')), (v, k) => {
                      const date = moment(dateStart).add(v, 'd');
                      return (
                        <DatepickerDay
                          key={k}
                          active={date.isSame(datetime, 'd')}
                          range={
                            (min &&
                              date.isSameOrAfter(moment(min), 'd') &&
                              date.isSameOrBefore(datetime, 'd')) ||
                            (max &&
                              date.isSameOrBefore(moment(max), 'd') &&
                              date.isSameOrAfter(datetime, 'd'))
                          }
                          isStart={
                            (min && date.isSame(moment(min), 'd')) ||
                            (!min && max && date.isSame(datetime, 'd'))
                          }
                          isEnd={
                            (max && date.isSame(moment(max), 'd')) ||
                            (!max && min && date.isSame(datetime, 'd'))
                          }
                          grayed={
                            !date.isSame(viewdate, 'month') ||
                            date.isBefore(moment(), 'd')
                          }
                          disabled={
                            // (min && date.isBefore(moment(min), 'd')) ||
                            // (max && date.isAfter(moment(max), 'd'))
                            false
                          }
                          onClick={() => {
                            const newValue = (datetime?.isValid() ? datetime : moment())
                            .clone()
                              .year(date.year())
                              .month(date.month())
                              .date(date.date());
                            if (onlyTime) {
                              onChange(newValue.format('HH:mm'));
                              return;
                            }
                            onChange(newValue.format(SqlFormat));
                          }}
                        >
                          <div>{date.date()}</div>
                        </DatepickerDay>
                      );
                    })}
                </div>
              )}
            </div>
          </>
        )}
      />
    );
  }
}
