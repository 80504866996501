import React from 'react';
import Input from './Input';
import _ from 'lodash';
import { ButtonUI } from 'ui/Button';
import { Row } from 'ui/Row';

type Props = {
  value: any;
  onChange: (data: any) => void;
  readOnly?: boolean;
};
type State = {};

export default class JsonTable extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  decode() {
    const { value } = this.props;
    let json: any = [];
    try {
      // const dec = JSON.parse(value);
      const dec = value;
      json = _.map(dec, (v, k) => [k, v]);
    } catch (e) {}
    return json;
  }

  encode(json: any) {
    const data: any = {};
    _.map(json, (v) => {
      data[v[0]] = v[1];
    });
    // return JSON.stringify(data);
    return data;
  }

  render() {
    const { onChange } = this.props;
    const json = this.decode();

    return (
      <>
        <div>
          {_.map(json, (v, k) => {
            return (
              <Row key={k}>
                <div className="col pr-0">
                  <Input
                    type="text"
                    value={v[0]}
                    onChange={(e) => {
                      v[0] = e.target.value;
                      onChange(this.encode(json));
                    }}
                  />
                </div>
                <div className="col">
                  <Input
                    type="text"
                    value={v[1]}
                    onChange={(e) => {
                      v[1] = e.target.value;
                      onChange(this.encode(json));
                    }}
                  />
                </div>
              </Row>
            );
          })}
        </div>
        <div className="row">
          <div className="col" />
          <div className="col-auto">
            <ButtonUI
              onClick={() => {
                json.push(['', '']);
                onChange(this.encode(json));
              }}
            >
              Lisää rivi
            </ButtonUI>
          </div>
        </div>
      </>
    );
  }
}
