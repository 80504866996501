import Offices from 'components/Frontpage/StatsOffices';
import StatsSales from 'components/Frontpage/StatsSales';
import StatsOffices from 'components/Frontpage/StatsOffices';
import React from 'react';
import { Row } from 'ui/Row';

type Props = {};
type State = {};

export default class Frontpage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Row>
        <StatsSales />
        <StatsOffices />
      </Row>
    );
  }
}
