import styled, { css } from 'styled-components';

export const Row = styled.div<{ column?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;

  ${(props) =>
    props?.column &&
    css`
      @media (min-width: 768px) {
        flex-direction: row;
        flex-wrap: nowrap;
      }
    `}
`;
