import React from 'react';
import _ from 'lodash';
import styled, { css } from 'styled-components';

const BackdropDiv = styled.div`
  position: fixed;
  background: none;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100vmax;
  height: 100vmax;
`;

type Props = {
  onFocus?: () => void;
  onClick?: React.MouseEventHandler;
};
type State = {};

export default class Backdrop extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const { onFocus, onClick } = this.props;
    return (
      <BackdropDiv
        onClick={(e) => {
          onClick && onClick(e);
          onFocus && onFocus();
        }}
        onTouchStart={() => {
          onFocus && onFocus();
        }}
        onMouseDown={() => {
          onFocus && onFocus();
        }}
        onTouchMove={() => {
          onFocus && onFocus();
        }}
        onMouseMove={() => {
          onFocus && onFocus();
        }}
        onMouseEnter={() => {
          onFocus && onFocus();
        }}
        onMouseOver={() => {
          onFocus && onFocus();
        }}
        onMouseUp={() => {
          onFocus && onFocus();
        }}
      />
    );
  }
}
