import BaseDropdown from 'components/BaseDropdown/BaseDropdown';
import DropdownV2 from 'components/Dropdown/DropdownV2';
import TypedInput from 'components/Forms/TypedInput';
import { Fade } from 'components/UI/Fade';
import { TableUI } from 'components/UI/TableUI';
import _, { wrap } from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { ButtonUI } from 'ui/Button';
import { ClearButton } from 'ui/ClearButton';
import { Loader } from 'ui/Loader';
import { Row } from 'ui/Row';
import { MinutesToString, TryDateFormat } from 'utils/Dates';
import Lang, { lang } from 'utils/Lang';
import BaseModal from './BaseModal';
import { dialog } from './Dialog';
import ModalFooter from './ModalFooter';
import ModalPage from './ModalPage';
import ModalPricing from './ModalPricing';
import ModalTable from './ModalTable';

type Props = {
  user: any;
  style?: React.CSSProperties;
  data: any;
  onClose: () => void;
};
type State = {
  data: any;
  unsaved: boolean;
  loading: boolean;

  pricingModal: {
    show: boolean;
    data: any;
    add?: boolean;
  } | undefined;
};

class Modal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      data: _.cloneDeep({
        ..._.mapValues(props.user?.types?.resource, (v) => v?.default),
        ...props.data
      }),
      unsaved: false,
      loading: true,
      pricingModal: undefined
    };
  }

  render() {
    const { user, style, onClose } = this.props;
    const { unsaved, loading, data, pricingModal } = this.state;

    return (
      <BaseModal
        style={style}
        unsaved={unsaved}
        hasId={!!data?.id}
        title={<Lang>{'resources'}</Lang>}
        onClose={onClose}
        footer={
          <ModalFooter
            table={'resource'}
            data={data}
            loading={loading}
            setLoading={(loading) => {
              this.setState({ loading });
            }}
            onClose={onClose}
          />
        }
      >
        {loading && <Loader />}
        <ModalPage
          title={<Lang>resource</Lang>}
          className="col-md-6 col-lg mt-2"
          table="resource"
          data={data}
          customRender={(v, k) => k === 'pricing'}
          onLoad={() => {
            this.setState({ loading: false });
          }}
          onChange={(e: any) => {
            this.setState({ data: e, unsaved: !loading });
          }}
        />
        <Fade
          show={true}
          transition="slide"
          content={(style) => (
            <>
              <div
                className="col-md-6 col-lg mt-2"
                style={{ width: '100vw', maxWidth: '600px', ...style }}
              >
                <Row className="mb-3">
                  <div className="col font-weight-bold">
                    <Lang>pricing</Lang>
                  </div>
                </Row>
                <TableUI>
                  <thead>
                    <tr>
                      <th style={{ width: '32px' }}></th>
                      {
                        // Listan headeri
                        _.map(
                          user?.types?.pricing,
                          (v: any, k: any) =>
                            !v?.flags?.readonly &&
                            k !== 'resource' && (
                              <th key={k}>
                                <div>
                                  <Lang>{k}</Lang>
                                </div>
                              </th>
                            )
                        )
                      }
                      <th style={{ width: '32px' }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      // Listaa rivit
                      _.map(data?.pricing, (v, k) => (
                        <tr
                          key={k}
                          onClick={() => {
                            this.setState({
                              pricingModal: {
                                show: true,
                                data: data.pricing[k],
                              }
                            });
                          }}
                        >
                          <td>
                            <ClearButton small={true}>
                              <i className="icon-pencil" />
                            </ClearButton>
                          </td>
                          {_.map(
                            user?.types?.pricing,
                            (v2: any, k2: any) =>
                              !v2?.flags?.readonly &&
                              k2 !== 'resource' && (
                                <td key={k2}>
                                  <div className="text-ellipsis">
                                    {((k2 === 'duration' || k2 === 'min_duration') &&
                                      MinutesToString(v?.[k2])) ||
                                      (v2.type === 'enum' && (
                                        <Lang>{v?.[k2]}</Lang>
                                      )) ||
                                      (v2.type === 'datetime' &&
                                        TryDateFormat(v?.[k2], 'HH:mm D.M.Y')) ||
                                      (v2.type === 'date' &&
                                        TryDateFormat(v?.[k2], 'D.M.Y')) ||
                                      (v2.type === 'time' &&
                                        TryDateFormat(v?.[k2], 'H:i')) ||
                                      v?.[k2]}
                                  </div>
                                </td>
                              )
                          )}

                          <td className="col ellipsis">
                            <ClearButton
                              small={true}
                              onClick={async (e) => {
                                e.stopPropagation();
                                const confirm = await dialog({
                                  title: (
                                    <>
                                      <div className="mb-2">
                                        Poistetaanko hinta?
                                      </div>
                                      <div>
                                        {MinutesToString(v?.duration)}
                                        <span
                                          style={{
                                            fontWeight: 'bold',
                                            marginLeft: '3px'
                                          }}
                                        >
                                          {v?.price} €
                                        </span>
                                      </div>
                                    </>
                                  )
                                });
                                if (!confirm) return;
                                data.pricing.splice(k, 1);
                                this.setState({ data });
                              }}
                            >
                              <i className="icon-cross text-danger" />
                            </ClearButton>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </TableUI>
                <Row className="mb-3 mt-3">
                  <div className="col" />
                  <div className="col-auto">
                    <ButtonUI
                      onClick={() => {
                        if (!data?.pricing) data.pricing = [];
                        this.setState({
                          pricingModal: {
                            show: true,
                            data: {},
                            add: true,
                          }
                        });
                      }}
                    >
                      <Lang>add new</Lang>
                    </ButtonUI>
                  </div>
                </Row>
              </div>
            </>
          )}
        />
        <Fade
          transition="fade"
          show={!!pricingModal?.show}
          onExited={() => {
            this.setState({ pricingModal: undefined });
          }}
          content={(style) =>
            pricingModal ? 
            (
              <ModalPricing
                style={style}
                data={pricingModal.data}
                onSave={(changes: any)=>{
                  console.log(pricingModal?.data);
                  if (pricingModal?.add) {
                    data.pricing.push(changes);
                  } else {
                    _.map(changes, (v,k)=>{ pricingModal.data[k] = changes[k] });
                  }
                  pricingModal.show = false;
                  this.setState({ data, pricingModal });
                  return true;
                }}
                onClose={()=>{
                  if (pricingModal) pricingModal.show = false;
                  this.setState({ pricingModal });
                }}
              />
            ) 
            : (<></>)
          }
        />
      </BaseModal>
    );
  }
}

export default connect(
  (state: any) => ({
    user: state?.payload?.user
  }),
  {
    setStore: (payload: any) => ({ type: 'set', payload: payload })
  }
)(Modal);
