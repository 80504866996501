import styled, { css } from 'styled-components';

const buttonCss = css<{
  white?: boolean;
  light?: boolean;
  clear?: boolean;
  red?: boolean;
}>`
  align-items: center;
  background-color: #007ac2;
  border: 1px solid transparent;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-family: 'Avenir Next W00', Avenir, 'Helvetica Neue', sans-serif;
  font-size: 12px;
  font-stretch: 100%;
  height: 32px;
  margin: 0;
  justify-content: center;
  letter-spacing: normal;
  line-height: 15.96px;
  padding: 7px 12px;
  position: relative;
  text-align: center;
  text-decoration-color: #fff;
  text-decoration-line: none;
  text-decoration-style: solid;
  text-indent: 0px;
  text-rendering: auto;
  transition: all 100ms ease-in-out;
  user-select: none;
  width: 100%;
  min-width: 60px;
  -webkit-font-smoothing: antialiased;
  outline: none !important;
  white-space: nowrap;

  /* outline: 2px solid transparent !important;
    outline-offset: -2px; */

  :hover {
    background: rgb(40, 144, 206);
  }
  :active {
    border: 1px solid transparent;
    box-shadow: 0 0 0 1px #fff, 0 0 0 3px #007ac2;
    z-index: 1;
  }

  > i {
    margin-top: 2px;
    user-select: none;
  }

  ${(props) =>
    props.light &&
    css`
      color: #151515;
      border: 1px solid #ccc;
      background-color: #eaeaea;

      :hover {
        color: #151515;
        background-color: #f3f3f3;
      }
      :active {
        border: 1px solid transparent;
        box-shadow: inset 0 0 0 1px #007ac2, 0 0 0 1px #fff, 0 0 0 3px #007ac2;
        z-index: 1;
      }

      > i {
        color: #151515;
      }
    `}

  ${(props) =>
    props.clear &&
    css`
      color: rgb(0, 97, 155);
      border: 1px solid rgb(0, 97, 155);
      background: none;

      :hover {
        background: none;
        box-shadow: rgb(40, 144, 206) 0px 0px 0px 1px inset;
      }
      :active {
        border: 1px solid transparent;
        box-shadow: inset 0 0 0 1px #007ac2, 0 0 0 1px #fff, 0 0 0 3px #007ac2;
        z-index: 1;
      }

      > i {
        color: rgb(0, 97, 155);
      }
    `}

    ${(props) =>
    props.red &&
    css`
      color: #7c1d13;
      border: 1px solid #d83020;
      background: none;

      :hover {
        background: none;
        box-shadow: #d83020 0px 0px 0px 1px inset;
      }
      :active {
        border: 1px solid transparent;
        box-shadow: inset 0 0 0 1px #d83020, 0 0 0 1px #fff, 0 0 0 3px #d83020;
        z-index: 1;
      }

      > i {
        color: #d83020;
      }
    `}

    ${(props) =>
    props.white &&
    css`
      background: #fff;
      color: #151515;
      /* outline: 0px solid #007ac2!important; */

      :hover {
        background: #efefef;
        color: #000;
      }
      :active {
        border: 1px solid transparent;
        box-shadow: inset 0 0 0 1px #007ac2, 0 0 0 1px #fff, 0 0 0 3px #007ac2;
        z-index: 1;
      }

      > i {
        color: #151515;
      }
    `}

    .btn-group.calcite-button-group {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
    border: 1px solid #cacaca;
    background: #fff;
  }

  .btn-group.calcite-button-group .calcite-button {
    height: 26px;
    margin: 2px;
    /* outline: 3px solid #007ac2; */
  }

  .pagination-buttons button {
    border: 0;
    /* outline: 0; */
    background: none !important;
    color: #151515 !important;
    font-weight: 400;
    border-bottom: 3px solid transparent;
    padding: 0px 0px 5px 0px;
  }

  .pagination-buttons button:hover {
    border: 0;
    /* outline: 0; */
    background: none !important;
    color: #151515 !important;
    font-weight: 500;
    border-bottom: 3px solid #dfdfdf;
  }

  .pagination-buttons button:focus,
  .pagination-buttons button:active,
  .pagination-buttons button.active {
    border: 0;
    /* outline: 0; */
    background: none !important;
    color: #151515 !important;
    font-weight: 500;
    border-bottom: 3px solid #007ac2;
  }
`;

export const ButtonUI = styled.button.attrs({ type: 'button' })<{
  white?: boolean;
  light?: boolean;
  clear?: boolean;
  red?: boolean;
}>`
  ${buttonCss}
`;

export const SubmitButtonUI = styled.button.attrs({ type: 'submit' })<{
  white?: boolean;
  light?: boolean;
  clear?: boolean;
  red?: boolean;
}>`
  ${buttonCss}
`;
