import React from 'react';
import _ from 'lodash';
import { SetClass } from 'utils/SetClass';

type Props = {
  offset: number;
  limit: number;
  count: number;
  onChange: (data: any) => void;
};
type State = {};

export default class Pagination extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const { offset, limit, count, onChange } = this.props;

    const page = Math.floor(offset / limit);
    const pages = Math.ceil(count / limit);

    const length = Math.min(5, pages);
    const start = Math.max(
      Math.min(page - Math.floor(length / 2), 0 + pages - length),
      0
    );
    const range = _.range(start, start + length);

    return (
      <>
        {pages > 1 && (
          <>
            <div className="btn-group pagination-buttons" role="group">
              <button
                type="button"
                className=""
                disabled={page <= 0}
                onClick={(e) => {
                  e.preventDefault();
                  onChange && onChange(0);
                }}
              >
                <i className="icon-arrow-left5" />
              </button>
              {/* <button type="button" className="btn btn-sm btn-light border" disabled={page <= 0}
                            onClick={(e) =>
                            {
                                e.preventDefault();
                                onChange && onChange(offset - limit);
                            }}
                        >
                            <i className="icon-arrow-left5" />
                        </button> */}
              {_.map(range, (v) => (
                <button
                  key={v}
                  type="button"
                  className={SetClass(['', v === page && 'active'])}
                  onClick={(e) => {
                    e.preventDefault();
                    onChange && onChange(v * limit);
                  }}
                >
                  {v + 1}
                </button>
              ))}
              {/* <button type="button" className="btn btn-sm btn-light border" disabled={page >= pages-1}
                                onClick={(e) =>
                                {
                                    e.preventDefault();
                                    onChange && onChange(offset + limit);
                                }}
                            >
                                <i className="icon-arrow-right5" />
                            </button> */}
              <button
                type="button"
                className=""
                disabled={page >= pages - 1}
                onClick={(e) => {
                  e.preventDefault();
                  onChange && onChange((pages - 1) * limit);
                }}
              >
                <i className="icon-arrow-right5" />
              </button>
            </div>
          </>
        )}
        <div className="p-2">
          Näytetään tulokset {page * limit + 1} -{' '}
          {Math.min(count, page * limit + limit)}, yhteensä {count} riviä
        </div>
      </>
    );
  }
}
