import styled, { css } from 'styled-components';

export const CheckboxUI = styled.input.attrs({ type: 'checkbox' })<{
  halfchecked?: boolean;
}>`
  width: 1.25rem;
  height: 1.25rem;
  transition: all 150ms ease-in-out 0s;
  position: relative;
  vertical-align: bottom;
  margin-right: 0.3rem;

  :before {
    content: ' ';
    display: block;
    border: 1px solid rgb(202, 202, 202);
    width: 1.25rem;
    height: 1.25rem;
    background: #fafafa;
    transition: all 150ms ease-in-out 0s;
    pointer-events: none;
  }
  &:checked:before {
    background: #007ac2;
    border: 1px solid transparent;
  }
  :active {
    box-shadow: inset 0 0 0 1px #007ac2, 0 0 0 2px #fff, 0 0 0 4px #007ac2;
  }

  &:checked:after {
    font-family: 'icomoon-ultimate' !important;
    content: '\\ed6f';
    color: #fff;
    position: absolute;
    top: 1px;
    right: 4px;
  }

  ${(props) =>
    props.halfchecked &&
    css`
      &:checked:after {
        font-family: 'icomoon-ultimate' !important;
        content: '\\ed5e';
        color: #fff;
        position: absolute;
        top: 1px;
        right: 4px;
      }
    `}
`;
