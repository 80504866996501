import SystemIcon from 'components/UI/SystemIcon';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { UserType } from 'store';
import Lang from 'utils/Lang';

type Props = {
  user: UserType;
};
type State = {};

class Sidebar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      status: ''
    };
  }

  render() {
    const { user } = this.props;

    return (
      <div
        className="card m-0 rounded-0 border-right pb-3"
        style={{ borderRadius: '0 0 0.2rem 0' }}
      >
        <div className="card-header p-3 mt-1 border-0">
          <h6>Pääkäyttäjä</h6>
          <div className="header-elements">
            <div className="list-icons">
              <a href="/" data-action="collapse" className="list-icons-item">
                {' '}
              </a>
            </div>
          </div>
        </div>
        <div className="card-body p-0">
          <ul data-nav-type="accordion" className="nav d-flex flex-column">
            <li className="nav-item">
              <NavLink to="/orders" className="nav-link">
                <i className="icon-home align-top mt-1 mr-3" />
                <span className="d-inline-block">
                  <b>Tilaukset</b>
                  <span className="d-block">Ei uusia tilauksia</span>
                </span>
              </NavLink>
            </li>

            {_.map(
              user?.links,
              (v, k) =>
                v.level === 'super' && (
                  <li key={k} className="nav-item pt-1">
                    <NavLink to={k} className="nav-link">
                      <i className={'mr-3 ' + SystemIcon(k)} />
                      <Lang>{v.title}</Lang>
                    </NavLink>
                  </li>
                )
            )}

            {/* <li className="nav-item pt-1">
                <NavLink to="company" className="nav-link">
                  <i className={"mr-3 " + SystemIcon('company')} />Yritykset
                </NavLink>
              </li>

              <li className="nav-item pt-1">
                <NavLink to="translations" className="nav-link">
                  <i className={"mr-3 " + SystemIcon('translations')} />Käännökset
                </NavLink>
              </li>

              <li className="nav-item pt-1">
                <NavLink to="validations" className="nav-link">
                  <i className={"mr-3 " + SystemIcon('validations')} />Validointi
                </NavLink>
              </li>

              <li className="nav-item pt-1">
                <NavLink to="analytics" className="nav-link">
                  <i className={"mr-3 " + SystemIcon('analytics')} />Analytiikka
                </NavLink>
              </li>

              <li className="nav-item pt-1">
                <NavLink to="notifications" className="nav-link">
                  <i className={"mr-3 " + SystemIcon('notifications')} />Ilmoitukset
                </NavLink>
              </li>

              <li className="nav-item pt-1">
                <NavLink to="database" className="nav-link">
                  <i className={"mr-3 " + SystemIcon('database')} />Tietokanta
                </NavLink>
              </li>

              <li className="nav-item pt-1">
                <NavLink to="apikeys" className="nav-link">
                  <i className={"mr-3 " + SystemIcon('apikeys')} />API-avaimet
                </NavLink>
              </li>

              <li className="nav-item pt-1">
                <NavLink to="logs" className="nav-link">
                  <i className={"mr-3 " + SystemIcon('logs')} />Lokit
                </NavLink>
              </li> */}
          </ul>
        </div>
      </div>
    );
  }
}

export default connect(
  (state: any) => {
    return {
      user: state?.payload?.user
    };
  },
  {
    setStore: (payload: any) => ({ type: 'set', payload: payload })
  }
)(Sidebar);
