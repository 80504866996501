import React from 'react';
import _ from 'lodash';
import Lang from 'utils/Lang';

type Props = {};
type State = {};

export default class NotFound extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <Lang>not found</Lang>
      </div>
    );
  }
}
