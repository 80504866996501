import DatePickerV2 from 'components/DatePicker/DatePickerV2';
import DropdownV2 from 'components/Dropdown/DropdownV2';
import Input from 'components/Forms/Input';
import TypedInput from 'components/Forms/TypedInput';
import { toastError, toastSuccess } from 'components/Toasts/Success';
import { Fade } from 'components/UI/Fade';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { ButtonUI, SubmitButtonUI } from 'ui/Button';
import { InputUI } from 'ui/InputUI';
import { Loader } from 'ui/Loader';
import { Post, Put, Fetch } from 'utils/Api';
import { MinutesToString } from 'utils/Dates';
import Lang, { lang } from 'utils/Lang';
import BaseModal from './BaseModal';
import { dialog } from './Dialog';
import ModalPage from './ModalPage';

type Props = {
  user: any;
  style?: React.CSSProperties;
  data: any;
  onClose: () => void;
};
type State = {
  schedule: any;
  unsaved: boolean;
  loading: boolean;
  printing: string | undefined;
  pricedata: any;
};

export const printContract = async (id: string) => {
  toast.info(
    <>
      <div>
        <i className="icon-printer3 icon-2x" />
      </div>
      <Lang>printing</Lang>...
    </>
  );

  const data = await Fetch('POST', 'print', { id: id });
  const res = await data.blob();
  const printURL = URL.createObjectURL(res);

  const iframe =
    document.getElementById('oitis_printing_frame') ||
    document.createElement('iframe');
  if (!(iframe instanceof HTMLIFrameElement)) return;

  iframe.id = 'oitis_printing_frame';
  iframe.style.maxWidth = '1px';
  iframe.style.maxHeight = '1px';
  iframe.style.visibility = 'hidden';
  iframe.src = printURL;

  return new Promise((resolve: (value?: unknown) => void) => {
    window.onafterprint = () => {
      if (iframe) iframe.remove();
    };
    iframe.onload = (e: any) => {
      const isFirefox =
        navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
      if (isFirefox) {
        setTimeout(() => {
          e.target.contentWindow.focus();
          e.target.contentWindow.print();
          resolve();
        }, 1000);
      } else {
        e.target.contentWindow.focus();
        e.target.contentWindow.print();
        resolve();
      }
    };
    document.body.appendChild(iframe);
  });
};

class Modal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      schedule: _.cloneDeep({
        ..._.mapValues(props.user?.types?.schedule, (v) => v?.default),
        ...props.data
      }),
      unsaved: false,
      loading: true,
      printing: undefined,
      pricedata: undefined,
    };
  }

  async calcPrice() {
    const { schedule } = this.state;
    if (!schedule) return;
    const pricedata = await Post('get_price', schedule);
    schedule.calcprice = pricedata.price;
    this.setState({ pricedata });
  }

  componentDidMount() {
    this.calcPrice();
  }

  async save(data?: any) {
    const { schedule } = this.state;
    try {
      const res = await Put('schedule', { ...schedule, ...data });
      toastSuccess();
      return res;
    } catch (e) {
      toastError(e);
      throw e;
    }
  }

  render() {
    const { user, style, onClose } = this.props;
    const { unsaved, schedule, loading, pricedata } = this.state;
    return (
      <>
        <BaseModal
          style={style}
          unsaved={unsaved}
          hasId={!!schedule?.id}
          title={<Lang>{'schedules'}</Lang>}
          onClose={onClose}
          footer={
            <>
              {schedule?.id && (
                <ButtonUI
                  red
                  style={{ marginRight: 'auto', maxWidth: '100px' }}
                  disabled={loading}
                  onClick={async () => {
                    if (loading) return;
                    this.setState({ loading: true });

                    const confirm = await dialog({
                      title: (
                        <>
                          <div className="mb-2">
                            Syy varauksen poistamiseen:
                          </div>
                          <div>
                            <InputUI
                              type="text"
                              onKeyDown={async (e)=>{
                                if (e.key === 'Enter') {
                                  e.preventDefault();
                                  try {
                                    await this.save({ active: 'deleted' });
                                    onClose && onClose();
                                  } catch (e) {}
                                }
                              }}
                              onChange={(e) => {
                                schedule.deletereason = e.target.value;
                                console.log(schedule.deletereason);
                              }}
                            />
                          </div>
                        </>
                      )
                    });

                    if (confirm) {
                      try {
                        await this.save({ active: 'deleted' });
                        onClose && onClose();
                      } catch (e) {}
                    }
                    this.setState({ loading: false });
                  }}
                >
                  <Lang>delete</Lang>
                </ButtonUI>
              )}

              <div className="price">
                Loppusumma: {schedule?.customprice || schedule?.calcprice} €
              </div>

              <div className="btn-group calcite-button-group" role="group">
                {schedule?.status === 'progress' && (
                  <ButtonUI
                    white
                    disabled={loading}
                    style={{ minWidth: '140px' }}
                    onClick={async (e) => {
                      if (loading) return;
                      if (!e.currentTarget.form?.checkValidity()) return;
                      this.setState({ loading: true });

                      if (
                        await dialog({
                          title: 'Merkitäänkö varaus päättyneeksi?'
                        })
                      ) {
                        try {
                          await this.save({ status: 'ready' });
                          onClose && onClose();
                        } catch (e) {}
                      }
                      this.setState({ loading: false });
                    }}
                  >
                    Merkitse päättyneeksi
                  </ButtonUI>
                )}
                {(!schedule?.status || schedule?.status === 'new') && (
                  <SubmitButtonUI
                    white
                    disabled={loading}
                    style={{ minWidth: '140px' }}
                    onClick={async (e) => {
                      if (loading) return;
                      if (!e.currentTarget.form?.checkValidity()) return;
                      this.setState({ loading: true });

                      if (
                        await dialog({
                          title: 'Merkitäänkö varaus alkaneeksi?'
                        })
                      ) {
                        try {
                          const res = await this.save({ status: 'progress' });
                          await printContract(res?.id || 0);
                          onClose && onClose();
                        } catch (e) {}
                      }
                      this.setState({ loading: false });
                    }}
                  >
                    Merkitse alkaneeksi
                  </SubmitButtonUI>
                )}
                {schedule?.id &&
                  (schedule?.status === 'progress' ||
                    schedule?.status === 'ready') && (
                    <SubmitButtonUI
                      white
                      disabled={loading}
                      style={{ minWidth: '140px' }}
                      onClick={async (e) => {
                        if (loading) return;
                        if (!e.currentTarget.form?.checkValidity()) return;
                        this.setState({ loading: true });
                        try {
                          await printContract(schedule.id);
                          onClose && onClose();
                        } catch (e) {}
                        this.setState({ loading: false });
                      }}
                    >
                      <Lang>print contract again</Lang>
                    </SubmitButtonUI>
                  )}
                {
                  <SubmitButtonUI
                    disabled={loading}
                    onClick={async (e) => {
                      if (loading) return;
                      if (!e.currentTarget.form?.checkValidity()) return;
                      this.setState({ loading: true });
                      try {
                        await this.save();
                        onClose && onClose();
                      } catch (e) {}
                      this.setState({ loading: false });
                    }}
                  >
                    <Lang>save</Lang>
                  </SubmitButtonUI>
                }
              </div>
            </>
          }
        >
          {loading && <Loader />}
          <ModalPage
            title={<Lang>schedule</Lang>}
            className="col-md-6 col-lg mt-2"
            table="schedule"
            data={schedule}
            onLoad={() => {
              this.setState({ loading: false });
            }}
            customRender={(v: any, k: any) =>
              ((k === 'active' ||
                k === 'customer' ||
                k === 'metadata' ||
                k === 'status' ||
                (k === 'deletereason' && schedule?.active !== 'deleted')) &&
                // || (k === 'office' && schedule?.resource?.office !== null)
                true) ||
              (k === 'calcprice' && (
                (
                  pricedata?.error &&
                  <InputUI value={pricedata?.error} readOnly />
                )
                || (<div style={{ display: 'flex' }}>
                  <InputUI value={schedule?.[k]} readOnly />
                  <InputUI value={MinutesToString(pricedata?.amount)} readOnly />
                </div>)
              )) ||
              (k === 'resource' &&
                (() => {
                  return (
                    <>
                      <DropdownV2
                        style={style}
                        required={!v?.flags?.allow_any}
                        placeholder={lang(k)}
                        selected={(e: any) =>
                          e?.id === (schedule?.[k]?.id || schedule?.[k])
                        }
                        allowAny={v?.flags?.allow_any}
                        options={async (search: string) => {
                          const res = await Post(
                            v.join,
                            {
                              ...(search && { name: `%${search}%` }),
                              ...(user?.settings?.office?.id && {
                                office: user?.settings?.office?.id
                              })
                            },
                            { limit: 1000 }
                          );
                          res.data = _.pickBy(res?.data, (v) => v.reservable);
                          return res;
                        }}
                        onChange={(e: any) => {
                          schedule[k] = e;
                          this.setState({ schedule }, () => {
                            this.calcPrice();
                          });
                        }}
                      />
                      {schedule[k]?.subresources?.length > 0 && (
                        <DropdownV2
                          style={style}
                          required={!v?.flags?.allow_any}
                          placeholder={lang('subresources')}
                          selected={(e: any) =>
                            e?.id === (schedule?.[k]?.id || schedule?.[k])
                          }
                          allowAny={v?.flags?.allow_any}
                          options={async (search: string) => {
                            return {
                              data: schedule[k].subresources,
                              count: schedule[k].subresources.length
                            };
                          }}
                          onChange={(e: any) => {
                            // schedule[k].subresources
                            console.log('subresources', e);
                            this.setState({ schedule }, () => {
                              this.calcPrice();
                            });
                          }}
                        />
                      )}
                    </>
                  );
                })()) ||
              (k === 'start' && (
                <DatePickerV2
                  value={schedule?.[k] || ''}
                  max={schedule?.ending}
                  onChange={(date: any) => {
                    schedule[k] = date;
                    if (moment(schedule[k]).isAfter(schedule?.ending)) {
                      schedule.ending = date;
                    }
                    this.setState({ schedule }, () => {
                      this.calcPrice();
                    });
                  }}
                />
              )) ||
              (k === 'ending' && (
                <DatePickerV2
                  value={schedule?.[k] || ''}
                  min={schedule?.start}
                  onChange={(date: any) => {
                    schedule[k] = date;
                    if (moment(schedule[k]).isBefore(schedule?.start)) {
                      schedule.start = date;
                    }
                    this.setState({ schedule }, () => {
                      this.calcPrice();
                    });
                  }}
                />
              ))
            }
            onChange={(e: any) => {
              this.setState({ schedule: e, unsaved: !loading }, () => {
                this.calcPrice();
              });
            }}
          />
          <Fade
            show={true}
            transition="slide"
            content={(style) => (
              <ModalPage
                style={style}
                title={<Lang>customer</Lang>}
                className="col-md-6 col-lg mt-2"
                table="customer"
                data={schedule?.customer}
                onLoad={() => {
                  this.setState({ loading: false });
                }}
                customRender={(v: any, k: any) =>
                  k === 'active' ||
                  k === 'metadata' ||
                  (k === 'name' && (
                    <DropdownV2
                      value={schedule?.customer?.name}
                      required={!''.match(v?.regex || '')}
                      regex={v?.regex}
                      placeholder={lang('search or create customer')}
                      selected={(e: any) => e?.id === schedule.customer?.id}
                      options={(search: string) =>
                        search?.length >= 3 &&
                        Post('customer', {
                          ...(search && { name: `%${search}%` })
                        })
                      }
                      onChange={(e: any) => {
                        schedule.customer = e;
                        this.setState({ schedule, unsaved: !loading });
                      }}
                      onType={(e: any) => {
                        if (
                          schedule?.customer?.id &&
                          e.target.value.length === 0
                        ) {
                          this.setState({
                            schedule: {
                              ...schedule,
                              customer: { name: e.target.value }
                            },
                            unsaved: !loading
                          });
                        } else {
                          this.setState({
                            schedule: {
                              ...schedule,
                              customer: {
                                ...schedule.customer,
                                name: e.target.value
                              }
                            },
                            unsaved: !loading
                          });
                        }
                      }}
                      onClear={() => {
                        schedule.customer = {};
                        this.setState({ schedule, unsaved: !loading });
                      }}
                    />
                  )) ||
                  (k === 'vatnumber' && !schedule?.customer?.iscompany)
                }
                onChange={(e: any) => {
                  this.setState({
                    schedule: { ...schedule, customer: e },
                    unsaved: !loading
                  });
                }}
              />
            )}
          />
        </BaseModal>
      </>
    );
  }
}

export default connect(
  (state: any) => ({
    user: state?.payload?.user
  }),
  {
    setStore: (payload: any) => ({ type: 'set', payload: payload })
  }
)(Modal);
