import React from 'react';
import _ from 'lodash';
import { Row } from 'ui/Row';
import { Post } from 'utils/Api';
import DropdownV2 from 'components/Dropdown/DropdownV2';
import moment from 'moment';
import { Doughnut } from 'react-chartjs-2';
import md5 from 'md5';
import { Chart } from "react-google-charts";

type Props = {};

type State = {
  timerange: string;
  stats: {
    newcustomers: number;
    schedules: number;
    offices: any;
    sales: any;
    salestotal: number;
  };
};

export const randomColor = (seed: string) => {
  return (
    '#' +
    (parseInt(md5('0' + seed), 16) / 2 + 0.5).toString(16).substr(2, 2) +
    (parseInt(md5('1' + seed), 16) / 2 + 0.5).toString(16).substr(2, 2) +
    (parseInt(md5('2' + seed), 16) / 2 + 0.5).toString(16).substr(2, 2)
  );
};

console.log(parseInt(md5('0' + '2'), 16));

export default class StatsOffices extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      timerange: moment().startOf('month').format('YYYY-MM-DD'),
      stats: {
        newcustomers: 0,
        schedules: 0,
        offices: [],
        sales: [],
        salestotal: 0
      }
    };
  }

  async updateData() {
    const { timerange, stats } = this.state;
    stats.offices = (
      await Post('office', { active: 'active' }, { nojoins: true })
    )?.data;
    stats.sales = (
      await Post(
        'schedule',
        { created: '>=' + timerange, active: 'active' },
        { limit: 10000 }
      )
    )?.data;
    this.setState({ stats });
  }

  componentDidMount() {
    this.updateData();
  }

  render() {
    const { timerange, stats } = this.state;

    return (
      <div className="col-xl-5">
        <style>{`
          svg > g > g:last-child { pointer-events: none; }
        `}</style>
        <div className="card">
          <div className="card-header border-0 d-flex">
            <Row className="align-items-center m-0 w-100">
              <div className="col m-0 p-1">
                <h6>Toimipisteet</h6>
              </div>
              <div className="col m-0 p-1">
                <DropdownV2
                  options={(search: string) => [
                    {
                      name: 'Kuluva kuukausi',
                      value: moment().startOf('month').format('YYYY-MM-DD')
                    },
                    {
                      name: '3 kuukautta',
                      value: moment()
                        .startOf('month')
                        .subtract(3, 'month')
                        .format('YYYY-MM-DD')
                    },
                    {
                      name: '6 kuukautta',
                      value: moment()
                        .startOf('month')
                        .subtract(6, 'month')
                        .format('YYYY-MM-DD')
                    },
                    {
                      name: 'Kuluva vuosi',
                      value: moment().startOf('year').format('YYYY-MM-DD')
                    },
                    {
                      name: 'Alusta asti',
                      value: moment(0).format('YYYY-MM-DD')
                    }
                  ]}
                  selected={(e: any) => e?.value === timerange}
                  onChange={(e: any) => {
                    this.setState({ timerange: e.value }, () => {
                      this.updateData();
                    });
                  }}
                />
              </div>
            </Row>
          </div>
          <div className="card-body py-0">
            <div id="traffic-sources" className="chart position-relative p-2">
              <Row className="mb-2">
                <div className="col-6">
                  Varaukset
                  {/* <Doughnut
                    width={177}
                    // legend={{ display: false }}
                    // options={{  }}
                    data={{
                      labels: _.map(stats.offices, (o) => o.name),
                      datasets: [
                        {
                          data: _.map(stats.offices, (o) =>
                            _.reduce(
                              stats.sales,
                              (r: any, v) => {
                                if (v.office.id === o.id) r++;
                                return r;
                              },
                              0
                            )
                          ),
                          backgroundColor: _.map(stats.offices, (o) =>
                            randomColor(o.id)
                          )
                        }
                      ]
                    }}
                    height={100}
                  /> */}
                  <Chart
                    chartType="PieChart"
                    loader={<div>Loading chart</div>}
                    data={
                      [
                        ['Toimipiste', 'Summa'],
                        ..._.map(stats.offices, (o) =>
                          [o.name, _.reduce(
                            stats.sales,
                            (r: any, v) => {
                              if (v.office.id === o.id) r++;
                              return r;
                            },
                            0
                          )]
                        )
                      ]
                    }
                    options={{
                      title: '',
                      backgroundColor: 'transparent',
                      chartArea: { width: '90%', height: '90%' },
                      pieHole: 0.4,
                    }}
                  />
                </div>
                <div className="col-6">
                  Myynti (EUR)
                  <Chart
                    chartType="PieChart"
                    loader={<div>Loading chart</div>}
                    data={
                      [
                        ['Toimipiste', 'Summa'],
                        ..._.map(stats.offices, (o) =>
                          [o.name, _.reduce(
                            stats.sales,
                            (r: any, v) => {
                              if (v.office.id === o.id)
                                r += parseFloat(v.customprice || v.calcprice) || 0;
                              return r;
                            },
                            0
                          )])
                      ]
                    }
                    options={{
                      title: '',
                      backgroundColor: 'transparent',
                      chartArea: { width: '90%', height: '90%' },
                      pieHole: 0.4,
                    }}
                  />
                  {/* <Doughnut
                    width={177}
                    legend={{ display: true }}
                    data={{
                      labels: _.map(stats.offices, (o) => o.name),
                      datasets: [
                        {
                          data: _.map(stats.offices, (o) =>
                            _.reduce(
                              stats.sales,
                              (r: any, v) => {
                                if (v.office.id === o.id)
                                  r += v.customprice || v.price || 0;
                                return r;
                              },
                              0
                            )
                          ),
                          backgroundColor: _.map(stats.offices, (o) =>
                            randomColor(o.id)
                          )
                        }
                      ]
                    }}
                    height={100}
                  /> */}
                </div>
              </Row>
              <Row className="mb-2">
                <div className="col-12 m-0 p-1">
                  <h6>Varauksien jakauma</h6>
                </div>
                <div className="col-6">
                  Varaukset
                  {/* <Chart
                    chartType="PieChart"
                    loader={<div>Loading chart</div>}
                    data={
                      [
                        ['Kassavaraukset', 'Nettivaraukset'],
                        ..._.map(stats.offices, (o) =>
                          [o.name, _.reduce(
                            stats.sales,
                            (r: any, v) => {
                              if (v.office.id === o.id)
                                r += parseFloat(v.customprice || v.calcprice) || 0;
                              return r;
                            },
                            0
                          )])
                      ]
                    }
                    options={{
                      title: '',
                      backgroundColor: 'transparent',
                      chartArea: { width: '90%', height: '90%' },
                      pieHole: 0.4,
                    }}
                  /> */}
                  <Doughnut
                    width={200}
                    data={{
                      labels: ['Kassavaraukset', 'Nettivaraukset'],
                      datasets: [
                        {
                          data: _.values(
                            _.reduce(
                              stats.sales,
                              (r: any, v) => {
                                if (v.source === 'nettivaraus')
                                  r[1] = (r?.[1] || 0) + 1;
                                else r[0] = (r?.[0] || 0) + 1;
                                return r;
                              },
                              {}
                            )
                          ),
                          backgroundColor: ['#2cc445', '#36A2EB']
                        }
                      ]
                    }}
                    height={100}
                  />
                </div>
                <div className="col-6">
                  Myynti (EUR)
                  <Doughnut
                    width={200}
                    data={{
                      labels: ['Kassavaraukset', 'Nettivaraukset'],
                      datasets: [
                        {
                          data: _.values(
                            _.reduce(
                              stats.sales,
                              (r: any, v) => {
                                const price = _.isNumber(v.customprice)
                                  ? v.customprice
                                  : v.calcprice || 0;
                                if (v.source === 'nettivaraus')
                                  r[1] = (r?.[1] || 0) + price;
                                else r[0] = (r?.[0] || 0) + price;
                                return r;
                              },
                              {}
                            )
                          ),
                          backgroundColor: ['#2cc445', '#36A2EB']
                        }
                      ]
                    }}
                    height={100}
                  />
                </div>
              </Row>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
