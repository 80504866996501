import styled, { css } from 'styled-components';

const sharedStyle = css<{ error?: boolean }>`
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(202, 202, 202);
  color: rgb(21, 21, 21);
  display: flex;
  flex: 1;
  width: 100%;
  max-height: 100%;
  max-width: 100%;
  min-width: 20%;
  height: 100%;
  min-height: 32px;
  transition: all 100ms ease-in-out;
  outline: 2px solid transparent;
  outline-offset: 1px;
  padding: 6px 12px;
  position: relative;
  word-spacing: 0px;
  -webkit-font-smoothing: antialiased;
  -webkit-rtl-ordering: logical;

  :focus {
    outline: 2px solid #007ac2;
    outline-offset: -2px;
  }

  ${(props) =>
    props.error &&
    css`
      outline-color: #e65240 !important;
      outline-width: 1px;
      outline-offset: -1px;
    `}
`;

export const InputDiv = styled.div<{ error?: boolean }>`
  ${sharedStyle}
`;

export const InputUI = styled.input<{ error?: boolean }>`
  ${sharedStyle}
  :read-only {
    background: #efefef;
    /* border-width: 0 0 1px 0; */
    outline: 0 !important;
    user-select: none;
    pointer-events: none;
  }

  :focus:invalid {
    outline-color: #e65240 !important;
    outline-width: 1px;
    outline-offset: -1px;
  }
`;

export const NoStyleInput = styled.input`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: 0;
  outline: 0;
  border: 0;
  padding-left: 10px;
`;

// export const TextareaUI = styled.textarea<{ error?: boolean }>`
//      ${sharedStyle}
// `;
