import BaseModal from 'components/Modals/BaseModal';
import ModalPage from 'components/Modals/ModalPage';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import Lang from 'utils/Lang';
import ModalFooter from './ModalFooter';

type Props = {
  user: any;
  table: string;
  data: any;
  onClose?: (data?: any) => void;
  style?: React.CSSProperties;
};
type State = {
  data: any;
  loading: boolean;
  unsaved: boolean;
};

class Modal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      data: _.cloneDeep({
        ..._.mapValues(props.user?.types?.[props.table], (v) => v?.default),
        ...props.data
      }),
      unsaved: false,
      loading: true
    };
  }

  render() {
    const { style, table, onClose } = this.props;
    const { unsaved, data, loading } = this.state;
    return (
      <BaseModal
        style={style}
        unsaved={unsaved}
        hasId={!!data?.id}
        title={<Lang>{table}</Lang>}
        onClose={() => {
          onClose && onClose(data);
        }}
        footer={
          <ModalFooter
            table={table}
            data={data}
            loading={loading}
            setLoading={(loading) => {
              this.setState({ loading });
            }}
            onClose={() => {
              onClose && onClose(data);
            }}
          />
        }
      >
        <ModalPage
          className="col-md-12"
          table={table}
          data={data}
          onLoad={() => {
            this.setState({ loading: false });
          }}
          onChange={(e: any) => {
            this.setState({ data: e, unsaved: !loading, loading: false });
          }}
        />
      </BaseModal>
    );
  }
}

export default connect(
  (state: any) => ({
    user: state?.payload?.user
  }),
  {
    setStore: (payload: any) => ({ type: 'set', payload: payload })
  }
)(Modal);
