import { Fade } from 'components/UI/Fade';
import * as Cookies from 'js-cookie';
import React from 'react';
import { connect } from 'react-redux';
import { ButtonUI, SubmitButtonUI } from 'ui/Button';
import { InputUI } from 'ui/InputUI';
import { Post } from 'utils/Api';

import logo_dark from 'assets/images/logo_dark.svg';

type Props = {
  setStore: (data: any) => void;
};
type State = {
  email: string;
  password: string;
  status: '' | 'invalid' | 'success';
  recoverStatus: '' | 'invalid' | 'success';
  loading: boolean;
  recover: boolean;
};

class Login extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      status: '',
      recoverStatus: '',
      loading: false,
      recover: false
    };
  }

  async recover() {
    try {
      const { email } = this.state;
      const res = await Post('recover', { email });
      this.setState({ recoverStatus: 'success' });
    } catch (e) {
      this.setState({ recoverStatus: 'invalid' });
    }
    return false;
  }

  async login() {
    try {
      const { email, password } = this.state;
      const res = await Post('login', { email, password });
      if (!res?.access_token) return;
      Cookies.set('token', res.access_token);
      this.setState({ status: 'success' });
      document.location.replace('/');
    } catch (e) {
      this.setState({ status: 'invalid' });
    }
    return false;
  }

  render() {
    const { email, password, status, recoverStatus, recover } = this.state;

    return (
      <div className="page-content pt-0 h-100 d-flex justify-content-center align-items-center">
        <div className="content-wrapper">
          <div className="content d-flex justify-content-center align-items-center">
            <div
              className="card mb-0"
              style={{ width: '250px', userSelect: 'none' }}
            >
              <div
                className="card-body"
                style={{
                  height: '340px',
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <div className="text-center mb-3 pl-4 pr-4">
                  <div className="d-inline">
                    <img
                      className="logoimg mt-2 mb-3"
                      src={logo_dark}
                      alt="Oitis.fi"
                    />
                  </div>
                </div>

                <div
                  style={{ position: 'relative', flex: 1, overflow: 'hidden' }}
                >
                  <Fade
                    transition={recover ? 'switch' : 'switchflip'}
                    show={!recover}
                    content={(style) => (
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          this.login();
                        }}
                      >
                        <div
                          style={{
                            ...style,
                            position: 'absolute',
                            width: '100%'
                          }}
                        >
                          <div className="text-center mb-3 pl-4 pr-4">
                            <h5 className="mb-0">Kirjaudu sisään</h5>
                            {(status === '' && (
                              <span className="d-block text-muted">
                                Anna sähköposti ja salasana
                              </span>
                            )) ||
                              (status === 'invalid' && (
                                <span className="d-block text-danger">
                                  Kirjautuminen epäonnistui
                                </span>
                              )) ||
                              (status === 'success' && (
                                <span className="d-block text-success">
                                  Tervetuloa
                                </span>
                              ))}
                          </div>
                          <div className="pb-2">
                            <InputUI
                              value={email}
                              type="email"
                              placeholder="Sähköposti"
                              required
                              onChange={(e) => {
                                this.setState({ email: e.target.value });
                              }}
                            />
                          </div>
                          <div className="pb-2">
                            <InputUI
                              value={password}
                              type="password"
                              placeholder="Salasana"
                              required
                              onChange={(e) => {
                                this.setState({ password: e.target.value });
                              }}
                            />
                          </div>
                          <div className="pb-2">
                            <SubmitButtonUI>
                              Kirjaudu sisään{' '}
                              <i className="icon-circle-right2 ml-2" />
                            </SubmitButtonUI>
                          </div>
                        </div>
                      </form>
                    )}
                  />

                  <Fade
                    transition={recover ? 'switch' : 'switchflip'}
                    show={recover}
                    content={(style) => (
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          this.recover();
                        }}
                      >
                        <div
                          style={{
                            ...style,
                            position: 'absolute',
                            width: '100%'
                          }}
                        >
                          <div className="text-center mb-3 pl-4 pr-4">
                            <h5 className="mb-0">Palauta salasana</h5>
                            {(recoverStatus === '' && (
                              <span className="d-block text-muted">
                                Anna sähköposti
                              </span>
                            )) ||
                              (recoverStatus === 'invalid' && (
                                <span className="d-block text-danger">
                                  Virhe, yritä myöhemmin uudelleen
                                </span>
                              )) ||
                              (recoverStatus === 'success' && (
                                <span className="d-block text-success">
                                  Salasanan palautuslinkki on lähetetty
                                  sähköpostiisi
                                </span>
                              ))}
                          </div>
                          <div className="pb-2">
                            <InputUI
                              value={email}
                              type="email"
                              placeholder="Sähköposti"
                              required
                              onChange={(e) => {
                                this.setState({ email: e.target.value });
                              }}
                            />
                          </div>
                          <div className="pb-2">
                            <SubmitButtonUI>
                              Palauta <i className="icon-circle-right2 ml-2" />
                            </SubmitButtonUI>
                          </div>
                        </div>
                      </form>
                    )}
                  />
                </div>

                <div className="text-center">
                  <a
                    href="recover"
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({ recover: !recover });
                    }}
                  >
                    {(!recover && <>Unohtuiko salasana?</>) || (
                      <>Takaisin kirjautumiseen</>
                    )}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  (state: any) => ({
    user: state?.payload?.user
  }),
  {
    setStore: (payload: any) => ({ type: 'set', payload: payload })
  }
)(Login);
