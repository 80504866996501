import React from 'react';
import { toast } from 'react-toastify';
import Lang from 'utils/Lang';
import _ from 'lodash';

export const toastSuccess = (msg?: string) => {
  toast.success(
    <>
      <div>
        <i className="icon-checkmark3 icon-2x" />
      </div>
      <Lang>{msg || 'saved'}</Lang>
    </>
  );
};

export const toastError = (res?: any) => {
  toast.error(
    <>
      <div>
        <i className="icon-notification icon-2x" />
      </div>
      <div>
        {_.map(res?.error, (v: any, k) => (
          <span key={k}>
            <Lang>{_.isString(v) ? v : ''}</Lang>
            <br />
          </span>
        ))}
      </div>
    </>
  );
};
