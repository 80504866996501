import React from 'react';
import _ from 'lodash';
import { SetClass } from 'utils/SetClass';

type Props = {
  name: string;
};
type State = {};

const icons: any = {
  schedule: 'icon-calendar',
  customer: 'icon-user',
  resource: 'icon-cart5',
  category: 'icon-folder-open',
  company: 'icon-office',
  office: 'icon-location3',
  user: 'icon-user-tie',
  opening: 'icon-alarm',
  analytics: 'icon-stats-growth',
  service: 'icon-cart4',
  notify: 'icon-bell2',
  privilege: 'icon-user-lock',
  translation: 'icon-ligature',
  validation: 'icon-spell-check2',
  database: 'icon-database',
  apikeys: 'icon-lan',
  user_level: 'icon-key',
  logs: 'icon-file-text',
  active: 'icon-checkmark text-success',
  inactive: 'icon-cross2 text-danger',
  deleted: 'icon-bin2',
  new: 'icon-plus text-primary',
  progress: 'icon-stopwatch text-primary',
  ready: 'icon-checkmark text-success',
  cancelled: 'icon-minus text-warning'
};

export default function (name: string) {
  return icons?.[name] || '';
}

// export default class extends React.Component<Props, State>
// {
//     constructor(props: Props)
//     {
//         super(props);
//         this.state = {};
//     }

//     render()
//     {
//         const { name, className } = this.props;
//         return (
//             <i className={SetClass([icons[name], className])} />
//         );
//     }
// }
