import React from 'react';
import _ from 'lodash';
import { Row } from 'ui/Row';
import { Post } from 'utils/Api';
import DropdownV2 from 'components/Dropdown/DropdownV2';
import moment from 'moment';
import { Line } from 'react-chartjs-2';
import { SqlFormat } from 'utils/Dates';

type Props = {};

type State = {
  timerange: string;
  stats: {
    newcustomers: number;
    schedules: number;
    sales: any;
    salestotal: number;
  };
};

export default class StatsSales extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      timerange: moment().startOf('month').format('YYYY-MM-DD'),
      stats: {
        newcustomers: 0,
        schedules: 0,
        sales: [],
        salestotal: 0
      }
    };
  }

  async updateData() {
    const { timerange, stats } = this.state;
    stats.newcustomers =
      (
        await Post(
          'customer',
          { created: '>=' + timerange, active: 'active' },
          { countonly: true }
        )
      )?.count || 0;
    stats.schedules =
      (
        await Post(
          'schedule',
          { created: '>=' + timerange, active: 'active' },
          { countonly: true }
        )
      )?.count || 0;
    stats.sales = (
      await Post(
        'schedule',
        { created: '>=' + timerange, active: 'active' },
        { nojoins: true, limit: 10000 }
      )
    )?.data;
    stats.salestotal =
      _.reduce(
        stats.sales,
        (r, v) =>
          r + (_.isNumber(v.customprice) ? v.customprice : v.calcprice || 0),
        0
      ) || 0;
    this.setState({ stats });
  }

  componentDidMount() {
    this.updateData();
  }

  render() {
    const { timerange, stats } = this.state;

    return (
      <div className="col-xl-7">
        <div className="card">
          <div className="card-header border-0 d-flex">
            <Row className="align-items-center m-0 w-100">
              <div className="col m-0 p-1">
                <h6>Tilastot</h6>
              </div>
              <div className="col m-0 p-1">
                <DropdownV2
                  options={(search: string) => [
                    {
                      name: 'Kuluva kuukausi',
                      value: moment().startOf('month').format('YYYY-MM-DD')
                    },
                    {
                      name: '3 kuukautta',
                      value: moment()
                        .startOf('month')
                        .subtract(3, 'month')
                        .format('YYYY-MM-DD')
                    },
                    {
                      name: '6 kuukautta',
                      value: moment()
                        .startOf('month')
                        .subtract(6, 'month')
                        .format('YYYY-MM-DD')
                    },
                    {
                      name: 'Kuluva vuosi',
                      value: moment().startOf('year').format('YYYY-MM-DD')
                    },
                    {
                      name: 'Alusta asti',
                      value: moment(0).format('YYYY-MM-DD')
                    }
                  ]}
                  selected={(e: any) => e?.value === timerange}
                  onChange={(e: any) => {
                    this.setState({ timerange: e.value }, () => {
                      this.updateData();
                    });
                  }}
                />
              </div>
            </Row>
          </div>
          <div className="card-body py-0">
            <Row>
              <div className="col-sm-4">
                <div className="d-flex align-items-center justify-content-center mb-2">
                  <span className="btn bg-transparent border-teal text-teal rounded-round border-2 btn-icon mr-3">
                    <i className="icon-plus3" />
                  </span>
                  <div>
                    <div className="font-weight-semibold">Myynti</div>
                    <span className="text-muted">
                      <span className="badge badge-mark border-success mr-2" />{' '}
                      {stats.salestotal.toFixed(2)} €
                    </span>
                  </div>
                </div>
                <div id="new-visitors" className="w-75 mx-auto mb-3">
                  <div
                    className="recharts-responsive-container"
                    style={{ width: '100%', height: '100%' }}
                  >
                    <div
                      className="recharts-wrapper"
                      style={{
                        position: 'relative',
                        cursor: 'default',
                        width: 169,
                        height: '28.1667px'
                      }}
                    >
                      <svg
                        className="recharts-surface"
                        width={169}
                        height="28.166666666666668"
                        viewBox="0 0 169 28.166666666666668"
                        version="1.1"
                      >
                        <defs>
                          <clipPath id="recharts67-clip">
                            <rect
                              x={5}
                              y={5}
                              height="18.166666666666668"
                              width={159}
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="d-flex align-items-center justify-content-center mb-2">
                  <span className="btn bg-transparent border-warning-400 text-warning-400 rounded-round border-2 btn-icon mr-3">
                    <i className="icon-watch2" />
                  </span>
                  <div>
                    <div className="font-weight-semibold">Varaukset</div>
                    <span className="text-muted">
                      <span className="badge badge-mark border-success mr-2" />{' '}
                      {stats.schedules} kpl
                    </span>
                  </div>
                </div>
                <div id="new-sessions" className="w-75 mx-auto mb-3">
                  <div
                    className="recharts-responsive-container"
                    style={{ width: '100%', height: '100%' }}
                  >
                    <div
                      className="recharts-wrapper"
                      style={{
                        position: 'relative',
                        cursor: 'default',
                        width: 169,
                        height: '28.1667px'
                      }}
                    >
                      <svg
                        className="recharts-surface"
                        width={169}
                        height="28.166666666666668"
                        viewBox="0 0 169 28.166666666666668"
                        version="1.1"
                      >
                        <defs>
                          <clipPath id="recharts70-clip">
                            <rect
                              x={5}
                              y={5}
                              height="18.166666666666668"
                              width={159}
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="d-flex align-items-center justify-content-center mb-2">
                  <span className="btn bg-transparent border-indigo-400 text-indigo-400 rounded-round border-2 btn-icon mr-3">
                    <i className="icon-user" />
                  </span>
                  <div>
                    <div className="font-weight-semibold">Uudet asiakkaat</div>
                    <span className="text-muted">
                      <span className="badge badge-mark border-success mr-2" />{' '}
                      {stats.newcustomers}
                    </span>
                  </div>
                </div>
                <div id="total-online" className="w-75 mx-auto mb-3">
                  <div
                    className="recharts-responsive-container"
                    style={{ width: '100%', height: '100%' }}
                  >
                    <div
                      className="recharts-wrapper"
                      style={{
                        position: 'relative',
                        cursor: 'default',
                        width: 169,
                        height: '33.8px'
                      }}
                    >
                      <svg
                        className="recharts-surface"
                        width={169}
                        height="33.8"
                        viewBox="0 0 169 33.8"
                        version="1.1"
                      >
                        <defs>
                          <clipPath id="recharts73-clip">
                            <rect
                              x={5}
                              y={5}
                              height="23.799999999999997"
                              width={159}
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </Row>
          </div>
          <div id="traffic-sources" className="chart position-relative p-2">
            <Line
              data={{
                labels: _.values(
                  _.sortBy(
                    _.reduce(
                      stats.sales,
                      (r: any, v) => {
                        const i = moment(v.created).format('YYYYMMDD');
                        r[i] = moment(v.created).format('DD.MMM');
                        return r;
                      },
                      {}
                    ),
                    (v, k) => k
                  )
                ),
                datasets: [
                  {
                    label: 'Myynti (EUR)',
                    fill: false,
                    lineTension: 0.1,
                    borderColor: '#FF6384',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: _.values(
                      _.sortBy(
                        _.reduce(
                          stats.sales,
                          (r: any, v) => {
                            const i = moment(v.created).format(SqlFormat);
                            r[i] =
                              (r?.[i] || 0) +
                              (_.isNumber(v.customprice)
                                ? v.customprice
                                : v.calcprice || 0);
                            return r;
                          },
                          {}
                        ),
                        (v, k) => k
                      )
                    )
                  },
                  {
                    label: 'Varaukset',
                    fill: false,
                    lineTension: 0.1,
                    borderColor: '#36A2EB',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: _.values(
                      _.sortBy(
                        _.reduce(
                          stats.sales,
                          (r: any, v) => {
                            const i = moment(v.created).format('YYYYMMDD');
                            r[i] = (r?.[i] || 0) + 1;
                            return r;
                          },
                          {}
                        ),
                        (v, k) => k
                      )
                    )
                  },
                  {
                    label: 'Nettivaraukset',
                    fill: false,
                    lineTension: 0.1,
                    borderColor: 'rgb(75, 192, 192)',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: '#FFCE56',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: _.values(
                      _.sortBy(
                        _.reduce(
                          stats.sales,
                          (r: any, v) => {
                            const i = moment(v.created).format('YYYYMMDD');
                            r[i] = r?.[i] || 0;
                            if (v.source === 'nettivaraus') r[i]++;
                            return r;
                          },
                          {}
                        ),
                        (v, k) => k
                      )
                    )
                  }
                ]
              }}
              height={100}
            />
          </div>
        </div>
      </div>
    );
  }
}
