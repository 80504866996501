import DropdownV2 from 'components/Dropdown/DropdownV2';
import React from 'react';
import { connect } from 'react-redux';
import { UserType } from 'store';
import { Post, Put } from 'utils/Api';
import { lang } from 'utils/Lang';

type Props = {
  title: string;
  page: any;

  setStore: (data: any) => void;
  user: UserType;
};
type State = {};

class Breadcrumbs extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const { setStore, title, page, user } = this.props;

    return (
      <div className="page-header">
        <div className="col-12 col-lg p-1" style={{ fontSize: '1rem' }}>
          <span className="font-weight-semibold">{title}</span>
          <i className="icon-arrow-right5 p-1" />
          <span className="font-weight-normal">{page}</span>
        </div>
        {user?.user?.super && (
          <div className="col-6 col-lg-1 p-1">
            <DropdownV2
              placeholder={lang('company')}
              options={(search: string) =>
                Post('company', { active: 'active' })
              }
              selected={(e: any) => e?.id === user?.settings?.company?.id}
              onChange={async (e: any) => {
                await Put('user', {
                  id: user?.user?.id,
                  company: e?.id
                });
                const types = await Post('types');
                const offices = await Post('office', { active: 'active' });
                types.settings = {
                  ...user?.settings,
                  company: types?.user?.company,
                  ...(offices?.data?.length === 1 && {
                    office: offices?.data?.[0]
                  })
                };
                setStore({ user: types });
              }}
            />
          </div>
        )}
        {
          <div className="col-6 col-lg-1 p-1">
            {(user?.user?.super || user?.user?.offices?.length > 1) && (
              <DropdownV2
                placeholder={lang('office')}
                allowAny={true}
                options={(search: string) =>
                  Post('office', { active: 'active' })
                }
                selected={(e: any) => e?.id === user?.settings?.office?.id}
                onChange={(e: any) => {
                  setStore({
                    user: {
                      ...user,
                      settings: { ...user?.settings, office: e }
                    }
                  });
                }}
              />
            )}
          </div>
        }
      </div>
    );
  }
}

export default connect(
  (state: any) => {
    return {
      user: state?.payload?.user
    };
  },
  {
    setStore: (payload: any) => ({ type: 'set', payload: payload })
  }
)(Breadcrumbs);
