import { Fade } from 'components/UI/Fade';
import React from 'react';
import { connect } from 'react-redux';
import store from 'store';
import Lang from 'utils/Lang';
import ModalInfo from './ModalInfo';

export type DialogData = {
  show: boolean;
  onConfirm: () => void;
  onCancel?: () => void;
  title: any;
};

type Props = {
  data: DialogData;
  setStore?: any;
};
type State = {};

class Dialog extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const { data, setStore } = this.props;
    return (
      <Fade
        show={!!data?.show}
        transition="fade"
        onExited={() => {
          setStore({ dialog: undefined });
        }}
        content={(style) => (
          <ModalInfo
            style={style}
            title={<Lang>confirm</Lang>}
            onConfirm={() => {
              data?.onConfirm && data?.onConfirm();
              setStore({ dialog: { ...data, show: false } });
            }}
            onCancel={() => {
              data?.onCancel && data?.onCancel();
              setStore({ dialog: { ...data, show: false } });
            }}
          >
            {data?.title}
          </ModalInfo>
        )}
      />
    );
  }
}

export default connect(
  (state: any) => ({
    data: state?.payload?.dialog
  }),
  {
    setStore: (payload: any) => ({ type: 'set', payload: payload })
  }
)(Dialog);

export const dialog = async (data: { title: any }) => {
  return new Promise<boolean>((resolve) => {
    store.dispatch({
      type: 'set',
      payload: {
        dialog: {
          ...data,
          show: true,
          onConfirm: () => {
            resolve(true);
          },
          onCancel: () => {
            resolve(false);
          }
        }
      }
    });
  });
};
