import { contextMenu } from 'components/ContextMenu';
import { dialog } from 'components/Modals/Dialog';
import { toastError, toastSuccess } from 'components/Toasts/Success';
import Image from 'components/UI/Image';
import SystemIcon from 'components/UI/SystemIcon';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { UserType } from 'store';
import styled from 'styled-components';
import { CheckboxUI } from 'ui/Checkbox';
import { ClearButton } from 'ui/ClearButton';
import { Ellipsis } from 'ui/Ellipsis';
import { Row } from 'ui/Row';
import { Put } from 'utils/Api';
import Lang, { lang } from 'utils/Lang';
import { SetClass } from 'utils/SetClass';

export const recurCopy = (data: any, types: any): any => 
{
  return _.pickBy(data, (f: any, k: any) => 
  {
    if (f?.id && types?.flags?.cascade) {
      f.id = undefined;
    }
    if (_.isObject(f)) return recurCopy(f, types?.[k]);
    return f?.id || f;
  });
}

const ColAuto = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
  @media (min-width: 300px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media (min-width: 576px) {
    flex: 0 0 20%;
    max-width: 20%;
  }
  @media (min-width: 768px) {
    flex: 0 0 20%;
    max-width: 20%;
  }
  @media (min-width: 1200px) {
    flex: 0 0 10%;
    max-width: 10%;
  }
`;

const ItemCard = styled.div`
  position: relative;
  transition: box-shadow 100ms ease-out;
  border: 1px solid #dfdfdf;
  height: 100%;
  background: #fff;
  cursor: pointer;
  :hover {
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 16px 0px,
      rgba(0, 0, 0, 0.04) 0px 2px 8px 0px;
    border: 1px solid #bfbfbf;
  }
  .item-checkbox {
    position: absolute;
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: flex-end;
  }
`;

type Props = {
  user: UserType;
  table: string;
  columns?: any;
  showDeleted?: boolean;
  view?: 'grid' | 'list';
  onModal: (modal: any) => void;
  data: any;
  sort: string;
  dir: 'asc' | 'desc';
  onSort: (sorting: any) => void;
};

type State = {};

class Table extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  async save(data?: any) {
    const { table } = this.props;
    try {
      const res = await Put(table, data);
      toastSuccess();
      return res;
    } catch (e) {
      toastError(e);
      throw e;
    }
  }

  render() {
    const {
      data,
      user,
      table,
      view,
      onModal,
      sort,
      dir,
      onSort,
      columns
    } = this.props;

    return (
      <>
        {(view === 'grid' || !view) && (
          <div className="row m-0">
            {(data?.data?.length === 0 && (
              <div className="text-center w-100">Ei tuloksia</div>
            )) ||
              _.map(data?.data, (v: any, k: any) => (
                <ColAuto key={k} className="p-1">
                  <ItemCard
                    onClick={() => {
                      onModal({
                        table: table,
                        data: _.mapValues(v, (f: any) => f?.id || f)
                      });
                    }}
                    onContextMenu={(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      contextMenu({
                        x: e.clientX,
                        y: e.clientY,
                        content: (onClose) => (
                          <div className="dropdown-menu show m-0 mb-3">
                            {
                              (user?.user?.super || _.find(user?.user?.user_level?.privileges, { table: table, level: 'create' })) &&
                              (
                              <button
                                className="dropdown-item"
                                onClick={async () => {
                                  onClose();
                                  onModal({
                                    table: table,
                                    data: recurCopy({ ...v, id: undefined }, user?.types?.[table]),
                                  });
                                }}
                              >
                                <i className="icon-arrow-right5 mr-2 dropdown-icon" />
                                Kopioi
                              </button>
                            )}
                            {v.active === 'active' && (
                              <button
                                className="dropdown-item"
                                onClick={async () => {
                                  onClose();
                                  if (
                                    !(await dialog({
                                      title: 'Poistetaanko käytöstä?'
                                    }))
                                  )
                                    return;
                                  await this.save({ ...v, active: 'inactive' });
                                }}
                              >
                                <i className="icon-arrow-right5 mr-2 dropdown-icon" />
                                Poista käytöstä
                              </button>
                            )}
                            {v.active === 'inactive' && (
                              <button
                                className="dropdown-item"
                                onClick={async () => {
                                  onClose();
                                  if (
                                    !(await dialog({
                                      title: 'Otetaanko käyttöön?'
                                    }))
                                  )
                                    return;
                                  await this.save({ ...v, active: 'active' });
                                }}
                              >
                                <i className="icon-arrow-right5 mr-2 dropdown-icon" />
                                Ota käyttöön
                              </button>
                            )}
                            {v.active !== 'deleted' && (
                              <button
                                className="dropdown-item"
                                onClick={async () => {
                                  onClose();
                                  if (
                                    !(await dialog({ title: 'Poistetaanko?' }))
                                  )
                                    return;
                                  await this.save({ ...v, active: 'deleted' });
                                }}
                              >
                                <i className="icon-arrow-right5 mr-2 dropdown-icon" />
                                <Lang>delete</Lang>
                              </button>
                            )}
                          </div>
                        )
                      });
                      return false;
                    }}
                  >
                    {(
                      <>
                        <div className="item-checkbox">
                          <CheckboxUI
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          />
                        </div>
                        <Image
                          thumb={300}
                          src={v?.picture}
                          style={{
                            width: '100%',
                            height: '100px',
                            backgroundPosition: 'center center',
                            backgroundSize: 'cover'
                          }}
                        />
                        <div className="p-2">
                          <Ellipsis className="font-weight-bold">
                            {v?.name}
                          </Ellipsis>
                          <Ellipsis nowrap>{v?.office?.name}</Ellipsis>
                          <Ellipsis nowrap>{v?.address}</Ellipsis>
                          <Ellipsis nowrap>
                            {v?.postal} {v?.city}
                          </Ellipsis>
                        </div>
                      </>
                    ) || (
                      <div className="p-2">
                        <Ellipsis className="font-weight-bold">
                          {v?.name}
                        </Ellipsis>
                        {_.map(user?.types?.[table], (tv: any, tk: string) => (
                          <React.Fragment key={tk}>
                            {tk !== 'created' &&
                              tk !== 'modified' &&
                              tk !== 'name' &&
                              tk !== 'company' &&
                              ((tv?.type === 'datetime' && (
                                <div>
                                  <Lang>{tk}</Lang>:{' '}
                                  {moment(v?.[tk]).format('H:i d.m.Y')}
                                </div>
                              )) ||
                                (tv?.type === 'enum' && v?.[tk] && (
                                  <div>
                                    <i
                                      className={'mr-1 ' + SystemIcon(v?.[tk])}
                                    />
                                    <Lang>{v?.[tk]}</Lang>
                                  </div>
                                )) ||
                                (tv?.type === 'boolean' && (
                                  <div>
                                    <i
                                      className={
                                        'mr-1 ' +
                                        SystemIcon(
                                          v?.[tk] === 1 ? 'active' : 'inactive'
                                        )
                                      }
                                    />
                                    <Lang>{tk}</Lang>
                                  </div>
                                )) ||
                                (tv?.type === 'row' && (
                                  <Ellipsis nowrap>{v?.[tk]?.name}</Ellipsis>
                                )) ||
                                (tv?.type === 'text' && (
                                  <Ellipsis nowrap>{v?.[tk]}</Ellipsis>
                                )))}
                          </React.Fragment>
                        ))}
                      </div>
                    )}
                  </ItemCard>
                </ColAuto>
              ))}
          </div>
        )}
        {view === 'list' && (
          <div className="bg-white border">
            <div className="col">
              <Row
                className="pb-2 d-none d-lg-flex border-bottom mt-1"
                style={{ boxShadow: '0 2px 3px -2px #00000033' }}
              >
                <div className="col" style={{ maxWidth: '40px' }}></div>
                {_.map(
                  user?.types?.[table],
                  (v: any, k: string) =>
                    columns?.[k] !== false &&
                    v?.type !== 'join' &&
                    v?.type !== 'json' &&
                    v?.flags?.type !== 'internal' && (
                      <div
                        key={k}
                        className="col sort"
                        onClick={() => {
                          onSort &&
                            onSort({
                              sort: k,
                              dir: sort === k && dir === 'asc' ? 'desc' : 'asc'
                            });
                        }}
                      >
                        {sort === k && (
                          <i
                            className={
                              'icon-arrow-up5' +
                              (dir !== 'asc' ? ' icon-rotate-180' : '')
                            }
                          />
                        )}
                        <Lang>{k}</Lang>
                      </div>
                    )
                )}
              </Row>
              {(data?.error && (
                <div className="text-center p-3">
                  {_.join(
                    _.map(data?.error, (f: any) => lang(f)),
                    ' - '
                  )}
                </div>
              )) ||
                (data?.data?.length === 0 && (
                  <div className="text-center p-3">Ei rivejä</div>
                )) ||
                _.map(data?.data, (v: any, k: number) => (
                  <div
                    key={table + (v?.id || k)}
                    className={SetClass([
                      'row row-hover p-0 mt-0',
                      v?.active === 'deleted' && 'deleted'
                    ])}
                    onClick={() => {
                      onModal({
                        table: table,
                        data: _.mapValues(v, (f: any) => f?.id || f)
                      });
                    }}
                  >
                    <div
                      className="col-8 col-lg ellipsis d-flex"
                      style={{ maxWidth: '40px' }}
                    >
                      <ClearButton
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                        }}
                      >
                        <i className="icon-menu7"></i>
                      </ClearButton>
                    </div>
                    {_.map(
                      user?.types?.[table],
                      (t: any, key: string) =>
                        columns?.[key] !== false &&
                        t?.type !== 'join' &&
                        t?.type !== 'json' &&
                        t?.flags?.type !== 'internal' && (
                          <React.Fragment key={key}>
                            <div className="col-4 d-lg-none">
                              <div className="dashed-mobile ellipsis">
                                {key}
                              </div>
                            </div>
                            <div className="col-8 col-lg ellipsis">
                              {(t?.type === 'row' && (
                                <NavLink to={t?.join}>{v?.[key]?.name}</NavLink>
                              )) ||
                                (t?.type === 'datetime' && v?.[key] && (
                                  <>
                                    <i
                                      className={'mr-1 ' + SystemIcon(v?.[key])}
                                    />
                                    {moment(v?.[key]).format('HH:mm D.M.Y')}
                                  </>
                                )) ||
                                (t?.type === 'enum' && v?.[key] && (
                                  <>
                                    <i
                                      className={'mr-1 ' + SystemIcon(v?.[key])}
                                    />
                                    <Lang>{v?.[key]}</Lang>
                                  </>
                                )) ||
                                (t?.type === 'boolean' && (
                                  <i
                                    className={
                                      'mr-1 ' +
                                      SystemIcon(
                                        v?.[key] === 1 ? 'active' : 'inactive'
                                      )
                                    }
                                  />
                                )) ||
                                (key === 'picture' && (
                                  <Image
                                    thumb
                                    className="w-100"
                                    src={v?.[key]}
                                  />
                                )) ||
                                ((_.isString(v?.[key]) ||
                                  _.isNumber(v?.[key])) &&
                                  v?.[key])}
                            </div>
                          </React.Fragment>
                        )
                    )}
                    <hr />
                  </div>
                ))}
            </div>
          </div>
        )}
      </>
    );
  }
}

export default connect(
  (state: any) => ({
    user: state.payload?.user
  }),
  {
    setStore: (payload: any) => ({ type: 'set', payload: payload })
  }
)(Table);
