// Dropdown

import styled, { css } from 'styled-components';

export const DropdownOuter = styled.div``;

export const Dropdown = styled.div<{ show: boolean }>`
  position: relative;
  ${(props) =>
    props.show &&
    css`
      z-index: 1 !important;
    `}

  outline: 0;
  border: 0;

  input {
    transition: all 100ms ease-in-out;
    outline: 2px solid transparent;
    outline-offset: 2px;
    cursor: pointer;
  }

  :focus input,
  :focus-within input {
    outline: 2px solid #007ac2;
    outline-offset: 0px;
  }
`;

export const DropdownField = styled.div`
  min-width: 50px;
  min-height: 2rem;
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;

  outline: 0;
  border: 0;
`;

export const DropdownArrow = styled.div`
  position: absolute;
  height: 100%;
  right: 0;
  display: flex;
  align-items: center;
  width: 16px;
  justify-content: center;
  top: 1px;
  pointer-events: none;
  > i {
    transition: all 150ms ease-in-out;
  }
`;

export const DropdownMenuOuter = styled.div`
  :before {
    content: ' ';
    transition: all 150ms ease-in-out;
  }

  // Backdrop for mobile
  @media (max-width: 980px) {
    position: fixed !important;
    display: flex;
    align-items: center;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1;

    :before {
      content: ' ';
      position: fixed !important;
      left: 0;
      top: 0;
      width: 100vw;
      height: 100vh;
      background: #555;
      opacity: 0.4;
      z-index: 1;
    }
  }
`;

export const DropdownMenu = styled.div`
  position: absolute;
  transition: all 500ms ease-in-out 0s;
  border: 1px solid #00000022;
  border-radius: 0;
  overflow: hidden auto;
  overflow: overlay;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 16px 0px;
  background: #fff;
  padding: 0;
  margin: 0;
  right: 0;
  max-height: 350px;
  display: block !important;
  min-width: 100%;
  transform: translateY(-5px);
  pointer-events: none;
  z-index: 1;

  visibility: visible;
  opacity: 1 !important;
  transform: translateY(0px);
  pointer-events: initial;
`;
