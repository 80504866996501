import * as Cookies from 'js-cookie';
import _ from 'lodash';

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

export const WebSocketURL = isLocalhost
  ? // 'http://95.217.182.49:4000/'
    'ws://localhost:4000/'
  : 'wss://ajanvaraus.oitis.fi/wss';

export const BackendURL = isLocalhost
  ? // 'http://95.217.182.49:5000/'
    'http://localhost:5000/'
  : 'https://ajanvaraus.oitis.fi/endpoint/';
  // : 'https://testiservu.oitis.fi:3000/';

export const Fetch = async (
  method: 'POST' | 'PUT',
  path: string,
  payload: any = {},
  header: any = {},
  extra?: any
): Promise<any> => {
  const res = await fetch(BackendURL + path, {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + Cookies.get('token'),
      ...header
    },
    body: JSON.stringify(payload),
    ...extra
  });

  if (res.status === 401) {
    Cookies.remove('token');
    // if (!(_.indexOf(document.location.href, 'login')>=0)) {
    //   document.location.replace('/login');
    // }
  }

  if (res.status !== 200) throw res;
  return res;
};

export const Post = async (
  path: string,
  payload: any = {},
  header: any = {},
  extra?: any
): Promise<any> => {
  try {
    return await (await Fetch('POST', path, payload, header, extra)).json();
  } catch (e) {
    throw await e.json();
  }
};

export const Put = async (
  path: string,
  payload: any,
  header: any = {},
  extra?: any
) => {
  try {
    return await (await Fetch('PUT', path, payload, header, extra)).json();
  } catch (e) {
    throw await e.json();
  }
};
