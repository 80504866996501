import ContextMenu from 'components/ContextMenu';
import Dialog, { DialogData } from 'components/Modals/Dialog';
import Breadcrumbs from 'components/Pages/Breadcrumbs';
import Sidebar from 'components/Pages/Sidebar';
import { Fade } from 'components/UI/Fade';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import setStore, { UserType } from 'store';
import styled from 'styled-components';
import Lang from 'utils/Lang';
import './styles.css';

type Props = {
  children: any;
  user: UserType;
  dialog: DialogData;
};
type State = {
  sort: boolean;
};

const Content = styled.div`
  @media (min-width: 768px) {
    margin: 1.25rem;
  }
`;

const SidebarContainer = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;

  @media (min-width: 768px) {
    max-width: 250px;
  }
`;

export class Page extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      sort: true
    };
  }

  render() {
    const { children, user, dialog } = this.props;
    let page =
      _.last(_.trimEnd(document.location.pathname, '/')?.split('/')) || '';
    if (page === '') page = 'frontpage';
    else if (!user?.types?.[page]) page = 'not found';

    return (
      <div className="page-content d-flex pt-0">
        {user?.user?.super === true && (
          <Fade
            show={user?.super?.sidebar}
            transition="slide"
            content={(style) => (
              <SidebarContainer style={style}>
                <Sidebar />
              </SidebarContainer>
            )}
          />
        )}
        <div className="content-wrapper">
          <Breadcrumbs
            title={user?.user?.company?.name}
            page={<Lang>{page}</Lang>}
          />
          <Content>{children}</Content>
        </div>
        <Dialog />
        <ContextMenu />
      </div>
    );
  }
}

export default connect(
  (state: any) => ({
    user: state?.payload?.user,
    translations: state?.payload?.translations,
    dialog: state?.payload?.dialog
  }),
  { setStore }
)(Page);
