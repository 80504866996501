import DropdownV2 from 'components/Dropdown/DropdownV2';
import TypedInput from 'components/Forms/TypedInput';
import BaseModal from 'components/Modals/BaseModal';
import ModalPage from 'components/Modals/ModalPage';
import { TableUI } from 'components/UI/TableUI';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { CheckboxUI } from 'ui/Checkbox';
import { Post } from 'utils/Api';
import Lang, { lang } from 'utils/Lang';
import ModalFooter from './ModalFooter';

type Props = {
    user: any;
    style?: React.CSSProperties;
    table: string;
    data: any;
    onClose: () => void;
    show: boolean;
};

type State = {
    data: any;
    loading: boolean;
    unsaved: boolean;
    privileges: any,
};

class Modal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            data: _.cloneDeep({
                ..._.mapValues(props.user?.types?.[props.table], (v) => v?.default),
                ...props.data
            }),
            unsaved: false,
            loading: true,
            privileges: [],
        };
    }

    async componentDidMount() {
        const privileges = await Post(
            'privilege', {}, { limit: 1000 }
        );
        this.setState({ privileges });
    }

    render() {
        const { style, table, onClose, user } = this.props;
        const { unsaved, data, loading, privileges } = this.state;

        return (
            <BaseModal
                style={style}
                unsaved={unsaved}
                hasId={!!data?.id}
                title={<Lang>{table}</Lang>}
                onClose={onClose}
                footer={
                    <ModalFooter
                        table={table}
                        data={data}
                        loading={loading}
                        setLoading={(loading) => {
                            this.setState({ loading });
                        }}
                        onClose={onClose}
                    />
                }
            >
                <ModalPage
                    className="col-md-12"
                    table={table}
                    data={data}
                    onLoad={() => {
                        this.setState({ loading: false });
                    }}
                    onChange={(e: any) => {
                        this.setState({ data: e, unsaved: !loading, loading: false });
                    }}
                    customRender={(v, k) =>
                        k === 'privileges' && (
                            <TableUI>
                                <thead>
                                    <tr>
                                        <th>Taulu</th>
                                        <th>Luonti</th>
                                        <th>Luku</th>
                                        <th>Kirjoitus</th>
                                        <th>Poisto</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        _.map(_.uniqBy(privileges?.data, (v:any)=>v.table), (v, k) => (
                                            <tr key={k}>
                                                <td><Lang>{ v.table }</Lang></td>
                                                {
                                                    _.map(['create', 'read', 'update', 'delete'], (f)=>(
                                                        <td key={f}>
                                                            <CheckboxUI 
                                                                checked={ !!_.find(data?.privileges, (p:any) => p.table === v.table && p.level === f) }
                                                                onChange={(e)=>{
                                                                    if (!data?.privileges) data.privileges = [];
                                                                    if (e.target.checked) {
                                                                        data.privileges.push(
                                                                            _.find(privileges?.data, (p:any)=>(p.table === v.table && p.level === f))
                                                                        );
                                                                    }
                                                                    else _.remove(data.privileges, (p:any) => p.table === v.table && p.level === f);
                                                                    this.setState({ data });
                                                                }} 
                                                            />
                                                        </td>
                                                    ))
                                                }
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </TableUI>
                        )

                        // k === 'privileges' && (
                        //   <DropdownV2
                        //     style={style}
                        //     multiple
                        //     placeholder={lang(k)}
                        //     selected={(e: any) =>
                        //       //_.find(value, (f: any) => (f?.id === e?.id))
                        //       false
                        //     }
                        //     options={async (search: string) => {
                        //       const res = await Post(
                        //         v.join,
                        //         {
                        //           ...(search && { name: `%${search}%` }),
                        //           ...(user?.settings?.office?.id && {
                        //             office: user?.settings?.office?.id
                        //           })
                        //         },
                        //         { limit: 1000 }
                        //       );

                        //       res.data = _.mapValues(res.data, (v, k) => ({
                        //         ...v,
                        //         name: v.table + ' ' + v.level
                        //       }));

                        //       console.log(res);

                        //       return res;
                        //     }}
                        //     onChange={(e: any) => {
                        //       // let newValue = (value || []);
                        //       // if (_.find(newValue, { id: e.id })) _.remove(newValue, { id: e.id });
                        //       // else newValue.push(e);
                        //       // onChange(newValue);
                        //     }}
                        //   />
                        // )
                    }
                />
            </BaseModal>
        );
    }
}

export default connect(
    (state: any) => ({
        user: state?.payload?.user
    }),
    {
        setStore: (payload: any) => ({ type: 'set', payload: payload })
    }
)(Modal);
