import React from 'react';
import { connect } from 'react-redux';
import store from 'store';
import _ from 'lodash';

export function langExists(key: string) {
  const translations = store.getState()?.payload?.translations;
  return _.find(translations?.data, { lang: 'fi', name: key })?.value;
}

export function lang(key: string) {
  const translations = store.getState()?.payload?.translations;
  return (
    (translations?.data &&
      _.find(translations?.data, { lang: 'fi', name: key })?.value) ||
    key?.[0]?.toUpperCase() + key?.slice(1) ||
    ''
  );
}

type Props = { children: string; translations: any; lower?: boolean };
type State = {};

class Lang extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const { children, translations, lower } = this.props;

    let ret: string = (translations?.data &&
      _.find(translations?.data, { lang: 'fi', name: '' + children })
        ?.value) ||
    (children &&
      children.slice &&
      children?.[0]?.toUpperCase() + children?.slice(1)) ||
    children;

    if (lower) ret = ret.toLowerCase();

    return (
      // <Tooltip title="moro">{
      ret || ''
      // }</Tooltip>
    );
  }
}

export default connect(
  (state: any) => {
    return {
      translations: state?.payload?.translations
    };
  },
  {
    // setStore: (payload: any) => ({ type: "set", payload: payload }),
  }
)(Lang);
