import React from 'react';
import _ from 'lodash';
import { SetClass } from 'utils/SetClass';
import { BackendURL } from 'utils/Api';

type Props = {
  src: string;
  className?: string;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler;
  thumb?: number | boolean;
};
type State = {
  status: '' | 'ready' | 'error';
};

export default class Image extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      status: ''
    };
  }

  render() {
    const { src, className, style, onClick, thumb } = this.props;
    const { status } = this.state;

    const image = thumb
      ? BackendURL +
        'thumb/?' +
        (_.isNumber(thumb) ? 'w=' + thumb + '&' : '') +
        'u=' +
        src
      : src;

    return (
      <>
        <img
          className="d-none"
          src={'' + image}
          alt="Loading"
          onError={(e) => {
            this.setState({ status: 'error' });
          }}
          onLoad={(e) => {
            this.setState({ status: 'ready' });
          }}
        />
        <div
          className={SetClass([
            'image',
            status === 'error' && 'no-image',
            className
          ])}
          style={{
            ...style,
            ...(status === 'ready' && { backgroundImage: 'url(' + image + ')' })
          }}
          onClick={(e) => {
            onClick && onClick(e);
          }}
        >
          {
            status === '' && <i className="icon-spinner2 spinner" />
            // || (status === 'error' && <div>Ei kuvaa</div>)
          }
        </div>
      </>
    );
  }
}
