import React from 'react';
import { connect } from 'react-redux';
import { Row } from 'ui/Row';

type Props = {
  children: any;
  buttons?: any;
};
type State = {};

class Card extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  response: any = {};

  render() {
    const { children, buttons } = this.props;

    return (
      <div className="card">
        <div className="card-header border-0">
          <Row className="align-items-center m-0">
            <Row className="m-0" style={{ flex: 1 }}>
              {buttons}
            </Row>
          </Row>
        </div>
        <div className="card-body pt-0">{children}</div>
      </div>
    );
  }
}

export default connect(
  (state: any) => ({
    user: state?.payload?.user
  }),
  {
    setStore: (payload: any) => ({ type: 'set', payload: payload })
  }
)(Card);
