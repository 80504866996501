import BaseDropdown from 'components/BaseDropdown/BaseDropdown';
import DropdownV2 from 'components/Dropdown/DropdownV2';
import Drop, { DropItem } from 'components/UI/Drop';
import SystemIcon from 'components/UI/SystemIcon';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { UserType } from 'store';
import { ClearButton } from 'ui/ClearButton';
import { Post } from 'utils/Api';
import Lang from 'utils/Lang';
import './styles.css';

type Props = {
  setStore: (data: any) => void;
  translations: any;
  user: UserType;
};
type State = {
  open: boolean;
};

function NavbarLink(
  props: React.PropsWithChildren<{ to: string; exact?: boolean }>
) {
  const { to, exact, children } = props;
  return (
    <NavLink className="nav-link" to={to} exact={exact}>
      {children}
    </NavLink>
  );
}

export class Navigation extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      open: false
    };
  }

  render() {
    const { user } = this.props;
    const { open } = this.state;

    return (
      <nav className="navbar navbar-expand-lg navbar-light bg-light p-0 pl-4 pr-4">
        <ClearButton
          className="navbar-toggler"
          style={{ width: '100%', margin: '8px' }}
          onClick={() => {
            this.setState({ open: !open });
          }}
        >
          <i className="icon-menu7 text-dark" />
        </ClearButton>

        <div className={'navbar-collapse' + (!open ? ' collapse' : '')}>
          <div className="mr-auto navbar-nav">
            <NavbarLink to="/" exact>
              <i className="icon-home mr-2" /> Etusivu
            </NavbarLink>

            {_.map(
              user?.links,
              (v: any, k: string) =>
                (
                  <NavbarLink key={k} to={k}>
                    <i className={'mr-2 ' + SystemIcon(k)} />
                    <Lang>{v.title}</Lang>
                  </NavbarLink>
                )
            )}
          </div>
          <div className="navbar-nav">
            <div className="p-1">
              <BaseDropdown
                header={(onClose) => (
                  <div>
                    <Lang>language</Lang>
                  </div>
                )}
                content={(onClose) => (
                  <>
                    <DropItem>FI</DropItem>
                    <DropItem>EN</DropItem>
                    <DropItem>SE</DropItem>
                    <DropItem>PL</DropItem>
                    <DropItem>RU</DropItem>
                  </>
                )}
              />
            </div>

            <div className="p-1">
              <BaseDropdown
                header={(onClose) => <i className="icon-cog3" />}
                content={(onClose) => (
                  <>
                    <DropItem icon={'icon-pushpin'}>
                      <Lang>sticky header</Lang>
                    </DropItem>
                    <DropItem icon={'icon-lock'}>Account security</DropItem>
                    <DropItem icon={'icon-stats-growth'}>Analytics</DropItem>
                    <DropItem icon={'icon-accessibility'}>
                      Accessibility
                    </DropItem>
                    <DropItem icon={'icon-wrench'}>All settings</DropItem>
                  </>
                )}
              />
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default connect(
  (state: any) => {
    return {
      translations: state?.payload?.translations,
      user: state?.payload?.user
    };
  },
  {
    setStore: (payload: any) => ({ type: 'set', payload: payload })
  }
)(Navigation);
