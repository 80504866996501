import { Fade } from 'components/UI/Fade';
import * as Cookies from 'js-cookie';
import React from 'react';
import { connect } from 'react-redux';
import { ButtonUI, SubmitButtonUI } from 'ui/Button';
import { InputUI } from 'ui/InputUI';
import { Post } from 'utils/Api';

import logo_dark from 'assets/images/logo_dark.svg';

type Props = {
  setStore: (data: any) => void;
  match: any;
};
type State = {
  password: string;
  passwordAgain: string;
  status: '' | 'invalid' | 'success' | 'not_match' | 'weak';
  loading: boolean;
};

class Recover extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      password: '',
      passwordAgain: '',
      status: '',
      loading: false
    };
  }

  async setPassword() {
    try {
      const { secret } = this.props?.match?.params;
      const { password, passwordAgain } = this.state;
      if (password !== passwordAgain) {
        this.setState({ status: 'not_match' });
        return;
      }
      const res = await Post('reset', { password, secret });
      this.setState({ status: 'success' });
      // document.location.replace('/login');
    } catch (e) {
      if (e?.error === 'weak') this.setState({ status: 'weak' });
      else this.setState({ status: 'invalid' });
    }
    return false;
  }

  render() {
    const { password, passwordAgain, status } = this.state;

    return (
      <div className="page-content pt-0 h-100 d-flex justify-content-center align-items-center">
        <div className="content-wrapper">
          <div className="content d-flex justify-content-center align-items-center">
            <div
              className="card mb-0"
              style={{ width: '250px', userSelect: 'none' }}
            >
              <div
                className="card-body"
                style={{
                  height: '340px',
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <div className="text-center mb-3 pl-4 pr-4">
                  <div className="d-inline">
                    <img
                      className="logoimg mt-2 mb-3"
                      src={logo_dark}
                      alt="Oitis.fi"
                    />
                  </div>
                </div>

                <div
                  style={{ position: 'relative', flex: 1, overflow: 'hidden' }}
                >
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      this.setPassword();
                    }}
                  >
                    <div style={{ position: 'absolute', width: '100%' }}>
                      <div className="text-center mb-3 pl-4 pr-4">
                        <h5 className="mb-0">Vaihda salasana</h5>
                        {(status === '' && (
                          <span className="d-block text-muted">
                            Anna uusi salasana
                          </span>
                        )) ||
                          (status === 'not_match' && (
                            <span className="d-block text-danger">
                              Salasanat eivät täsmää
                            </span>
                          )) ||
                          (status === 'weak' && (
                            <span className="d-block text-danger">
                              Minimipituus 8 merkkiä
                            </span>
                          )) ||
                          (status === 'invalid' && (
                            <span className="d-block text-danger">
                              Linkki on vanhentunut
                            </span>
                          )) ||
                          (status === 'success' && (
                            <span className="d-block text-success">
                              Salasana vaihdettu
                            </span>
                          ))}
                      </div>
                      <div className="pb-2">
                        <InputUI
                          value={password}
                          type="password"
                          placeholder="Salasana"
                          required
                          onChange={(e) => {
                            this.setState({ password: e.target.value });
                          }}
                        />
                      </div>
                      <div className="pb-2">
                        <InputUI
                          value={passwordAgain}
                          type="password"
                          placeholder="Salasana uudelleen"
                          required
                          onChange={(e) => {
                            this.setState({ passwordAgain: e.target.value });
                          }}
                        />
                      </div>
                      <div className="pb-2">
                        <SubmitButtonUI>
                          Kirjaudu sisään{' '}
                          <i className="icon-circle-right2 ml-2" />
                        </SubmitButtonUI>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  (state: any) => ({
    user: state?.payload?.user
  }),
  {
    setStore: (payload: any) => ({ type: 'set', payload: payload })
  }
)(Recover);
