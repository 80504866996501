import { ws } from 'App';
import BaseDropdown from 'components/BaseDropdown/BaseDropdown';
import Calendar from 'components/Calendar/Calendar';
import PriceCalendar from 'components/Calendar/PriceCalendar';
import DatePickerV2 from 'components/DatePicker/DatePickerV2';
import Input from 'components/Forms/Input';
import ModalSchedule from 'components/Modals/ModalSchedule';
import Card from 'components/Pages/Card';
import { Fade } from 'components/UI/Fade';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { UserType } from 'store';
import { ButtonUI } from 'ui/Button';
import { Post } from 'utils/Api';
import { DateYmd, SqlDate } from 'utils/Dates';
import Lang from 'utils/Lang';
import Table from './Table';

type Props = {
  user: UserType;
};
type State = {
  modal: any;
  options: {
    hideEmpty: boolean;
    showDeleted: boolean;
    editAvailability: any;
    show24h: boolean;
  };
  dateformat: string;
  range: {
    type: moment.unitOfTime.DurationConstructor | 'list';
    start: number;
    end: number;
    step: number;
  };
  resources: any;
  schedules: any;
  sort: string;
  dir: 'asc' | 'desc';
};

class CalendarCard extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      modal: undefined,
      dateformat: 'H:mm',
      options: {
        hideEmpty: false,
        showDeleted: false,
        editAvailability: undefined,
        show24h: true,
      },
      range: {
        type: 'day',
        start: +moment().startOf('day'),
        end: +moment().startOf('day').add(1, 'd'),
        step: 1000 * 60 * 60
      },
      resources: {},
      schedules: {},
      sort: 'created',
      dir: 'asc'
    };
  }

  async updateResources() {
    const { user } = this.props;

    const resources = await Post(
      'resource',
      {
        active: 'active',
        ...(user?.settings?.office && { office: user?.settings?.office?.id })
      },
      {
        limit: 500
      }
    );
    this.setState({ resources });
  }

  async updateSchedules() {
    const { user } = this.props;
    const { range, options } = this.state;

    const schedules = await Post(
      'schedule',
      {
        ...(!options?.showDeleted && { active: '!deleted' }),
        ending: '>=' + SqlDate(range.start),
        start: '<=' + SqlDate(range.end),
        ...(user?.settings?.office?.id && { office: user?.settings?.office.id })
      },
      {
        limit: 500
      }
    );
    this.setState({ schedules });
  }

  componentDidUpdate(prevProps: Props) {
    const { user } = this.props;
    if (user?.settings?.office?.id !== prevProps?.user?.settings?.office?.id) {
      this.updateResources();
      this.updateSchedules();
    }
  }

  ticker: any = undefined;
  prevDate: any = moment();
  idleTime: any = moment();

  componentWillUnmount() {
    clearInterval(this.ticker);
  }

  componentDidMount() {
    this.updateResources();
    this.updateSchedules();

    document.addEventListener('mousemove', () => {
      this.idleTime = moment();
    });

    document.addEventListener('keydown', () => {
      this.idleTime = moment();
    });

    this.ticker = setInterval(() => {
      if (
        // moment().date() !== moment(this.prevDate).date() ||
        moment(this.idleTime).isBefore(moment().subtract(30, 'minute'))
      ) {
        const { range } = this.state;
        const momentRange: moment.unitOfTime.DurationConstructor =
          range.type === 'list' ? 'month' : range.type;
        this.setState(
          {
            range: {
              type: range.type,
              start: +moment().startOf(momentRange),
              end: +moment().startOf(momentRange).add(1, momentRange),
              step: range.step
            }
          },
          () => {
            this.updateSchedules();
          }
        );
        this.prevDate = moment();
      }
    }, 5000);

    ws.onMessage((data: any) => {
      if (data?.update === 'schedule') {
        this.updateSchedules();
        console.log('WebSocket -> Calendar updated');
      }
      if (data?.update === 'resource') {
        this.updateResources();
        console.log('WebSocket -> Resources updated');
      }
    });
  }

  render() {
    const {
      modal,
      range,
      options,
      dateformat,
      resources,
      schedules,
      sort,
      dir
    } = this.state;
    const { user } = this.props;
    const momentRange: moment.unitOfTime.DurationConstructor = range.type === 'list' ? 'month' : range.type;

    return (
      <Card
        buttons={
          <>
            {options.editAvailability && (
              <div className="p-1">
                <ButtonUI clear
                  onClick={() => {
                    options.editAvailability = undefined;
                    this.setState({ options });
                  }}
                >
                  <i className="icon-arrow-left mr-2" />
                  Takaisin varauksiin
                </ButtonUI>
              </div>
            )}

            <div className="p-1 d-flex" style={{ flex: 1 }}>
              <div className="btn-group calcite-button-group" role="group">
                <ButtonUI
                  white={range.type !== 'day'}
                  onClick={() => {
                    this.setState(
                      {
                        dateformat: 'H:mm',
                        range: {
                          type: 'day',
                          start: +moment().startOf('day'),
                          end: +moment().startOf('day').add(1, 'day'),
                          step: 1000 * 60 * 60
                        }
                      },
                      () => {
                        this.updateSchedules();
                      }
                    );
                  }}
                >
                  Päivä
                </ButtonUI>
                <ButtonUI
                  white={range.type !== 'week'}
                  onClick={() => {
                    this.setState(
                      {
                        dateformat: 'dddd D.M',
                        range: {
                          type: 'week',
                          start: +moment().startOf('week'),
                          end: +moment().startOf('week').add(1, 'week'),
                          step: 1000 * 60 * 60 * 24
                        }
                      },
                      () => {
                        this.updateSchedules();
                      }
                    );
                  }}
                >
                  Viikko
                </ButtonUI>
                <ButtonUI
                  white={range.type !== 'month'}
                  onClick={() => {
                    this.setState(
                      {
                        dateformat: 'D.M',
                        range: {
                          type: 'month',
                          start: +moment().startOf('month'),
                          end: +moment().startOf('month').add(1, 'month'),
                          step: 1000 * 60 * 60 * 24
                        }
                      },
                      () => {
                        this.updateSchedules();
                      }
                    );
                  }}
                >
                  Kuukausi
                </ButtonUI>
                <ButtonUI
                  white={range.type !== 'list'}
                  onClick={() => {
                    this.setState(
                      {
                        dateformat: 'D.M',
                        range: {
                          type: 'list',
                          start: +moment().startOf('month'),
                          end: +moment().startOf('month').add(1, 'month'),
                          step: 1000 * 60 * 60 * 24
                        }
                      },
                      () => {
                        this.updateSchedules();
                      }
                    );
                  }}
                >
                  Lista
                </ButtonUI>
              </div>
            </div>
            <div className="col m-0 p-1" style={{ fontSize: '16px', whiteSpace: 'nowrap' }}>
              {
                (
                  options.editAvailability && options.editAvailability?.name
                ) ||
                (
                  moment(range.end).diff(moment(range.start), 'd') > 1 &&
                  <>{moment(range.start).format('D. MMMM Y')} <i className="icon-arrow-right5" /> {moment(range.end).format('D. MMMM Y')}</>
                ) ||
                moment(range.start).format('dddd, D. MMMM Y')
              }
            </div>
            <div className="p-1">
              <DatePickerV2
                onlyDate={true}
                value={DateYmd(range.start)}
                onChange={(date: any) => {
                  this.setState(
                    {
                      range: {
                        type: range.type,
                        start: +moment(date),
                        end: +moment(date).add(1, momentRange),
                        step: range.step
                      }
                    },
                    () => {
                      this.updateSchedules();
                    }
                  );
                }}
              />
            </div>
            <div className="p-1">
              <div className="btn-group calcite-button-group" role="group">
                <ButtonUI
                  style={{ minWidth: '100px' }}
                  white
                  onClick={() => {
                    this.setState(
                      {
                        range: {
                          type: range.type,
                          start: +moment(range.start).subtract(1, momentRange),
                          end: +moment(range.end).subtract(1, momentRange),
                          step: range.step
                        }
                      },
                      () => {
                        this.updateSchedules();
                      }
                    );
                  }}
                >
                  <i className="icon-arrow-left5"></i>
                  {moment(range.start).subtract(1, momentRange).format('DD.MMM')}
                </ButtonUI>
                <ButtonUI
                  style={{ minWidth: '100px' }}
                  white={
                    !moment().isSame(moment(range.start), momentRange)
                  }
                  onClick={() => {
                    this.setState(
                      {
                        range: {
                          type: range.type,
                          start: +moment().startOf(momentRange),
                          end: +moment().startOf(momentRange).add(1, momentRange),
                          step: range.step
                        }
                      },
                      () => {
                        this.updateSchedules();
                      }
                    );
                  }}
                >
                  <Lang>this</Lang> <Lang lower>{momentRange}</Lang>
                </ButtonUI>
                <ButtonUI
                  style={{ minWidth: '100px' }}
                  white
                  onClick={() => {
                    this.setState(
                      {
                        range: {
                          type: range.type,
                          start: +moment(range.start).add(1, momentRange),
                          end: +moment(range.end).add(1, momentRange),
                          step: range.step
                        }
                      },
                      () => {
                        this.updateSchedules();
                      }
                    );
                  }}
                >
                  {moment(range.start).add(1, momentRange).format('DD.MMM')}
                  <i className="icon-arrow-right5"></i>
                </ButtonUI>
              </div>
            </div>
            <div className="p-1">
              <ButtonUI
                onClick={() => {
                  this.setState({
                    modal: {
                      table: 'schedule',
                      data: {},
                      onClose: () => {
                        this.setState({ modal: undefined });
                      }
                    }
                  });
                }}
              >
                <Lang>create new</Lang>
                <i className="icon-plus2 ml-2" />
              </ButtonUI>
            </div>
            <div className="p-1">
              <BaseDropdown
                header={(onClose) => (
                  <ButtonUI light>
                    <i className="icon-cog" />
                  </ButtonUI>
                )}
                content={(onClose) => (
                  <>
                    <ButtonUI
                      white={options.show24h}
                      onClick={() => {
                        this.setState(
                          { options: { ...options, show24h: !options.show24h } },
                          () => {
                            this.updateSchedules();
                          }
                        );
                      }}
                    >
                      Näytä 24h
                    <i className="icon-clock ml-2" />
                    </ButtonUI>
                    <ButtonUI
                      white={!options.hideEmpty}
                      onClick={() => {
                        this.setState(
                          { options: { ...options, hideEmpty: !options.hideEmpty } },
                          () => {
                            this.updateSchedules();
                          }
                        );
                      }}
                    >
                      <Lang>hide empty</Lang>
                      <i className="icon-eye ml-2" />
                    </ButtonUI>
                    <ButtonUI
                      white={!options.showDeleted}
                      onClick={() => {
                        this.setState(
                          {
                            options: { ...options, showDeleted: !options.showDeleted }
                          },
                          () => {
                            this.updateSchedules();
                          }
                        );
                      }}
                    >
                      <Lang>show deleted</Lang>
                      <i className="icon-cross ml-2" />
                    </ButtonUI>
                  </>
                )}
              />
            </div>
            {/* <div className="p-1">
              <ButtonUI
                light={!options.editAvailability}
                onClick={() => {
                  this.setState(
                    {
                      options: {
                        ...options,
                        editAvailability: !options.editAvailability
                      }
                    },
                    () => {
                      this.updateSchedules();
                    }
                  );
                }}
              >
                Muokkaa saatavuutta
                <i className="icon-clock ml-2" />
              </ButtonUI>
            </div> */}
          </>
        }
      >
        {(options.editAvailability && (
          <PriceCalendar
            resource={options.editAvailability}
            schedules={schedules}
            options={options}
            range={range}
            dateformat={dateformat}
            onChange={() => {
              this.updateResources();
            }}
            onSelect={(v: any, deselect?: VoidFunction) => {
              this.setState({
                modal: {
                  table: 'schedule',
                  data: {
                    ...v,
                    start: SqlDate(v?.start),
                    ending: SqlDate(v?.ending)
                  },
                  onClose: () => {
                    deselect && deselect();
                    this.setState({ modal: undefined });
                  }
                }
              });
            }}
          />
        )) ||
          (range.type === 'list' && (
            <Table
              view="list"
              data={schedules}
              table="schedule"
              columns={{
                id: false,
                company: false,
                deletereason: false,
                pricereason: false,
                infotext: false
              }}
              sort={sort}
              dir={dir}
              onSort={(sorting) => {
                this.setState(
                  { sort: sorting?.sort, dir: sorting?.dir },
                  () => {
                    this.updateSchedules();
                  }
                );
              }}
              onModal={(modal) => {
                this.setState({
                  modal: {
                    ...modal,
                    onClose: () => {
                      this.setState({ modal: undefined });
                    }
                  }
                });
              }}
            />
          )) || (
            <Calendar
              resources={resources}
              schedules={schedules}
              options={options}
              range={range}
              dateformat={dateformat}
              onSelect={(v: any, deselect?: VoidFunction) => {
                this.setState({
                  modal: {
                    table: 'schedule',
                    data: {
                      ...v,
                      start: SqlDate(v?.start),
                      ending: SqlDate(v?.ending)
                    },
                    onClose: () => {
                      deselect && deselect();
                      this.setState({ modal: undefined });
                    }
                  }
                });
              }}
              onEditPricing={(resource) => {
                options.editAvailability = resource;
                this.setState({ options });
              }}
            />
          )}

        <Fade
          show={!!modal}
          transition="fade"
          content={(style) => <ModalSchedule {...modal} style={style} />}
        />
      </Card>
    );
  }
}

export default connect(
  (state: any) => ({
    user: state.payload?.user
  }),
  {
    setStore: (payload: any) => ({ type: 'set', payload: payload })
  }
)(CalendarCard);
