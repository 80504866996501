import moment, { Moment } from 'moment';
import { lang } from './Lang';

export const DateYmd = (date: number) => {
  return moment(+new Date(date)).format('YYYY-MM-DD');
};

export const DateTime = (date: number) => {
  if (+moment(date).startOf('day') === +moment().startOf('day'))
    return lang('today') + ' ' + moment(+new Date(date)).format('HH:mm');
  if (+moment(date).startOf('day') === +moment().add(1, 'day').startOf('day'))
    return lang('tomorrow') + ' ' + moment(+new Date(date)).format('HH:mm');
  if (+moment(date).startOf('day') === +moment().add(2, 'day').startOf('day'))
    return (
      lang('day after tomorrow') + ' ' + moment(+new Date(date)).format('HH:mm')
    );
  return moment(+new Date(date)).format('DD.MM HH:mm');
};

export const SqlFormat = 'YYYY-MM-DDTHH:mm:ss';

export const SqlDate = (date: number) => {
  return moment(+new Date(date)).format(SqlFormat);
};

export const RoundDate = (date: number, step: number) => {
  return new Date(date).setMinutes(
    Math.round(+new Date(date).getMinutes() / step) * step,
    0,
    0
  );
};

export const TryDateFormat = (
  sqlDate: string | Moment,
  format: string,
  fallback = ''
): string => {
  const d = moment(sqlDate);
  if (d.isValid()) return d.format(format);
  return fallback;
};

export const MinutesToString = (duration: number): string => {
  const days = Math.floor(duration / 60 / 24);
  const hours = Math.floor((duration / 60) % 24);
  const minutes = Math.floor(duration % 60);
  // const days = moment().add(duration, 'minute').diff('d');
  // const hours = moment().add(duration, 'minute').diff('h');
  // const minutes = moment().add(duration, 'minute').diff('minute');
  return [
    ...((days > 0 && [days + ' vrk']) || []),
    ...((hours > 0 && [hours + ' h']) || []),
    ...((minutes > 0 && [minutes + ' min']) || [])
  ].join(' ');
};
