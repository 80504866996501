import React from 'react';
import _ from 'lodash';
import { InputUI } from 'ui/InputUI';

type Props = {
  value: string;
  placeholder?: string;
  type?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onClick?: React.MouseEventHandler;
  className?: string;
  style?: React.CSSProperties;
  readOnly?: boolean;
  required?: boolean;
  regex?: string;
};
type State = {
  changed: boolean;
};

export default class Input extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      changed: false
    };
  }

  render() {
    const {
      value,
      placeholder,
      type,
      onChange,
      className,
      readOnly,
      regex,
      style,
      onClick,
      required
    } = this.props;
    const { changed } = this.state;
    const error =
      changed &&
      regex !== undefined &&
      !new RegExp(regex || '').test(value || '');

    return (
      <InputUI
        onClick={(e) => {
          onClick && onClick(e);
        }}
        pattern={regex}
        error={error}
        readOnly={readOnly}
        required={required}
        className={className}
        style={style}
        value={value || ''}
        type={type || 'text'}
        spellCheck={false}
        placeholder={placeholder}
        onKeyDown={(e) => {
          if (type === 'text' && e.key === 'Enter') {
            e.preventDefault();
          }
        }}
        onChange={(e) => {
          this.setState({ changed: true });
          onChange && onChange(e);
        }}
      />
    );
  }
}
