import BaseDropdown from 'components/BaseDropdown/BaseDropdown';
import Image from 'components/UI/Image';
import _ from 'lodash';
import React from 'react';
import { UserType } from 'store';
import styled from 'styled-components';
import { Badge } from 'ui/Badge';
import { ClearButton } from 'ui/ClearButton';
import { InputDiv, NoStyleInput } from 'ui/InputUI';
import Lang from 'utils/Lang';
import './styles.css';

const FlexNoWrap = styled.div`
  flex-flow: nowrap;
  display: flex;
  align-items: center;
  width: 100%;
`;

const DropdownIcon = styled.div`
  min-width: 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const DropdownElement = styled.div`
  position: relative;
  width: 100%;
  padding: 0 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
`;

const DropdownTitle = styled.div`
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const RightButton = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 32px;
  right: 0;
`;

type Props = {
  options: (search: string) => any;
  selected: (data: any) => void;
  onChange: (data: any) => void;
  onClear?: () => void;

  value?: any;
  onType?: (data: any) => void;
  placeholder?: any;
  multiple?: boolean;
  allowAny?: boolean;
  readOnly?: boolean;
  required?: boolean;
  regex?: string;
  style?: React.CSSProperties;

  user?: UserType;
};

type State = {
  search: string | undefined;
  changed: boolean;
  results: any;
};

export default class DropdownV2 extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      search: undefined,
      changed: false,
      results: []
    };
  }

  async updateResults(search = '') {
    const { options } = this.props;
    let results = await options(search);
    if (results?.data) results = results.data;
    this.setState({ results });
  }

  componentDidMount() {
    this.updateResults();
  }

  render() {
    const {
      value,
      selected,
      onChange,
      multiple,
      onType,
      readOnly,
      placeholder,
      onClear,
      regex,
      allowAny,
      required,
      style
    } = this.props;
    const { search, results } = this.state;

    // let select: any = [];
    // if (multiple)
    const select = _.filter(results, (f: any) => selected(f));
    // else
    // select = [_.find(results, (f: any) => (selected(f)))];

    return (
      <BaseDropdown
        style={style}
        onBlur={() => {
          this.setState({ search: undefined });
          this.updateResults();
        }}
        header={(onClose) => (
          <>
            <InputDiv style={{ padding: 0, width: '100px' }}>
              {select?.length === 1 &&
                ((select?.[0]?.picture && (
                  <Image
                    thumb={50}
                    src={select?.[0]?.picture}
                    style={{
                      minWidth: '40px',
                      flex: 1,
                      height: '30px',
                      backgroundSize: 'cover',
                      backgroundPosition: 'center'
                    }}
                  />
                )) ||
                  (select?.[0]?.icon && (
                    <DropdownIcon>
                      {_.isString(select?.[0]?.icon) ? (
                        <i className={select?.[0]?.icon + ' dropdown-icon'} />
                      ) : (
                        select?.[0]?.icon
                      )}
                    </DropdownIcon>
                  )))}
              <DropdownElement>
                <NoStyleInput
                  tabIndex={-1}
                  readOnly={!onType && !search && results?.length < 10}
                  required={required && !(select?.length > 0) && !value}
                  pattern={regex}
                  value={value || search || ''}
                  onChange={(e) => {
                    this.setState({ search: e.target.value });
                    this.updateResults(e.target.value);
                    onType && onType(e);
                  }}
                />
                {(select?.length > 1 &&
                  _.map(select, (f: any, k: any) => (
                    <Badge key={k}>
                      {(f?.picture && (
                        <Image
                          thumb={50}
                          src={f?.picture}
                          style={{
                            minWidth: '40px',
                            flex: 1,
                            height: '20px',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center'
                          }}
                        />
                      )) ||
                        (f?.icon && _.isString(f?.icon) ? (
                          <i className={f?.icon + ' mr-2 dropdown-icon'} />
                        ) : (
                          f?.icon
                        ))}
                      <div style={{ padding: '3px 5px' }}>
                        {f?.name || f?.id || ''}
                      </div>
                    </Badge>
                  ))) ||
                  (!value && search === undefined && (
                    <DropdownTitle>
                      {select?.[0]?.name || placeholder}
                    </DropdownTitle>
                  ))}
                {(allowAny || onClear) && (
                  <RightButton>
                    {
                      <ClearButton
                        onMouseDown={(e) => {
                          if (!(value?.length > 0 || select?.length > 0))
                            return;
                          onClose();
                          onClear && onClear();
                          onChange(null);
                          this.setState({ search: undefined });
                        }}
                      >
                        {(value?.length > 0 || select?.length > 0) && (
                          <i className="icon-cross2" />
                        )}
                      </ClearButton>
                    }
                  </RightButton>
                )}
              </DropdownElement>
            </InputDiv>
          </>
        )}
        content={(onClose) => (
          <>
            {(results?.length === 0 && (
              <div className="dropdown-item">
                <Lang>no results</Lang>
              </div>
            )) ||
              _.map(results, (v: any, k: any) => (
                <div
                  key={k}
                  className={
                    'dropdown-item p-0' + (_.find(select, v) ? ' active' : '')
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ search: undefined });
                    !readOnly && onChange && onChange(v);
                    if (multiple) return;
                    onClose();
                  }}
                >
                  <FlexNoWrap>
                    {v?.picture && (
                      <Image
                        thumb
                        src={v?.picture}
                        style={{
                          maxWidth: '50px',
                          height: '32px',
                          backgroundSize: 'cover'
                        }}
                      />
                    )}
                    <div className="flex-auto p-1 ml-1">
                      {(v?.icon && _.isString(v?.icon) ? (
                        <i className={v?.icon + ' mr-2 dropdown-icon'} />
                      ) : (
                        v?.icon
                      )) ||
                        (_.find(select, v) && (
                          <i className={'icon-checkmark mr-2 dropdown-icon'} />
                        )) || (
                          <i
                            className={'icon-arrow-right5 mr-2 dropdown-icon'}
                          />
                        )}
                      {v?.dbtable?.name && (
                        <span className="mr-2">{v?.dbtable?.name}</span>
                      )}
                      {(search !== undefined &&
                        _.indexOf(_.lowerCase(v?.name), _.lowerCase(search)) >=
                          0 && (
                          <>
                            {v?.name.substr(
                              0,
                              _.indexOf(
                                _.lowerCase(v?.name),
                                _.lowerCase(search)
                              )
                            )}
                            <b>
                              {v?.name.substr(
                                _.indexOf(
                                  _.lowerCase(v?.name),
                                  _.lowerCase(search)
                                ),
                                search?.length
                              )}
                            </b>
                            {v?.name.substr(
                              _.indexOf(
                                _.lowerCase(v?.name),
                                _.lowerCase(search)
                              ) + search?.length
                            )}
                          </>
                        )) ||
                        v?.name}
                    </div>
                  </FlexNoWrap>
                </div>
              ))}
          </>
        )}
      />
    );
  }
}
