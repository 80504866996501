import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import React from 'react';
import { connect } from 'react-redux';
import { ButtonUI } from 'ui/Button';
import { Loader } from 'ui/Loader';
import { ModalBackdrop } from 'ui/ModalBackdrop';
import { ModalDialogUI, ModalUI } from 'ui/ModalUI';
import { Row } from 'ui/Row';

type Props = {
  title: any;
  onConfirm?: () => void;
  onCancel?: () => void;
  style?: React.CSSProperties;
  children?: any;
};
type State = {
  loading: boolean;
};

class Modal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false
    };
  }

  componentDidMount() {
    disableBodyScroll(document.querySelector('#modal') || document.body);
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks();
  }

  render() {
    const { title, style, children, onConfirm, onCancel } = this.props;
    const { loading } = this.state;

    return (
      <>
        <ModalBackdrop style={{ ...style, zIndex: 2000 }} />
        <ModalUI
          style={{ ...style, zIndex: 2001, alignItems: 'center' }}
          onMouseDown={(e) => {
            if (e.target === e.currentTarget) {
              e.preventDefault();
              e.stopPropagation();
              this.setState({ loading: true });
              onCancel && onCancel();
            }
          }}
          onTouchEnd={(e) => {
            if (e.target === e.currentTarget) {
              e.preventDefault();
              e.stopPropagation();
              this.setState({ loading: true });
              onCancel && onCancel();
            }
          }}
        >
          <ModalDialogUI style={{ maxWidth: '400px' }}>
            {loading && <Loader />}
            <div className="modal-content">
              <div className="modal-header">
                <div className="modal-title">{title}</div>
                <a
                  href="close"
                  onClick={(e) => {
                    e.preventDefault();
                    if (loading) return;
                    this.setState({ loading: true });
                    onCancel && onCancel();
                  }}
                >
                  <i className="icon-cross2 modal-title" />
                </a>
              </div>
              <div className="modal-body pt-0" style={{ display: 'flex' }}>
                <Row style={{ width: '100vw', maxWidth: '500px' }}>
                  <div className="col mt-3">{children}</div>
                </Row>
              </div>
              <div className="modal-footer">
                <div>
                  <ButtonUI
                    clear
                    onClick={(e) => {
                      e.preventDefault();
                      if (loading) return;
                      this.setState({ loading: true });
                      onCancel && onCancel();
                    }}
                  >
                    Peruuta
                  </ButtonUI>
                </div>
                <div>
                  <ButtonUI
                    onClick={(e) => {
                      e.preventDefault();
                      if (loading) return;
                      this.setState({ loading: true });
                      onConfirm && onConfirm();
                    }}
                  >
                    Vahvista
                  </ButtonUI>
                </div>
              </div>
            </div>
          </ModalDialogUI>
        </ModalUI>
      </>
    );
  }
}

export default connect(
  (state: any) => ({
    user: state?.payload?.user
  }),
  {
    setStore: (payload: any) => ({ type: 'set', payload: payload })
  }
)(Modal);
