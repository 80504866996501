import { ContextMenuData } from 'components/ContextMenu';
import { DialogData } from 'components/Modals/Dialog';
import _ from 'lodash';
import { combineReducers, createStore } from 'redux';

export type UserType = {
  user: any;
  privileges: any;
  types: any;
  links: any;
  settings: {
    company: any;
    office: any;
  };
  super: { sidebar: boolean };
};

type State = {
  user?: UserType;
  translations?: any;
  dialog?: DialogData;
  contextMenu?: ContextMenuData;
};

const payload = (state: State = {}, action: any) => {
  // console.log(state, action.type, action.payload);
  switch (action.type) {
    case 'set': {
      return _.cloneDeep(_.assign(state, action.payload));
    }
    default: {
      return state;
    }
  }
};

export default createStore(combineReducers({ payload }));
