import React from 'react';
import styled, { css } from 'styled-components';

export const Loader = () => {
  return (
    <div className="loader">
      <div className="loader__svgs">
        <svg className="loader__svg loader__svg--1" viewBox="0 0 56 56">
          <circle r="25.2" cx={28} cy={28} />
        </svg>
        <svg className="loader__svg loader__svg--2" viewBox="0 0 56 56">
          <circle r="25.2" cx={28} cy={28} />
        </svg>
        <svg className="loader__svg loader__svg--3" viewBox="0 0 56 56">
          <circle r="25.2" cx={28} cy={28} />
        </svg>
      </div>
    </div>
  );
};
