import { dialog } from 'components/Modals/Dialog';
import { contextMenu } from 'components/ContextMenu';
import { printContract } from 'components/Modals/ModalSchedule';
import { toastError, toastSuccess } from 'components/Toasts/Success';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { UserType } from 'store';
import styled from 'styled-components';
import { Row } from 'ui/Row';
import { Put } from 'utils/Api';
import { RoundDate, SqlDate } from 'utils/Dates';
import Lang from 'utils/Lang';

import './Calendar.css';
import Backdrop from 'components/UI/Backdrop';
import { ModalBackdrop } from 'ui/ModalBackdrop';
import { ClearButton } from 'ui/ClearButton';

export const TimeblockResizer = styled.div`
  position: absolute;
  right: 0px;
  width: 8px;
  height: 100%;
  top: 0px;
  cursor: e-resize;
  pointer-events: auto;

  :hover {
    background: #00000025;
  }
`;

type Props = {
  date?: number;
  dateformat: string;
  range: {
    type: moment.unitOfTime.DurationConstructor | 'list';
    start: number;
    end: number;
    step: number;
  },
  onSelect: (data: any, onDeselect?: VoidFunction) => void;
  onEditPricing: (resource: any) => void;
  options?: any;
  user: UserType;
  resources: any;
  schedules: any;
  setStore: any;
};

type State = {
  select: any;
  drag: any;
  now: number;
  resizeOpening: any;
  resizeWeekDay: any;
};

class Calendar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      select: null,
      drag: null,
      now: +Date.now(),
      resizeOpening: null,
      resizeWeekDay: null
    };
  }

  checker = React.createRef<HTMLDivElement>();
  ticker: any = undefined;
  prevDate: any = moment();

  componentWillUnmount() {
    clearInterval(this.ticker);
  }

  componentDidMount() {
    this.ticker = setInterval(() => {
      this.setState({ now: Date.now() });
    }, 5000);
  }

  async saveSchedule(data: any) {
    const { drag } = this.state;
    try {
      const res = await Put('schedule', {
        ...data
      });
      toastSuccess();
      return res;
    } catch (e) {
      if (drag?.schedule && drag?.original) {
        drag.schedule.resource = drag.original.resource;
        drag.schedule.start = drag.original.start;
        drag.schedule.ending = drag.original.ending;
        this.setState({ drag: null });
      }
      toastError(e);
    }
  }

  render() {
    const {
      date,
      range,
      dateformat,
      onSelect,
      options,
      user,
      schedules,
      resources,
      onEditPricing
    } = this.props;
    const { select, drag, now, resizeOpening, resizeWeekDay } = this.state;

    let resourcesData = _.filter(
      resources?.data,
      (resource: any) =>
        resource?.reservable === true &&
        (!options?.hideEmpty ||
          _.find(schedules?.data, (f: any) =>
            _.isEqual(f?.resource?.id, resource?.id)
          ))
    );

    resourcesData = _.sortBy(resourcesData, (v: any) => v?.name);
    resourcesData = _.sortBy(resourcesData, (v: any) => v?.category?.created);
    resourcesData = _.sortBy(resourcesData, (v: any) => v?.office?.name);


    const date2 = moment(range?.start);
    const weekday = _.find(user?.settings?.office?.openings, {
      weekday: date2.weekday()
    });
    // console.log(weekday.opentime);
    const open = moment(weekday?.opentime, [
      moment.ISO_8601,
      'HH:mm'
    ]);
    const close = moment(weekday?.closetime, [
      moment.ISO_8601,
      'HH:mm'
    ]);
    const opentime = moment(range?.start)
      .hour(open.hour())
      .minute(open.minute());
    const closetime = moment(range?.start)
      .hour(close.hour())
      .minute(close.minute());


    const rangeStart =
      (user?.settings?.office && range?.type === 'day' && options?.show24h && opentime) || range?.start || new Date(date || Date.now()).setHours(6, 0, 0, 0);
    const rangeEnd =
      (user?.settings?.office && range?.type === 'day' && options?.show24h && closetime) || range?.end || new Date(date || Date.now()).setHours(20, 0, 0, 0);
    const rangeStep = range?.step || 1000 * 60 * 60;
    const minStep = 15; // rangeStep / (1000 * 60) / 4;
    const drawSchedules = _.union(
      schedules?.data,
      select?.schedule && [select.schedule],
      drag?.schedule && [drag.schedule]
    );

    return (
      <>
        <div
          className="container-fluid calendar"
          onMouseMove={(e: any) => {
            if (!drag?.schedule) return;
            if (drag?.schedule?.status === 'ready') return;

            if (e.buttons !== 1) {
              drag.schedule.resource = drag.original.resource;
              drag.schedule.start = drag.original.start;
              drag.schedule.ending = drag.original.ending;
              this.setState({ drag: null });
              return;
            }
            const step = (rangeEnd - rangeStart) / drag.w;

            drag.schedule.ending = new Date(
              +new Date(drag.original.ending) + (e.clientX - drag.x) * step
            );
            if (!drag.resize) {
              drag.schedule.start = new Date(
                +new Date(drag.original.start) + (e.clientX - drag.x) * step
              );
              const moveY = (e.clientY - drag.top - drag.h) / drag.h;
              if (resourcesData[Math.ceil(moveY)]) {
                drag.schedule.resource = resourcesData[Math.ceil(moveY)];
              }
            }

            const start = drag.schedule.start;
            const ending = drag.schedule.ending;
            drag.error = false;

            _.map(schedules?.data, (s: any) => {
              if (s === drag.schedule) return;
              if (s?.active !== 'active') return;
              if (s?.id + '' === drag.schedule?.id + '') return;
              if (s.resource?.id + '' !== drag.schedule?.resource?.id + '')
                return;
              if (start < +new Date(s.ending) && ending > +new Date(s.start)) {
                drag.error = true;
              }
            });

            this.setState({ drag: { ...drag, schedule: drag.schedule } });
          }}
          onMouseUp={async () => {
            if (!drag?.schedule) return;

            if (
              RoundDate(drag.schedule.start, minStep) -
                RoundDate(drag.original.start, minStep) ===
                0 &&
              RoundDate(drag.schedule.ending, minStep) -
                RoundDate(drag.original.ending, minStep) ===
                0 &&
              drag.schedule.resource?.id === drag.original.resource?.id
            ) {
              drag.schedule.start = drag.original.start;
              drag.schedule.ending = drag.original.ending;
              this.setState({ drag: null });
              if (onSelect) {
                onSelect(drag.schedule);
              }
              return;
            }

            const start = RoundDate(drag.schedule.start, minStep);
            const ending = RoundDate(drag.schedule.ending, minStep);

            if (
              _.find(schedules?.data, (s: any) => {
                if (s === drag.schedule) return;
                if (s?.active !== 'active') return;
                if (s?.id === drag.schedule?.id) return;
                if (s.resource?.id !== drag.schedule?.resource?.id) return;
                if (
                  start < +new Date(s.ending) &&
                  ending > +new Date(s.start)
                ) {
                  drag.schedule.resource = drag.original.resource;
                  drag.schedule.start = drag.original.start;
                  drag.schedule.ending = drag.original.ending;
                  this.setState({ drag: null });
                  return true;
                }
              })
            )
              return;

            drag.schedule.start = start;
            drag.schedule.ending = ending;

            const confirm = await dialog({
              title: (
                <>
                  <div className="mb-2">Muutetaanko varausta?</div>
                  {_.map(drag?.schedule, (v: any, k: string) => {
                    const o = drag?.original?.[k];
                    return (
                      JSON.stringify(o) !== JSON.stringify(v) &&
                      (!v?.id || o?.id !== v?.id) &&
                      (user?.types?.schedule?.[k]?.type !== 'datetime' ||
                        moment(v).valueOf() !== moment(o).valueOf()) &&
                      (v?.name || !_.isObject(v)) && (
                        <Row>
                          <div className="col-3 text-nowrap font-weight-bold">
                            <Lang>{k}</Lang>:{' '}
                          </div>
                          <div className="col">
                            {v?.name ||
                              (user?.types?.schedule?.[k]?.type ===
                                'datetime' &&
                                moment(v).format('HH:mm DD.MM.YYYY')) ||
                              v}
                          </div>
                        </Row>
                      )
                    );
                  })}
                </>
              )
            });

            if (confirm) {
              if (!drag?.schedule) return;
              this.saveSchedule({
                id: drag.schedule.id,
                resource: drag.schedule.resource?.id,
                customer: drag.schedule.customer?.id,
                start: SqlDate(drag.schedule.start),
                ending: SqlDate(drag.schedule.ending)
              }).finally(() => {
                this.setState({ drag: null });
              });
            } else {
              drag.schedule.resource = drag.original.resource;
              drag.schedule.start = drag.original.start;
              drag.schedule.ending = drag.original.ending;
              this.setState({ drag: null });
            }
          }}
        >
          <div
            className="row"
            style={{ boxShadow: '0 1px 0 #ddd, 1px 0 0 #ddd' }}
          >
            <div
              className="col-auto p-0"
              style={{ maxWidth: '20%', width: '100%' }}
            >
              <div className="cell-head">
                <Lang>resources</Lang>
              </div>
              {_.map(resourcesData, (resource: any, key: any) => (
                <div
                  key={key}
                  className="cell"
                  style={{
                    display: 'flex',
                    ...(drag?.schedule?.resource?.id === resource.id && {
                      background: drag?.error ? '#daa' : '#dfdfdf'
                    }),
                    ...(select?.schedule?.resource?.id === resource.id && {
                      background: select?.error ? '#daa' : '#dfdfdf'
                    })
                  }}
                >
                  <div className="mr-2">
                    <ClearButton
                      onClick={(e) => {
                        e.stopPropagation();
                        onEditPricing(_.cloneDeep(resource));
                      }}
                    >
                      <i className="icon-price-tags" style={{ color: '#35ac46' }} />
                    </ClearButton>
                  </div>
                  <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {resource?.name}
                  </div>
                </div>
              ))}
            </div>
            <div className="col p-0" style={{ overflow: 'hidden' }}>
              <Row className="m-0 flex-nowrap">
                {_.map(
                  _.range(rangeStart, rangeEnd, rangeStep),
                  (v: any, k: any) => (
                    <div key={k} className="col cell-head">
                      {moment(v).format(dateformat)}
                    </div>
                  )
                )}
              </Row>
              <div
                ref={this.checker}
                className="checker"
                style={{
                  backgroundSize:
                    100.0 / Math.ceil((rangeEnd - rangeStart) / rangeStep) +
                    '% 44px'
                }}
                onMouseDown={(e: any) => {
                  if (!resources) return;
                  if (e.button !== 0) return;
                  if (drag) return;
                  const size = e.currentTarget.getBoundingClientRect();

                  const resource =
                    resourcesData[Math.ceil((e.clientY - size.top - 44) / 44)];

                  const start = RoundDate(
                    +rangeStart +
                      (rangeEnd - rangeStart) *
                        ((e.clientX - size.left) / size.width),
                    minStep
                  );
                  const ending = RoundDate(
                    +start + 1000 * 60 * minStep,
                    minStep
                  );

                  // const staticStart = moment(resource?.pricing?.[0]?.start_time, [moment.ISO_8601, 'HH:mm:ss']);
                  // const staticEnd = moment(resource?.pricing?.[0]?.end_time, [moment.ISO_8601, 'HH:mm:ss']);
                  // start = +moment(start).startOf('d').add(staticStart.hour(), 'h').add(staticStart.minute(), 'minute');
                  // ending = +moment(start).add(1,'day').startOf('d').add(staticEnd.hour(), 'h').add(staticEnd.minute(), 'minute');

                  if (
                    _.find(schedules?.data, (s: any) => {
                      if (s.resource?.id + '' !== resource?.id + '') return;
                      if (
                        start < +new Date(s.ending) &&
                        ending > +new Date(s.start)
                      )
                        return true;
                    })
                  )
                    return;

                  this.setState({
                    drag: null,
                    select: {
                      schedule: {
                        office: resource?.office,
                        resource: resource,
                        start: start,
                        ending: ending
                      },
                      lock: false,
                      time: RoundDate(start, minStep),
                      error: false
                    }
                  });
                }}
                onMouseMove={(e: any) => {
                  if (drag || !select) return;
                  if (select?.lock) return;
                  const size = e.currentTarget.getBoundingClientRect();

                  const time =
                    +rangeStart +
                    (rangeEnd - rangeStart) *
                      ((e.clientX - size.left) / size.width);
                  const newTime = RoundDate(time, minStep);

                  const start =
                    time > select.time ? select.time : new Date(newTime);
                  const ending =
                    time > select.time ? new Date(newTime) : select.time;

                  if (
                    _.find(schedules?.data, (s: any) => {
                      if (
                        s.resource?.id + '' !==
                        select?.schedule?.resource + ''
                      )
                        return;
                      if (
                        start < +new Date(s.ending) &&
                        ending > +new Date(s.start)
                      )
                        return true;
                    })
                  )
                    return;

                  select.error = false;
                  _.map(schedules?.data, (s: any) => {
                    if (s === select.schedule) return;
                    if (s?.active !== 'active') return;
                    if (s?.id + '' === select.schedule?.id + '') return;
                    if (
                      s.resource?.id + '' !==
                      select.schedule?.resource?.id + ''
                    )
                      return;
                    if (
                      start < +new Date(s.ending) &&
                      ending > +new Date(s.start)
                    ) {
                      select.error = true;
                    }
                  });

                  this.setState({
                    select: {
                      ...select,
                      schedule: {
                        ...select?.schedule,
                        start: start,
                        ending: ending
                      }
                    }
                  });
                }}
                onMouseUp={() => {
                  if (drag || !select) return;
                  if (select?.error) {
                    this.setState({ select: null });
                    return;
                  }
                  this.setState({ select: { ...select, lock: true } });

                  // if (options?.editAvailability) {
                  //     return;
                  // }

                  const exactly = _.find(select.schedule.resource.pricing, { type: "duration_exactly" })
                  if (exactly) {
                    const start = moment(exactly.start);
                    const ending = moment(exactly.ending);
                    const duration = exactly.duration;
                    const days = moment(select.schedule.ending).diff(moment(select.schedule.start), 'd');
                    console.log("days", days);
                    select.schedule.start = +moment(select.schedule.start).hour(start.hour()).minute(start.minute()).second(0);
                    select.schedule.ending = +moment(select.schedule.start)
                      .hour(start.hour()).minute(start.minute()).second(0)
                      .add(duration, 'minutes')
                      .add(days, 'd');
                  }

                  onSelect &&
                    onSelect(select?.schedule, () => {
                      this.setState({ select: null });
                    });
                }}
              >
                {(drawSchedules?.length > 0 &&
                  _.map(
                    _.filter(
                      _.merge(drawSchedules),
                      (f: any) =>
                        +new Date(f?.ending) >= rangeStart &&
                        +new Date(f?.start) < rangeEnd
                    ),
                    (f: any, k: any) =>
                      _.find(
                        resourcesData,
                        (v: any) => '' + v?.id === '' + f?.resource?.id
                      ) && (
                        <div
                          key={k}
                          className="timeblock"
                          style={{
                            transition:
                              drag || select
                                ? 'background 0.2s, top 0.05s, filter 0.1s, transform 0.1s'
                                : 'filter 0.1s, transform 0.1s',
                            // ...(drag?.schedule === f && { cursor: 'move' }),
                            background:
                              (select?.schedule === f &&
                                select?.error === true &&
                                'rgba(170,85,85,0.5)') ||
                              (select?.schedule === f && '#007ac280') ||
                              (drag?.schedule === f &&
                                drag?.error === true &&
                                'rgba(170,85,85,0.5)') ||
                              (f.active === 'deleted' && '#4a4a4a44') || // Poistettu
                              (f.status === 'new' && '#007ac2') || // Uusi varaus
                              (f.status === 'progress' && '#35ac46') || // Aloitettu
                              (f.status === 'ready' && '#35ac4688') || // Päättynyt
                              (f.status === 'cancelled' && '#4a4a4a88') || // Peruttu
                              '',
                            ...(select?.schedule === f &&
                              select?.error === true && { zIndex: 9999 }),
                            ...(drag?.schedule === f &&
                              drag?.error === true && { zIndex: 9999 }),
                            ...(drag?.schedule === f && {
                              border: '1px dashed rgba(0,0,0,0.3)'
                            }),
                            ...((drag?.schedule === f || select === f) && {
                              filter: 'drop-shadow(0px 3px 6px #888)'
                            }),
                            // ...((drag?.schedule?.id === f?.id && drag?.schedule !== f) && { opacity: '0.6' }),
                            top:
                              resourcesData?.indexOf(
                                _.find(
                                  resourcesData,
                                  (v: any) =>
                                    '' + v?.id === '' + f?.resource?.id
                                )
                              ) *
                                44 +
                              'px',
                            left:
                              ((+new Date(f?.start) - rangeStart) /
                                (rangeEnd - rangeStart)) *
                                100 +
                              '%',
                            width:
                              ((+new Date(f?.ending) - +new Date(f?.start)) /
                                (rangeEnd - rangeStart)) *
                                100 +
                              '%',
                            paddingLeft:
                              'calc(4px + ' +
                              ((((+new Date(f?.start) - rangeStart) /
                                (rangeEnd - rangeStart)) *
                                100 <
                                0 &&
                                (-(+new Date(f?.start) - rangeStart) /
                                  (rangeEnd - rangeStart)) *
                                  100) ||
                                0) +
                              '%)'
                          }}
                          onContextMenu={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            contextMenu({
                              x: e.clientX,
                              y: e.clientY,
                              content: (onClose) => (
                                <div className="dropdown-menu show m-0 mb-3">
                                  {f?.status === 'new' && (
                                    <button
                                      className="dropdown-item"
                                      onClick={async () => {
                                        onClose();
                                        if (
                                          !(await dialog({
                                            title:
                                              'Merkitäänkö varaus alkaneeksi?'
                                          }))
                                        )
                                          return;
                                        await this.saveSchedule({
                                          ...f,
                                          status: 'progress'
                                        });
                                        printContract(f?.id);
                                      }}
                                    >
                                      <div className="flex-auto">
                                        <i className="icon-arrow-right5 mr-2 dropdown-icon" />
                                        Merkitse alkaneeksi
                                      </div>
                                    </button>
                                  )}
                                  {f?.status !== 'new' && (
                                    <button
                                      className="dropdown-item"
                                      onClick={async () => {
                                        onClose();
                                        printContract(f?.id);
                                      }}
                                    >
                                      <div className="flex-auto">
                                        <i className="icon-arrow-right5 mr-2 dropdown-icon" />
                                        Tulosta uudelleen
                                      </div>
                                    </button>
                                  )}
                                  {f?.status === 'progress' && (
                                    <button
                                      className="dropdown-item"
                                      onClick={async () => {
                                        onClose();
                                        if (
                                          !(await dialog({
                                            title:
                                              'Merkitäänkö varaus päättyneeksi?'
                                          }))
                                        )
                                          return;
                                        await this.saveSchedule({
                                          ...f,
                                          status: 'ready'
                                        });
                                      }}
                                    >
                                      <div className="flex-auto">
                                        <i className="icon-arrow-right5 mr-2 dropdown-icon" />
                                        Merkitse päättyneeksi
                                      </div>
                                    </button>
                                  )}
                                  {
                                    <button
                                      className="dropdown-item"
                                      onClick={async () => {
                                        onClose();
                                        if (
                                          !(await dialog({
                                            title: 'Poistetaanko?'
                                          }))
                                        )
                                          return;
                                        await this.saveSchedule({
                                          ...f,
                                          active: 'deleted'
                                        });
                                      }}
                                    >
                                      <div className="flex-auto">
                                        <i className="icon-arrow-right5 mr-2 dropdown-icon" />
                                        <Lang>delete</Lang>
                                      </div>
                                    </button>
                                  }
                                </div>
                              )
                            });
                            return false;
                          }}
                          onMouseDown={(e) => {
                            if (!resources) return;
                            if (e.button !== 0) return;
                            if (!f?.id || !f?.resource) return;
                            e.stopPropagation();

                            const targetSize = e.currentTarget.getBoundingClientRect();
                            const parentSize = e.currentTarget?.parentElement?.getBoundingClientRect();
                            this.setState({
                              drag: {
                                color:
                                  'linear-gradient(0deg, green 0%, green 20%)',
                                speedX: 0,
                                schedule: f,
                                original: _.cloneDeep(f),
                                resize:
                                  e.clientX - targetSize.left >
                                  targetSize.width - 8,
                                x: e.clientX,
                                y: e.clientY,
                                w: parentSize?.width,
                                h: 44,
                                top: parentSize?.top
                              },
                              select: null
                            });
                          }}
                        >
                          {f?.source === 'nettivaraus' && (
                            <span>
                              <i className="icon-cart" />{' '}
                            </span>
                          )}
                          {moment(f?.start).format('HH:mm')} -{' '}
                          {moment(f?.ending).format('HH:mm')}
                          <span> {f?.customer?.name}</span>
                          <br />
                          <span> {f?.resource?.name}</span>
                          <TimeblockResizer />
                        </div>
                      )
                  )) ||
                  (options?.hideEmpty &&
                    resourcesData?.length > 0 &&
                    schedules?.data?.length === 0 && (
                      <div className="noreservations">Ei varauksia</div>
                    )) ||
                  (resources?.data?.length === 0 && (
                    <div className="noreservations">
                      Ei resursseja,{' '}
                      <NavLink to="resource">luo resurssi</NavLink>
                    </div>
                  ))}

                {user?.settings?.office?.openings &&
                  _.map(
                    _.range(moment(rangeEnd).diff(moment(rangeStart), 'd')),
                    (v, k) => {
                      const date = moment(rangeStart).add(v, 'd');
                      const weekday = _.find(user?.settings?.office?.openings, {
                        weekday: date.weekday()
                      });
                      // console.log(weekday.opentime);
                      const open = moment(weekday?.opentime, [
                        moment.ISO_8601,
                        'HH:mm'
                      ]);
                      const close = moment(weekday?.closetime, [
                        moment.ISO_8601,
                        'HH:mm'
                      ]);
                      const opentime = moment(date)
                        .hour(open.hour())
                        .minute(open.minute());
                      const closetime = moment(date)
                        .hour(close.hour())
                        .minute(close.minute());

                      return (
                        <>
                          <div
                            className="timeblock"
                            key={v}
                            style={{
                              top: 0,
                              pointerEvents: 'none',
                              left:
                                ((+date - rangeStart) /
                                  (rangeEnd - rangeStart)) *
                                  100 +
                                '%',
                              width:
                                ((+opentime - +date) /
                                  (rangeEnd - rangeStart)) *
                                  100 +
                                '%',
                              height: '100%',
                              opacity: 0.3,
                              background: '#00000033',
                              cursor: 'default'
                            }}
                          >
                            {/* <TimeblockResizer
                              onMouseDown={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                this.setState({
                                  resizeOpening: e.screenX,
                                  resizeWeekDay: weekday
                                });
                              }}
                            /> */}
                            {
                              // resizeWeekDay && (
                              //     <ModalBackdrop style={{ background: 0, pointerEvents: 'auto' }}
                              //         onMouseMove={async (e) =>
                              //         {
                              //             e.preventDefault();
                              //             e.stopPropagation();
                              //             const open = moment(resizeWeekDay?.opentime, [moment.ISO_8601, 'HH:mm:ss']);
                              //             if (!resizeWeekDay.opentimeTemp) resizeWeekDay.opentimeTemp = ''+resizeWeekDay.opentime;
                              //             // console.log(resizeWeekDay.id);
                              //             if (e.buttons === 1) {
                              //                 const checkerSize = this.checker?.current?.getBoundingClientRect();
                              //                 const diff = (rangeEnd - rangeStart) / (checkerSize?.width || 1.0) * (e.screenX - resizeOpening);
                              //                 resizeWeekDay.opentime = moment(+open + diff).format('HH:mm:ss');
                              //                 this.setState({ resizeOpening: e.screenX });
                              //                 return;
                              //             }
                              //             if (resizeWeekDay?.opentimeTemp)
                              //             {
                              //                 resizeWeekDay.opentime = moment(resizeWeekDay?.opentime, [moment.ISO_8601, 'HH:mm:ss']).valueOf();
                              //                 resizeWeekDay.opentime = RoundDate(resizeWeekDay.opentime, minStep);
                              //                 resizeWeekDay.opentime = moment(resizeWeekDay.opentime).format('HH:mm');
                              //                 if (resizeWeekDay.opentimeTemp !== resizeWeekDay.opentime) {
                              //                     if (!await dialog({ title: 'Muutetaanko aukioloaikaa?' })) {
                              //                         const weekday = _.find(user?.settings?.office?.openings, { id: resizeWeekDay.id });
                              //                         console.log("cancelled", weekday.id, weekday.opentime, resizeWeekDay.opentimeTemp);
                              //                         weekday.opentime = ''+resizeWeekDay.opentimeTemp;
                              //                     }
                              //                 }
                              //             }
                              //             this.setState({ resizeOpening: null, resizeWeekDay: null });
                              //         }}
                              //     />
                              // )
                            }
                          </div>
                          <div
                            className="timeblock"
                            key={v}
                            style={{
                              top: 0,
                              pointerEvents: 'none',
                              left:
                                ((+closetime - +rangeStart) /
                                  (rangeEnd - rangeStart)) *
                                  100 +
                                '%',
                              width:
                                ((+moment(date).add(1, 'd') - +closetime) /
                                  (rangeEnd - rangeStart)) *
                                  100 +
                                '%',
                              height: '100%',
                              opacity: 0.3,
                              background: '#00000033',
                              cursor: 'default'
                            }}
                          >
                            {/* <TimeblockResizer onMouseDown={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                this.setState({ resizeOpening: e.screenX, resizeWeekDay: weekday });
                                            }} /> */}
                          </div>
                        </>
                      );
                    }
                  )}
                <div
                  className="checker-line"
                  style={{
                    left:
                      ((now - rangeStart) / (rangeEnd - rangeStart)) * 100 + '%'
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(
  (state: any) => ({
    user: state?.payload?.user
  }),
  {
    setStore: (payload: any) => ({ type: 'set', payload: payload })
  }
)(Calendar);
