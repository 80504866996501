import Backdrop from 'components/UI/Backdrop';
import React from 'react';
import { SetClass } from 'utils/SetClass';

export const DropItem = (
  props: React.PropsWithChildren<{
    onClick?: React.MouseEventHandler;
    icon?: string;
  }>
) => {
  const { onClick, icon, children } = props;
  return (
    <a href="#" role="button" className="dropdown-item" onClick={onClick}>
      <div className="flex-auto">
        <i className={(icon || 'icon-arrow-right5') + ' mr-2 dropdown-icon'} />
        {children}
      </div>
      <small />
    </a>
  );
};

type Props = {
  title?: any;
  style?: React.CSSProperties;
};
type State = {
  open: boolean;
  focus: boolean;
};

export default class Drop extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      open: false,
      focus: false
    };
  }

  getInput(e: React.MouseEvent | React.FocusEvent | React.KeyboardEvent) {
    let input: any = e?.currentTarget;
    while (!input?.getElementsByTagName('input')?.item(0)) {
      input = input?.parentElement;
    }
    return input?.getElementsByTagName('input')?.item(0);
  }

  render() {
    const { title, children, style } = this.props;
    const { open, focus } = this.state;

    return (
      <>
        {open && focus && (
          <Backdrop
            onFocus={() => {
              this.setState({ focus: false });
            }}
          />
        )}
        <div className={SetClass(['dropdown', open && 'show'])}>
          <div
            style={{
              minWidth: title ? '3rem' : '50px',
              minHeight: '2rem',
              ...style
            }}
            className={'position-relative d-flex align-items-center h-100 p-1'}
            role="button"
            onMouseDown={(e) => {
              if (!open) {
                e.preventDefault();
                this.getInput(e)?.focus();
              }
            }}
          >
            <input
              type="text"
              style={{ opacity: 0, width: 1, height: 1 }}
              onFocus={(e) => {
                e.preventDefault();
                this.setState({ open: true, focus: true });
              }}
              onBlur={(e) => {
                e.preventDefault();
                if (!focus) this.setState({ open: false, focus: false });
              }}
            />
            {title}
            <div className="dropdown-arrow ml-1">
              {
                <i
                  className={
                    'icon-arrow-down5' + (open ? ' icon-rotate-180' : '')
                  }
                />
              }
            </div>
          </div>
          <div
            className="dropdown-menu dropdown-menu-right m-0"
            style={{ width: 'auto' }}
            onMouseEnter={(e) => {
              if (!open) return;
              this.setState({ focus: true });
              this.getInput(e)?.focus();
            }}
            onMouseMove={(e) => {
              if (!open) return;
              this.setState({ focus: true });
              this.getInput(e)?.focus();
            }}
            onMouseOver={(e) => {
              if (!open) return;
              this.setState({ focus: true });
              this.getInput(e)?.focus();
            }}
          >
            {children}
          </div>
        </div>
      </>
    );
  }
}
