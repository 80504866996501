import styled, { css } from 'styled-components';

export const ClearButton = styled.button.attrs({ type: 'button' })<{
  small?: boolean;
}>`
  border: 0;
  background: none;
  outline: 0 !important;
  padding: 0 !important;
  transform: none;
  transition: all 100ms ease-in-out;
  pointer-events: all !important;

  :active,
  :hover {
    outline: 0;
    transform: scale(1.2);
  }

  ${(props) =>
    props.small &&
    css`
      font-size: 12px;
    `}
`;
