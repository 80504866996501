import styled, { css } from 'styled-components';

export const DatepickerDay = styled.div<{
  active?: boolean;
  grayed?: boolean;
  disabled?: boolean;
  range?: boolean;
  isStart?: boolean;
  isEnd?: boolean;
}>`
  flex: 1;
  display: flex;
  position: relative;
  justify-content: center;
  flex-direction: column;
  min-width: 14.28%;
  max-width: 14.28%;
  text-align: center;
  background: none;
  outline: 0;
  border: 0;
  display: flex;
  align-self: center;
  cursor: pointer;

  :hover > div {
    background: #00000011;
  }

  ${(props) =>
    props.disabled &&
    css`
      color: #00000050;
      pointer-events: none;
    `}

  ${(props) =>
    props.range &&
    !(props.isStart && props.isEnd) &&
    css`
      :before {
        content: ' ';
        background: #eee;
        height: 34px;
        position: absolute;
        top: 0px;
        z-index: -1;
        /* opacity: 0.5; */
        width: 100%;
        ${props.isStart &&
        !props.active &&
        css`
          border-top-left-radius: 100px;
          border-bottom-left-radius: 100px;
        `}
        ${props.isEnd &&
        !props.active &&
        css`
          border-top-right-radius: 100px;
          border-bottom-right-radius: 100px;
        `}
            ${props.active &&
        props.isStart &&
        css`
          width: 50%;
          left: 50%;
        `}
            ${props.active &&
        props.isEnd &&
        css`
          width: 50%;
        `}
      }
      > div {
        /* color: #fff!important; */
      }
    `}

    >div {
    border-radius: 100px;
    width: 34px;
    height: 34px;
    align-self: center;
    align-items: center;
    display: flex;
    justify-content: center;
    color: #444;
    white-space: nowrap;
    font-size: 14px;
    position: relative;

    ${(props) =>
      props.grayed &&
      css`
        color: #00000050;
      `}

    ${(props) =>
      props.active &&
      css`
        background: #007ac2 !important;
        color: #fff !important;
      `}
  }
`;

export const DatepickerTime = styled.div<{
  active: boolean;
  disabled?: boolean;
  range?: boolean;
  isStart?: boolean;
  isEnd?: boolean;
}>`
  padding: 2px 10px;
  position: relative;
  white-space: nowrap;
  color: #444;
  font-size: 14px;
  height: 35px;
  cursor: pointer;

  :hover > div {
    background: #00000011;
  }

  ${(props) =>
    props.range &&
    !(props.isStart && props.isEnd) &&
    css`
      :before {
        content: ' ';
        background: #eee;
        /* background: #007ac2; */
        height: 35px;
        position: absolute;
        top: 0px;
        z-index: -1;
        /* opacity: 0.5; */
        width: 72px;
        ${props.isStart &&
        !props.active &&
        css`
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
        `}
        ${props.isEnd &&
        !props.active &&
        css`
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
        `}
            ${props.active &&
        props.isStart &&
        css`
          height: 50%;
          top: 50%;
        `}
            ${props.active &&
        props.isEnd &&
        css`
          height: 50%;
        `}
      }
      > div {
        /* color: #fff!important; */
      }
    `}

  >div {
    padding: 5px;
    position: relative;
    border-radius: 4px;
    display: flex;
    justify-content: center;

    ${(props) =>
      props.active &&
      css`
        background: #007ac2 !important;
        color: #fff;
      `}

    ${(props) =>
      props.disabled &&
      css`
        color: #00000033;
      `}
  }
`;

export const DatepickerWeekday = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-width: 14.28%;
  max-width: 14.28%;
  text-align: center;
  cursor: pointer;
`;

export const DatepickerButton = styled.div`
  flex: 0 auto;
  padding: 6px;
  padding: 9px;
  cursor: pointer;

  :hover {
    background: #00000011;
  }
`;

export const DatepickerHeader = styled.div`
  flex: 1;
  height: 36px;
  border-bottom: 1px solid #00000022;
  display: flex;
  font-size: 16px;
  overflow: hidden;
  align-items: center;
`;
