import styled from "styled-components";

export const TableUI = styled.table`
  width: 100%;
  table-layout: fixed;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  line-height: 1.5;
  flex: 1 1 auto;

  tbody, thead {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    line-height: 1.5;
    flex: 1 1 auto;
  }

  tr {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
  }

  th {
    white-space: normal;
    justify-content: center;
  }
  th > div {
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  td > .text-ellipsis {
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
  } 

  td, th {
    display: flex;
    flex-flow: row nowrap;
    flex-grow: 1;
    flex-basis: 0;
    padding: 0.2em;
    word-break: break-word;
    /* overflow: hidden; */
    text-overflow: ellipsis;
    min-width: 0px;
    white-space: nowrap;
  }

  /* th {
    margin-bottom: 5px;
  }
  td, th {
    padding: 2px 3px;
  }
  th > div {
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
  } */
  tbody > tr {
    cursor: pointer;
  }
  tbody > tr:hover {
    background: #0000000a;
  }
`;