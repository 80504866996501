import Tooltip from 'components/UI/Tooltip';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { UserType } from 'store';
import styled from 'styled-components';
import { ButtonUI } from 'ui/Button';
import { InputUI } from 'ui/InputUI';
import { Row } from 'ui/Row';
import { Put } from 'utils/Api';
import Lang, { lang, langExists } from 'utils/Lang';
import TypedInput from 'components/Forms/TypedInput';
import { toastError, toastSuccess } from 'components/Toasts/Success';

export const InfoButton = styled.button.attrs({ type: 'button' })`
  border: 0;
  outline: 0;
  background: #007ac2;
  border-radius: 10%;
  color: #fff;
  margin-left: 5px;
  opacity: 0.5;

  :hover {
    opacity: 1;
  }
`;

type Props = {
  user?: UserType;
  table: string;
  data?: any;
  title?: any;
  onChange: (data: any) => void;
  onLoad?: () => void;
  customRender?: (v: any, k: string) => any;
  className?: string;
  style?: React.CSSProperties;
};

type State = {};

class ModalPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { onLoad } = this.props;
    onLoad && onLoad();
  }

  render() {
    const {
      user,
      table,
      children,
      onChange,
      className,
      customRender,
      title,
      style
    } = this.props;
    const { data } = this.props;

    return (
      <div
        className={className}
        style={{ width: '100vw', maxWidth: '600px', ...style }}
      >
        <Row className="mb-3">
          <div className="col font-weight-bold">{title}</div>
        </Row>
        <Row>
          {children}
          {_.map(
            user?.types?.[table],
            (v: any, k: any) =>
              k === 'orderno' ||
              v?.flags?.readonly ||
              (!_.isEmpty(v?.flags?.cond) &&
                _.filter(data, (fv: any, fk: any) => {
                  const cond = v?.flags?.cond?.[fk];
                  if (cond === undefined) return;
                  return !(
                    cond === fv ||
                    cond === fv ||
                    (cond === '1' && (fv === true || fv === 1)) ||
                    (_.startsWith(cond, '#') &&
                      _.toString(fv) ===
                        _.toString(data?.[_.replace(cond, '#', '')])) ||
                    (_.startsWith(cond, '!#') &&
                      _.toString(fv) !==
                        _.toString(data?.[_.replace(cond, '!#', '')]))
                  );
                })?.length > 0) ||
              (customRender && customRender(v, k) === true) || (
                <React.Fragment key={k}>
                  <div
                    className="col-md-4 col-lg-3 mb-1"
                    style={{ display: 'flex' }}
                  >
                    <div>
                      <Lang>{k}</Lang>
                      {!''.match(v?.regex || '') && (
                        <span style={{ color: '#f00' }}> *</span>
                      )}
                    </div>
                    <div style={{ marginLeft: 'auto' }}>
                      {(user?.user?.super === true && (
                        <Tooltip
                          title={(onClose: () => void) => (
                            <>
                              <div className="pb-1">Syötä käännös</div>
                              <div
                                style={{ display: 'flex', flexWrap: 'nowrap' }}
                              >
                                <InputUI
                                  type="text"
                                  defaultValue={lang(k + '_help')}
                                />
                                <ButtonUI
                                  style={{ minWidth: '32px', maxWidth: '32px' }}
                                  onClick={async (e) => {
                                    const val = e?.currentTarget?.parentElement
                                      ?.getElementsByTagName('input')
                                      ?.item(0)?.value;
                                    try {
                                      await Put('translation', {
                                        lang: 'fi',
                                        name: k + '_help',
                                        value: val
                                      });
                                      onClose();
                                      toastSuccess();
                                    } catch (e) {
                                      toastError(e);
                                    }
                                  }}
                                >
                                  <i className="icon-checkmark" />
                                </ButtonUI>
                              </div>
                            </>
                          )}
                        >
                          <InfoButton tabIndex={-1}>?</InfoButton>
                        </Tooltip>
                      )) ||
                        (langExists(k + '_help') && (
                          <Tooltip
                            title={() =>
                              (langExists(table + '_' + k + '_help') && (
                                <Lang>{table + '_' + k + '_help'}</Lang>
                              )) || <Lang>{k + '_help'}</Lang>
                            }
                          >
                            <button
                              style={{
                                border: 0,
                                outline: 0,
                                background: '#444',
                                borderRadius: '10%',
                                color: 'white',
                                marginLeft: '5px'
                              }}
                            >
                              ?
                            </button>
                          </Tooltip>
                        ))}
                    </div>
                  </div>
                  <div
                    className="col-md-8 col-lg-9 mb-2"
                    style={{ minHeight: '32px' }}
                  >
                    {(customRender && customRender(v, k)) || (
                      <TypedInput
                        name={k}
                        value={data?.[k] || ''}
                        typeDef={v}
                        onChange={(e: any) => {
                          onChange({ ...data, [k]: e });
                        }}
                      />
                    )}
                  </div>
                </React.Fragment>
              )
          )}
        </Row>
      </div>
    );
  }
}

export default connect(
  (state: any) => ({
    user: state?.payload?.user
  }),
  {
    setStore: (payload: any) => ({ type: 'set', payload: payload })
  }
)(ModalPage);
