import DropdownV2 from 'components/Dropdown/DropdownV2';
import Image from 'components/UI/Image';
import SystemIcon from 'components/UI/SystemIcon';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { UserType } from 'store';
import { CheckboxUI } from 'ui/Checkbox';
import { Put, Post } from 'utils/Api';
import { lang } from 'utils/Lang';
import DatePicker from './DatePicker';
import Input from './Input';
import JsonTable from './JsonTable';
import moment from 'moment';
import { toastError, toastSuccess } from 'components/Toasts/Success';
import { FileReaderAsync } from 'utils/FileReaderAsync';
import DatePickerV2 from 'components/DatePicker/DatePickerV2';
import { InputDiv, InputUI, NoStyleInput } from 'ui/InputUI';
import styled from 'styled-components';

type Props = {
  typeDef: any;
  name: string;
  value: any;
  onChange: (data: any) => void;
  placeholder?: string;
  readOnly?: boolean;
  style?: React.CSSProperties;
  user?: UserType;
};
type State = {
  timeformat: number,
};

const DurationField = styled.div`
  flex: 1;
  span {
    width: 100%;
    text-align: end;
  }
  @-moz-document url-prefix() {
    span {
      padding-right: 12px;
    }
  }
  @media (min-width: 768px) {
    span {
      transition: padding 100ms ease-out;
    }
    :hover span, input:focus ~ span, input:active ~ span {
      padding-right: 12px;
    }
  }
`;

class TypedInput extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      timeformat: 0, // Used only by duration select
    };
  }

  render() {
    const {
      user,
      typeDef,
      name,
      value,
      onChange,
      placeholder,
      readOnly,
      style
    } = this.props;

    // let { timeformat } = this.state;

    return (
      (typeDef?.type === 'enum' && (
        <DropdownV2
          style={style}
          required={true}
          allowAny={false}
          selected={(e: any) => e?.value === value}
          options={() =>
            _.map(typeDef?.enum, (v: any) => ({
              value: v,
              name: lang(v),
              icon: SystemIcon(v)
            }))
          }
          onChange={(e: any) => {
            onChange(e?.value);
          }}
        />
      )) ||
      (typeDef?.type === 'row' && (
        <DropdownV2
          style={style}
          required={!typeDef?.flags?.allow_any}
          placeholder={lang(name)}
          selected={(e: any) => e?.id === (value?.id || value)}
          allowAny={typeDef?.flags?.allow_any}
          options={(search: string) =>
            Post(
              typeDef.join,
              {
                ...(search && { name: `%${search}%` }),
                ...(user?.settings?.office?.id && {
                  office: user?.settings?.office?.id
                })
              },
              { limit: 1000 }
            )
          }
          onChange={(e: any) => {
            onChange(e);
          }}
        />
      )) ||
      (typeDef?.type === 'join' && (
        <DropdownV2
          style={style}
          multiple
          placeholder={lang(name)}
          selected={(e: any) => _.find(value, (f: any) => f?.id === e?.id)}
          options={(search: string) =>
            Post(
              typeDef.join,
              {
                ...(search && { name: `%${search}%` }),
                ...(user?.settings?.office?.id && {
                  office: user?.settings?.office?.id
                })
              },
              { limit: 1000 }
            )
          }
          onChange={(e: any) => {
            const newValue = value || [];
            if (_.find(newValue, { id: e.id }))
              _.remove(newValue, { id: e.id });
            else newValue.push(e);
            onChange(newValue);
          }}
        />
      )) ||
      (name === 'picture' && (
        <label className="w-100">
          <input
            type="file"
            className="d-none"
            readOnly={typeDef?.flags?.readonly}
            onChange={async (e) => {
              if (!e?.target?.files?.[0]) return;
              try {
                const data = await FileReaderAsync(e.target.files[0]);
                const res = await Put('picture', { data });
                if (!res?.filename) throw res;
                onChange(res.filename);
                toastSuccess('file uploaded');
              } catch (e) {
                toastError(e);
              }
            }}
          />
          <Image thumb src={value} style={{ minHeight: '100px' }} />
        </label>
      )) ||
      (typeDef?.flags?.type === 'info' && (
        <Input
          placeholder={placeholder}
          readOnly={true}
          regex={typeDef?.regex}
          type="text"
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
          }}
        />
      )) ||
      (typeDef?.type === 'boolean' && (
        <CheckboxUI
          checked={value}
          onChange={(e) => {
            onChange(e.target.checked);
          }}
        />
      )) ||
      (typeDef?.type === 'json' && (
        <JsonTable
          value={value}
          onChange={(e: any) => {
            onChange(e);
          }}
        />
      )) ||
      (typeDef?.type === 'float' && (
        <Input
          readOnly={readOnly}
          placeholder={placeholder}
          required={!''.match(typeDef?.regex || '')}
          type="number"
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
          }}
        />
      )) ||
      (typeDef?.flags?.textarea && (
        <Input
          readOnly={readOnly}
          placeholder={placeholder}
          required={!''.match(typeDef?.regex || '')}
          regex={typeDef?.regex}
          type="text"
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
          }}
        />
      )) ||
      (typeDef?.type === 'datetime' && (
        <DatePickerV2
          readOnly={readOnly}
          value={value || ''}
          onChange={(date: any) => {
            onChange(date);
          }}
        />
      )) ||
      (typeDef?.type === 'date' && (
        <DatePickerV2
          readOnly={readOnly}
          onlyDate={true}
          value={value || ''}
          onChange={(date: any) => {
            onChange(date);
          }}
        />
      )) ||
      (typeDef?.type === 'time' && (
        <DatePickerV2
          readOnly={readOnly}
          onlyTime={true}
          value={value || ''}
          onChange={(date: any) => {
            onChange(date);
          }}
        />
      )) || ((name === 'duration' || name === 'min_duration') && (
        <div style={{ display: 'flex' }}>
          <DurationField>
            <InputDiv style={{ display: 'flex', borderRight: 0 }}>
                <NoStyleInput
                  type="number"
                  min={0} max={364}
                  placeholder={lang(name)}
                  value={
                    Math.floor(value / 60 / 24).toString()
                  }
                  onChange={(e) => {
                    let n = parseFloat(e.target.value) || 0;
                    if (n < 0) n = 0;
                    const v = (n * 60 * 24) + (value % (60 * 24));
                    onChange(v);
                  }}
                />
              <span>Vrk.</span>
            </InputDiv>
          </DurationField>
          <DurationField>
            <InputDiv style={{ display: 'flex', borderRight: 0 }}>
                <NoStyleInput
                  type="number"
                  min={0} max={23}
                  placeholder={lang(name)}
                  value={
                    Math.floor((value % (60 * 24)) / 60).toString()
                  }
                  onChange={(e) => {
                    let n = parseFloat(e.target.value) || 0;
                    if (n < 0) n = 0; if (n > 23) n = 23;
                    const days = (Math.floor(value / 60 / 24) * 60 * 24);
                    const mins = (Math.floor(value % 60));
                    onChange(days + mins + (n * 60));
                  }}
                />
              <span>Tuntia</span>
            </InputDiv>
          </DurationField>
          <DurationField>
            <InputDiv style={{ display: 'flex' }}>
                <NoStyleInput
                  type="number"
                  min={0} max={59}
                  placeholder={lang(name)}
                  value={
                    Math.floor(value % 60).toString()
                  }
                  onChange={(e) => {
                    let n = parseFloat(e.target.value) || 0;
                    if (n < 0) n = 0; if (n > 59) n = 59;
                    const v = (Math.floor(value / 60) * 60);
                    onChange(v + (n * 1));
                  }}
                />
              <span>Min.</span>
            </InputDiv>
          </DurationField>
          {/* <InputUI
            placeholder={lang(name)}
            value={
              '' + (
                (timeformat && Math.round(value / timeformat * 100) / 100) ||
                (value / 60 / 24 >= 1 && Math.round(value / 60 / 24 * 100) / 100 || '') ||
                (value / 60 >= 1 && Math.round(value / 60 * 100) / 100 || '') ||
                (Math.round(value * 100) / 100)
              )
            }
            onChange={(e) => {
              const n = parseFloat(e.target.value) || 0;
              if (!timeformat) {
                timeformat = (
                  (value / 60 / 24 >= 1 && (value / 60 / 24) % 1 === 0 && 60 * 24) ||
                  (value / 60 >= 1 && (value / 60) % 1 === 0 && 60) ||
                  1
                );
                this.setState({ timeformat });
              }
              onChange(n * timeformat);
            }}
          />
          <DropdownV2
            style={{ ...style }}
            required={true}
            allowAny={false}
            selected={(e: any) => {
              if (!timeformat) {
                timeformat = (
                  (value / 60 / 24 >= 1 && (value / 60 / 24) % 1 === 0 && 60 * 24) ||
                  (value / 60 >= 1 && (value / 60) % 1 === 0 && 60) ||
                  1
                );
                this.setState({ timeformat });
              }
              if (e.value === timeformat) return true;
            }}
            options={() => ([
              { value: 1, name: 'Minuuttia' },
              { value: 60, name: 'Tuntia' },
              { value: 60 * 24, name: 'Päivää' },
            ])}
            onChange={(e: any) => {
              if (!timeformat) {
                timeformat = (
                  (value / 60 / 24 >= 1 && (value / 60 / 24) % 1 === 0 && 60 * 24) ||
                  (value / 60 >= 1 && (value / 60) % 1 === 0 && 60) ||
                  1
                );
              }
              onChange(value * (e.value / timeformat));
              this.setState({ timeformat: e.value });
            }}
          /> */}
        </div>
      )) || (
        <Input
          readOnly={readOnly}
          placeholder={placeholder}
          required={!''.match(typeDef?.regex || '')}
          regex={typeDef?.regex}
          type="text"
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
          }}
        />
      )
    );
  }
}

export default connect(
  (state: any) => ({
    user: state?.payload?.user
  }),
  {}
)(TypedInput);
