import _ from 'lodash';
import React, { PropsWithChildren } from 'react';
import { SwitchTransition, Transition } from 'react-transition-group';
import { TransitionStatus } from 'react-transition-group/Transition';

type TransitionType = {
  unmounted?: React.CSSProperties;
  entering?: React.CSSProperties;
  entered?: React.CSSProperties;
  exiting?: React.CSSProperties;
  exited?: React.CSSProperties;
};

const transitions: { [name: string]: TransitionType } = {
  slide: {
    unmounted: { maxWidth: 0, maxHeight: 0, overflow: 'hidden' },
    entering: { maxWidth: 0, maxHeight: 0, overflow: 'hidden' },
    entered: { },
    exiting: { maxWidth: 0, maxHeight: '80vh', overflow: 'hidden' },
    exited: { maxWidth: 0, maxHeight: '80vh', overflow: 'hidden' }
  },
  fade: {
    unmounted: {
      filter: 'opacity(0) blur(2px)',
      transform: 'perspective(1000px) rotate3d(1, 0, 0, 45deg)'
    },
    entering: {
      filter: 'opacity(1)',
      transform: 'perspective(1000px) rotate3d(1, 0, 0, 25deg)'
    },
    entered: {},
    exiting: {
      filter: 'opacity(0) blur(2px)',
      transform: 'perspective(1000px) rotate3d(1, 0, 0, 25deg)'
    },
    exited: {
      filter: 'opacity(0) blur(5px)',
      transform: 'perspective(1000px) rotate3d(1, 0, 0, 25deg)'
    }
  },
  slidedown: {
    unmounted: {
      maxHeight: '0px',
      filter: 'opacity(0)',
      transform: 'translateY(-10px)',
      overflow: 'hidden'
    },
    entering: {
      maxHeight: '0px',
      filter: 'opacity(0)',
      transform: 'translateY(-10px)',
      overflow: 'hidden'
    },
    entered: {},
    exiting: {
      maxHeight: '0px',
      filter: 'opacity(0) blur(5px)',
      overflow: 'hidden'
    },
    exited: {
      maxHeight: '0px',
      filter: 'opacity(0) blur(5px)',
      overflow: 'hidden'
    }
  },
  switch: {
    unmounted: { filter: 'opacity(0)', transform: 'translateX(-100%)' },
    entering: { filter: 'opacity(0)', transform: 'translateX(-100%)' },
    entered: {},
    exiting: { filter: 'opacity(0)', transform: 'translateX(100%)' },
    exited: { filter: 'opacity(0)', transform: 'translateX(100%)' }
  },
  switchflip: {
    unmounted: { filter: 'opacity(0)', transform: 'translateX(100%)' },
    entering: { filter: 'opacity(0)', transform: 'translateX(100%)' },
    entered: {},
    exiting: { filter: 'opacity(0)', transform: 'translateX(-100%)' },
    exited: { filter: 'opacity(0)', transform: 'translateX(-100%)' }
  }
};

type Props = {
  show: boolean;
  transition: string | TransitionType;
  content: (style: React.CSSProperties) => JSX.Element;
  duration?: number;
  onExited?: () => void;
};

export const Fade = (props: Props): React.ReactElement => {
  const nodeRef = React.useRef(null);
  const { duration, show, onExited, transition, content } = props;
  const timeout = duration || 200;
  return (
    <Transition
      nodeRef={nodeRef}
      in={show}
      onExited={() => {
        onExited && onExited();
      }}
      unmountOnExit={true}
      mountOnEnter={true}
      timeout={{ appear: 1, enter: 1, exit: timeout }}
    >
      {(state: TransitionStatus) => (
        <div style={{ display: 'contents' }} ref={nodeRef}>
          {content({
            transition: `all ${timeout}ms ease-in-out`,
            ...(_.isString(transition)
              ? transitions[transition][state]
              : transition)
          })}
        </div>
      )}
    </Transition>
  );
};
