import styled, { css } from 'styled-components';

export const Badge = styled.div`
  line-height: 1;
  /* background-color: rgb(40,144,206); */
  /* color: #fff; */
  background: #0000000f;
  color: #222;
  border: 1px solid #00000011;
  white-space: nowrap;
  vertical-align: baseline;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  padding: 0;
  /* border: 1px solid transparent; */
  margin: 1px;
  border-radius: 1px;
  font-family: 'Open Sans' !important;
  max-width: 100%;
  overflow: hidden;
  font-size: 12px;
  font-weight: 400;
  pointer-events: auto;
  min-width: 60px;

  :hover {
    background-color: #007ac2;
  }
`;
