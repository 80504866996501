import { recurCopy } from 'components/Pages/Table';
import { toastSuccess, toastError } from 'components/Toasts/Success';
import React from 'react';
import { connect } from 'react-redux';
import { UserType } from 'store';
import { ButtonUI, SubmitButtonUI } from 'ui/Button';
import { Put } from 'utils/Api';
import Lang from 'utils/Lang';
import { dialog } from './Dialog';

type Props = {
  table: string;
  data: any;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  onClose: () => void;
  onSave?: (data?: any) => void;
  user: UserType;
};

type State = {

};

class ModalFooter extends React.Component<Props, State>
{
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  async save(data: any = {}) {
    const { setLoading, loading, onClose, onSave, table } = this.props;
    if (loading) return;
    setLoading(true);
    try {
      if (onSave && onSave(data)) return;
      const res = await Put(table, data);
      toastSuccess();
      onClose();
      return res;
    } catch (e) {
      toastError(e);
    }
    setLoading(false);
  }

  render() {
    const { data, loading, user, table } = this.props;
    return (
      <>
        {data?.id && (
          <ButtonUI
            red
            style={{ marginRight: 'auto', maxWidth: '100px' }}
            disabled={loading}
            onClick={async () => {
              if (await dialog({ title: 'Poistetaanko?' })) {
                this.save({ ...data, active: 'deleted' });
              }
            }}
          >
            <Lang>delete</Lang>
          </ButtonUI>
        )}

        <div className="btn-group calcite-button-group" role="group">
          {
            data?.id && (
              <SubmitButtonUI clear
                style={{ minWidth: '100px' }}
                disabled={loading}
                onClick={async (e) => {
                  e.preventDefault();
                  if (!e.currentTarget.form?.checkValidity()) return;
                  console.log(recurCopy({ ...data, id: undefined }, user?.types?.[table]));
                  this.save(recurCopy({ ...data, id: undefined }, user?.types?.[table]));
                }}
              >
                <Lang>save as new</Lang>
              </SubmitButtonUI>
            )
          }
          {
            <SubmitButtonUI
              disabled={loading}
              onClick={async (e) => {
                e.preventDefault();
                if (!e.currentTarget.form?.checkValidity()) return;
                this.save(data);
              }}
            >
              <Lang>save</Lang>
            </SubmitButtonUI>
          }
        </div>
      </>
    );
  }
}

export default connect(
  (state: any) => ({
    user: state.payload?.user
  }),
  {
    setStore: (payload: any) => ({ type: 'set', payload: payload })
  }
)(ModalFooter);

