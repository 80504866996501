import React from 'react';
import moment from 'moment';

type Props = {};
type State = {};

export default class Footer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="navbar navbar-expand-lg bg-white footer p-0 pr-4 pl-4">
        <div className="text-center d-lg-none w-100">
          © 2019 - {moment().format('YYYY')}. Oitis
        </div>
        <div id="navbar-footer" className="navbar-collapse collapse">
          <span className="navbar-text">
            © 2019 - {moment().format('YYYY')}. <a href="/">Oitis</a> |{' '}
            <a
              href="https://runtime.fi/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Runtime Oy
            </a>
          </span>
          <ul className="navbar-nav ml-lg-auto">
            <li className="nav-item">
              <a
                href="https://oitis.fi/"
                target="_blank"
                rel="noopener noreferrer"
                className="navbar-nav-link"
              >
                <i className="icon-lifebuoy mr-2" /> Tuki
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
