import styled, { css } from 'styled-components';

export const ModalUI = styled.div.attrs({
  role: 'dialog',
  ariaModal: true,
  tabIndex: -1
})`
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  overflow: auto;
  display: flex !important;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  outline: 0;
`;

export const ModalDialogUI = styled.form.attrs({ role: 'document' })<{
  small?: boolean;
  warning?: boolean;
  danger?: boolean;
}>`
  @media (min-width: 576px) {
    //max-width: 100%;
  }
  @media (min-width: 768px) {
    margin: auto;
    //max-width: 90%;
  }
  @media (min-width: 992px) {
    //max-width: 90%;
  }

  @media (min-width: 1200px) {
    //max-width: 1140px;
  }
  /*     
    ${(props) =>
    props?.small &&
    css`
      @media (min-width: 1200px) {
        max-width: 1140px;
      }
    `} */

  margin: 0 auto;
  position: relative;
  width: 100%;
  border-radius: 0;
  pointer-events: none;
  flex: 0;

  .modal-content {
    border-radius: 0;
    border-top: 4px solid #007ac2;
    ${(props) =>
      props?.warning &&
      css`
        border-top: 4px solid #d9bc00;
      `}
    ${(props) =>
      props?.danger &&
      css`
        border-top: 4px solid #d83020;
      `}

        @media (min-width: 768px) {
      border-radius: 3px;
    }
  }
`;
