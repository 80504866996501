import { WebSocketURL } from 'utils/Api';
import * as Cookies from 'js-cookie';

type WsCallback = (data: any) => void;

export class WebSock {
  _websocket: WebSocket = new WebSocket(WebSocketURL);
  _onMessage: WsCallback[] = [];

  constructor() {
    this.connect();
  }

  onMessage(cb: any) {
    this._onMessage.push(cb);
  }

  connect() {
    this._websocket.onmessage = (e) => {
      try {
        const msg = JSON.parse(e.data);
        for (const messageCb of this._onMessage) messageCb(msg);
      } catch (e) {
        console.log('Websocket error:', e);
      }
    };

    this._websocket.onopen = (e: any) => {
      try {
        if (this._websocket)
          this._websocket.send(JSON.stringify({ token: Cookies.get('token') }));
      } catch (e) {}
    };

    this._websocket.onclose = () => {
      console.log('Reconnecting websocket...');
      // Try to reconnect in 1 second
      setTimeout(() => {
        this._websocket = new WebSocket(WebSocketURL);
        this.connect();
        console.log('Reconnected.');
      }, 1000);
    };
  }
}

// export const createWebSocket = () => {
//     if (!("WebSocket" in window)) return;

//     console.log("WebSocket is supported");
//     _websocket = new WebSocket(WebSocketURL);

//     _websocket.onmessage = (e) => {
//         try {
//             const msg = JSON.parse(e.data);
//             console.log("Message received:", msg);
//         } catch (e) {
//             console.log("Websocket error:", e);
//         }
//     };

//     _websocket.onopen = (e: any) => {
//         try {
//             _websocket.send(JSON.stringify({ token: Cookies.get('token') }))
//         } catch (e) { }
//     }

//     _websocket.onclose = () => {
//         console.log("Connection closed.");
//         // Try to reconnect in 1 second
//         setTimeout(() => {
//             createWebSocket();
//         }, 1000);
//     };
// }
