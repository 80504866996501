import { Fade } from 'components/UI/Fade';
import React from 'react';
import { connect } from 'react-redux';
import store from 'store';
import { ContextMenuDiv } from 'ui/ContextMenu';
import { ModalBackdrop } from 'ui/ModalBackdrop';

export type ContextMenuData = {
  show: boolean;
  x: number;
  y: number;
  content: (onClose: (data: any) => void) => any;
};

type Props = {
  data: ContextMenuData;
  setStore?: any;
};
type State = {};

class ContextMenu extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const { data, setStore } = this.props;
    return (
      <Fade
        show={!!data?.show}
        transition="slidedown"
        duration={50}
        onExited={() => {
          this.setState({ context: null });
        }}
        content={(style) => (
          <>
            <ModalBackdrop
              style={{ ...style, background: 'none' }}
              onContextMenu={() => {
                setStore({ contextMenu: { ...data, show: false } });
              }}
              onClick={() => {
                setStore({ contextMenu: { ...data, show: false } });
              }}
            />
            <ContextMenuDiv x={data?.x} y={data?.y} style={{ ...style }}>
              {data.content(() => {
                setStore({ contextMenu: { ...data, show: false } });
              })}
            </ContextMenuDiv>
          </>
        )}
      />
    );
  }
}

export default connect(
  (state: any) => ({
    data: state?.payload?.contextMenu
  }),
  {
    setStore: (payload: any) => ({ type: 'set', payload: payload })
  }
)(ContextMenu);

export const contextMenu = async (data: {
  x: number;
  y: number;
  content: (onClose: VoidFunction) => any;
}) => {
  return new Promise<boolean>(() => {
    store.dispatch({
      type: 'set',
      payload: {
        contextMenu: {
          ...data,
          show: true
        }
      }
    });
  });
};
