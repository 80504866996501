import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import React from 'react';
import { connect } from 'react-redux';
import { UserType } from 'store';
import { ClearButton } from 'ui/ClearButton';
import { ModalBackdrop } from 'ui/ModalBackdrop';
import { ModalDialogUI, ModalUI } from 'ui/ModalUI';
import { Row } from 'ui/Row';
import Lang from 'utils/Lang';
import { dialog } from './Dialog';

export type Props = {
  user?: UserType;
  onClose: () => void;
  style?: React.CSSProperties;
  children?: any;
  hasId?: boolean;
  title: any;
  footer?: any;
  unsaved: boolean;
  onSubmit?: React.FormEventHandler;
};
type State = {
  loading: boolean;
};

class Modal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    disableBodyScroll(document.querySelector('#modal') || document.body);
  }
  componentWillUnmount() {
    clearAllBodyScrollLocks();
  }

  async confirmClose(e: any) {
    const { onClose, unsaved } = this.props;
    e.preventDefault();
    e.stopPropagation();

    if (
      !unsaved ||
      (await dialog({ title: 'Hylätäänkö tallentamattomat muutokset?' }))
    ) {
      onClose && onClose();
    }
  }

  render() {
    const {
      style,
      onClose,
      children,
      hasId,
      title,
      footer,
      unsaved,
      onSubmit
    } = this.props;
    const { loading } = this.state;

    return (
      <>
        <ModalBackdrop style={style} />
        <ModalUI
          style={style}
          onMouseDown={(e) => {
            if (e.target !== e.currentTarget) return;
            this.confirmClose(e);
          }}
          onTouchEnd={(e) => {
            if (e.target !== e.currentTarget) return;
            this.confirmClose(e);
          }}
        >
          <ModalDialogUI
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit && onSubmit(e);
              return false;
            }}
          >
            <div className="modal-content">
              {/* {loading && <Loader />} */}
              <div className="modal-header">
                <div className="modal-title" style={{ flex: 1 }}>
                  <Lang>{hasId ? 'edit' : 'create new'}</Lang> -{' '}
                  <Lang>{title}</Lang>
                </div>
                <div style={{ flex: 1, textAlign: 'end', fontSize: '1rem' }}>
                  <ClearButton
                    onClick={(e) => {
                      this.confirmClose(e);
                    }}
                  >
                    <i className="icon-cross2" />
                  </ClearButton>
                </div>
              </div>
              <div className="modal-body pt-0" style={{ display: 'flex' }}>
                <Row column>{children}</Row>
              </div>
              <div className="modal-footer">{footer}</div>
            </div>
          </ModalDialogUI>
        </ModalUI>
      </>
    );
  }
}

export default connect(
  (state: any) => ({
    user: state?.payload?.user
  }),
  {
    setStore: (payload: any) => ({ type: 'set', payload: payload })
  }
)(Modal);
