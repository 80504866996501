import TypedInput from 'components/Forms/TypedInput';
import { Fade } from 'components/UI/Fade';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Loader } from 'ui/Loader';
import { Row } from 'ui/Row';
import Lang, { lang } from 'utils/Lang';
import BaseModal from './BaseModal';
import ModalPage from './ModalPage';
import ModalFooter from './ModalFooter';

import { ClearButton } from 'ui/ClearButton';
import { ButtonUI } from 'ui/Button';
import { TableUI } from 'components/UI/TableUI';
import moment from 'moment';
import 'moment/locale/fi';
moment.locale('fi');

type Props = {
  user: any;
  style?: React.CSSProperties;
  data: any;
  onClose: () => void;
};
type State = {
  data: any;
  unsaved: boolean;
  loading: boolean;
};

class Modal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      data: _.cloneDeep({
        ..._.mapValues(props.user?.types?.offices, (v) => v?.default),
        ...props.data
      }),
      unsaved: false,
      loading: true
    };
  }

  render() {
    const { user, style, onClose } = this.props;
    const { unsaved, loading, data } = this.state;

    return (
      <>
        <BaseModal
          style={style}
          unsaved={unsaved}
          hasId={!!data?.id}
          title={<Lang>{'offices'}</Lang>}
          onClose={onClose}
          footer={
            <ModalFooter
              table={'office'}
              data={data}
              loading={loading}
              setLoading={(loading) => {
                this.setState({ loading });
              }}
              onClose={onClose}
            />
          }
        >
          {loading && <Loader />}
          <ModalPage
            title={<Lang>offices</Lang>}
            className="col-md-6 col-lg mt-2"
            table="office"
            data={data}
            customRender={(v, k) => k === 'openings' || k === 'holidays'}
            onLoad={() => {
              this.setState({ loading: false });
            }}
            onChange={(e: any) => {
              this.setState({ data: e, unsaved: !loading });
            }}
          />
          <Fade
            show={true}
            transition="slide"
            content={(style) => (
              <>
                <div
                  className="col-md-6 col-lg mt-2"
                  style={{ width: '100vw', maxWidth: '600px', ...style }}
                >
                  <Row className="mb-3">
                    <div className="col font-weight-bold">
                      <Lang>opening</Lang>
                    </div>
                  </Row>
                  <TableUI>
                    <thead>
                      <tr>
                        {
                          // Listan headeri
                          _.map(
                            user?.types?.opening,
                            (v: any, k: any) =>
                              !v?.flags?.readonly &&
                              k !== 'office' && (
                                <th key={k}>
                                  <div>
                                    <Lang>{k}</Lang>
                                  </div>
                                </th>
                              )
                          )
                        }
                      </tr>
                    </thead>
                    <tbody>
                      {
                        // Listaa rivit
                        _.map(_.range(7), (v) => (
                          <tr key={v}>
                            {_.map(
                              user?.types?.opening,
                              (v2: any, k2: any) =>
                                !v2?.flags?.readonly &&
                                k2 !== 'office' && (
                                  <td key={k2}>
                                  <div>
                                    {(k2 === 'weekday' &&
                                      moment()
                                        .startOf('week')
                                        .add(v, 'day')
                                        .format('dddd')) || (
                                      <TypedInput
                                        name={k2}
                                        placeholder={lang(k2)}
                                        value={
                                          _.find(data?.openings, {
                                            weekday: v
                                          })?.[k2] || ''
                                        }
                                        typeDef={v2}
                                        onChange={(e: any) => {
                                          if (!data?.openings)
                                            data.openings = [];
                                          const f = _.find(data?.openings, {
                                            weekday: v
                                          });
                                          if (f) f[k2] = e;
                                          else {
                                            data.openings.push({
                                              weekday: v,
                                              [k2]: e
                                            });
                                          }
                                          this.setState({ data });
                                        }}
                                      />
                                    )}
                                  </div>
                                  </td>
                                )
                            )}
                          </tr>
                        ))
                      }
                    </tbody>
                  </TableUI>

                  <hr />

                  <Row className="mb-3">
                    <div className="col font-weight-bold">
                      <Lang>holidays</Lang>
                    </div>
                  </Row>
                  <TableUI>
                    <thead>
                      <tr>
                        {
                          // Listan headeri
                          _.map(
                            user?.types?.opening_holiday,
                            (v: any, k: any) =>
                              !v?.flags?.readonly &&
                              k !== 'office' && (
                                <th key={k}>
                                  <div>
                                    <Lang>{k}</Lang>
                                  </div>
                                </th>
                              )
                          )
                        }
                        <th style={{ width: '32px' }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        // Listaa rivit
                        _.map(data?.holidays, (v, k) => (
                          <tr key={k}>
                            {_.map(
                              user?.types?.opening_holiday,
                              (v2: any, k2: any) =>
                                !v2?.flags?.readonly &&
                                k2 !== 'office' && (
                                  <td key={k2}>
                                  <div>
                                    {
                                      <TypedInput
                                        name={k2}
                                        readOnly={
                                          (k2 === 'opentime' ||
                                            k2 === 'closetime') &&
                                          v?.closed
                                        }
                                        placeholder={lang(k2)}
                                        value={v[k2]}
                                        typeDef={v2}
                                        onChange={(e: any) => {
                                          v[k2] = e;
                                          this.setState({ data });
                                        }}
                                      />
                                    }
                                  </div>
                                  </td>
                                )
                            )}
                            <td className="col ellipsis">
                              <ClearButton
                                className="navbar-toggler"
                                small={true}
                                onClick={() => {
                                  // _.remove(data.holidays, v)
                                  data.holidays.splice(k, 1);
                                  this.setState({ data });
                                }}
                              >
                                <i className="icon-cross text-danger" />
                              </ClearButton>
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </TableUI>
                  <Row className="mb-3 mt-3">
                    <div className="col" />
                    <div className="col-auto">
                      <ButtonUI
                        onClick={() => {
                          if (!data?.holidays) data.holidays = [];
                          data.holidays.push({});
                          this.setState({ data });
                        }}
                      >
                        <Lang>add new</Lang>
                      </ButtonUI>
                    </div>
                  </Row>
                </div>
              </>
            )}
          />
        </BaseModal>
      </>
    );
  }
}

export default connect(
  (state: any) => ({
    user: state?.payload?.user
  }),
  {
    setStore: (payload: any) => ({ type: 'set', payload: payload })
  }
)(Modal);
