import { ws } from 'App';
import DropdownV2 from 'components/Dropdown/DropdownV2';
import Input from 'components/Forms/Input';
import ModalOffices from 'components/Modals/ModalOffices';
import ModalPrivileges from 'components/Modals/ModalPrivileges';
import ModalResource from 'components/Modals/ModalResource';
import ModalTable from 'components/Modals/ModalTable';
import ModalUser from 'components/Modals/ModalUser';
import ModalUserLevel from 'components/Modals/ModalUserLevel';
import Pagination from 'components/Pages/Pagination';
import Table from 'components/Pages/Table';
import { Fade } from 'components/UI/Fade';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { UserType } from 'store';
import { ButtonUI } from 'ui/Button';
import { Row } from 'ui/Row';
import { Post } from 'utils/Api';
import Lang, { lang } from 'utils/Lang';

type Props = {
  table: string;
  data?: any;
  user: UserType;
};
type State = {
  modal: any;
  sort: string;
  dir: 'asc' | 'desc';
  offset: number;
  limit: number;
  search: string;
  columns: { [index: string]: boolean };
  showDeleted: boolean;
  view: 'grid' | 'list';
  data: any;
};

class TableCard extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      modal: undefined,
      sort: 'modified',
      dir: 'desc',
      offset: 0,
      limit: 10,
      search: '',
      columns: {},
      showDeleted: false,
      view: 'grid',
      data: props.data || {}
    };
  }

  async updateData() {
    const { user, table } = this.props;
    const { sort, dir, offset, limit, search, showDeleted } = this.state;

    const data = await Post(
      table,
      {
        office: user?.settings?.office?.id,
        ...(search && { name: '%' + search + '%' }),
        ...(!showDeleted && { active: '!deleted' })
      },
      {
        sort: sort,
        dir: dir,
        offset: offset || 0,
        limit: limit || 10
      }
    );

    this.setState({ data });
  }

  componentDidMount() {
    const { table } = this.props;
    this.updateData();

    ws.onMessage((data: any) => {
      if (data?.update === table) {
        this.updateData();
        console.log('WebSocket -> Table updated');
      }
    });
  }

  componentDidUpdate(prevProps: Props) {
    const { user } = this.props;
    if (user?.settings?.office?.id !== prevProps?.user?.settings?.office?.id) {
      this.updateData();
    }
  }

  render() {
    const { table, user } = this.props;
    const {
      modal,
      data,
      sort,
      dir,
      offset,
      limit,
      search,
      columns,
      showDeleted,
      view
    } = this.state;

    return (
      <div className="card">
        <div className="card-header border-0">
          <Row className="align-items-center m-0">
            <div className="col m-0 p-1">
              {/* <span className="h6 text-lg m-0 p-0 mr-3"><Lang>{table}</Lang></span> */}
              {/* <Lang>{table + ' description'}</Lang> */}
              <Input
                placeholder={lang('search')}
                value={search}
                onChange={(e) => {
                  this.setState({ search: e.target.value }, () => {
                    this.updateData();
                  });
                }}
              />
            </div>

            {search && (
              <div className="col-auto p-1">
                {search && 'Haku löysi ' + data?.count + ' tulosta'}
              </div>
            )}

            <div className="col p-1">
              <DropdownV2
                placeholder={lang('rows')}
                selected={(e: any) => e?.value === limit}
                onChange={(e: any) => {
                  this.setState({ limit: e?.value }, () => {
                    this.updateData();
                  });
                }}
                options={() => [
                  { value: 10, name: '10 ' + lang('rows').toLowerCase() },
                  { value: 20, name: '20 ' + lang('rows').toLowerCase() },
                  { value: 50, name: '50 ' + lang('rows').toLowerCase() },
                  { value: 100, name: '100 ' + lang('rows').toLowerCase() }
                ]}
              />
            </div>
            {view === 'list' && (
              <div className="col p-1">
                <DropdownV2
                  value={lang('show columns')}
                  selected={(e: any) => columns?.[e.value]}
                  onChange={(e: any) => {
                    this.setState(
                      {
                        columns: { ...columns, [e.value]: !columns?.[e.value] }
                      },
                      () => {
                        this.updateData();
                      }
                    );
                  }}
                  options={() =>
                    _.map(user?.types?.[table], (v: any, k: string) => ({
                      name: lang(k),
                      value: k
                    }))
                  }
                />
              </div>
            )}
            <div className="col-auto p-1">
              {(user?.user?.super || _.find(user?.user?.user_level?.privileges, { table: table, level: 'create' })) && (
                <ButtonUI
                  onClick={() => {
                    this.setState({
                      modal: {
                        table: table,
                        data: {},
                        onClose: () => {
                          this.setState({ modal: undefined });
                        }
                      }
                    });
                  }}
                >
                  <Lang>create new</Lang>
                  <i className="icon-plus2 ml-2" />
                </ButtonUI>
              )}
            </div>
            <div className="col-auto p-1">
              <ButtonUI
                onClick={() => {
                  this.setState({
                    view: view === 'grid' ? 'list' : 'grid'
                  });
                }}
              >
                <Lang>{view === 'grid' ? 'list' : 'grid'}</Lang>
                {view === 'grid' && <i className="icon-menu7 ml-2" />}
                {view === 'list' && <i className="icon-grid6 ml-2" />}
              </ButtonUI>
            </div>
            <div className="col-auto p-1">
              <ButtonUI
                light={!showDeleted}
                onClick={() => {
                  this.setState({ showDeleted: !showDeleted }, ()=>{
                    this.updateData();
                  });
                }}
              >
                <Lang>show deleted</Lang>
                <i className="icon-eye ml-2" />
              </ButtonUI>
            </div>
          </Row>
        </div>
        <div className="card-body pt-0">
          <Table
            table={table}
            data={data}
            showDeleted={showDeleted}
            view={view}
            columns={columns}
            sort={sort}
            dir={dir}
            onSort={(sorting) => {
              this.setState({ sort: sorting?.sort, dir: sorting?.dir }, () => {
                this.updateData();
              });
            }}
            onModal={(modal) => {
              this.setState({
                modal: {
                  ...modal,
                  onClose: () => {
                    this.setState({ modal: undefined });
                  }
                }
              });
            }}
          />
        </div>
        <div className="text-center pb-2">
          <Pagination
            offset={offset}
            limit={limit}
            count={data?.count}
            onChange={(p: any) => {
              this.setState({ offset: p }, () => {
                this.updateData();
              });
            }}
          />
        </div>
        <Fade
          show={!!modal}
          transition="fade"
          content={(style) =>
            (table === 'resource' && (
              <ModalResource {...modal} style={style} />
            )) ||
            (table === 'privilege' && (
              <ModalPrivileges {...modal} style={style} />
            )) ||
            (table === 'office' && <ModalOffices {...modal} style={style} />) ||
            (table === 'user_level' && <ModalUserLevel {...modal} style={style} />) ||
            (table === 'user' && <ModalUser {...modal} style={style} />) || 
            <ModalTable {...modal} style={style} />
          }
        />
      </div>
    );
  }
}

export default connect(
  (state: any) => ({
    user: state.payload?.user
  }),
  {
    setStore: (payload: any) => ({ type: 'set', payload: payload })
  }
)(TableCard);
