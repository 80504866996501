import { Fade } from 'components/UI/Fade';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { ButtonUI } from 'ui/Button';
import { CheckboxUI } from 'ui/Checkbox';
import { Row } from 'ui/Row';
import { Post, Put } from 'utils/Api';
import Lang from 'utils/Lang';
import BaseModal from './BaseModal';
import { dialog } from './Dialog';
import ModalPage from './ModalPage';
import { toastError, toastSuccess } from 'components/Toasts/Success';
import ModalFooter from './ModalFooter';

type Props = {
  user: any;
  style?: React.CSSProperties;
  table: string;
  data: any;
  onClose: () => void;
};
type State = {
  data: any;
  unsaved: boolean;
  loading: boolean;
  showtables: any;
  dbcolumns: any;
};

class Modal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      data: props.data,
      loading: true,
      unsaved: false,
      showtables: {},
      dbcolumns: {}
    };
  }

  async updateData() {
    const dbcolumns = await Post('dbcolumns', {}, { limit: 1000 });
  }

  render() {
    const { user, style, table, onClose } = this.props;
    const { data, unsaved, showtables, loading, dbcolumns } = this.state;

    return (
      <BaseModal
        style={style}
        unsaved={unsaved}
        hasId={!!data?.id}
        title={<Lang>{table}</Lang>}
        onClose={onClose}
        footer={
          <ModalFooter
            table={'privilege'}
            data={data}
            loading={loading}
            setLoading={(loading) => {
              this.setState({ loading });
            }}
            onClose={onClose}
          />
        }
      >
        <ModalPage
          className="col-md-12"
          table={table}
          data={data}
          customRender={(v: any, k: any) =>
            k === 'crudc' || k === 'crudr' || k === 'crudu' || k === 'crudd'
          }
          //onLoad={() => { this.setState({ loading: false }) }}
          onChange={(e: any) => {
            this.setState({ data: e, unsaved: !loading, loading: false });
          }}
        />

        <div className="col-md-12">
          <Row className="mt-2 mb-2 border-bottom">
            {_.map(
              ['dbtable', 'dbcolumn', 'crudc', 'crudd', 'crudr', 'crudu'],
              (v) => (
                <div className="col-md-2">
                  <Lang>{v}</Lang>
                </div>
              )
            )}
          </Row>
          {_.map(
            _.groupBy(dbcolumns, (f: any) => f?.dbtable?.id),
            (v: any, k: any) => (
              <React.Fragment key={k}>
                <Row>
                  <React.Fragment>
                    <div className="col-md-2 mb-2">
                      <Lang>{v?.[0]?.dbtable?.name}</Lang>
                    </div>
                    <div className="col-md-2 mb-2">
                      <ButtonUI
                        clear
                        style={{ height: '24px' }}
                        onClick={() => {
                          this.setState({
                            showtables: { ...showtables, [k]: !showtables?.[k] }
                          });
                        }}
                      >
                        Näytä kaikki
                      </ButtonUI>
                    </div>
                    <div className="col-md-2 mb-1">
                      <label>
                        <CheckboxUI
                          checked={
                            _.filter(data?.crudc, { id: v?.[0]?.dbtable?.id })
                              ?.length > 0
                          }
                          onChange={(e) => {
                            if (!data.crudc) data.crudc = [];
                            if (
                              _.filter(data?.crudc, { id: v?.[0]?.dbtable?.id })
                                ?.length > 0
                            ) {
                              _.remove(data.crudc, { id: v?.[0]?.dbtable?.id });
                            } else {
                              data.crudc.push({
                                id: v?.[0]?.dbtable?.id,
                                name: v?.[0]?.dbtable?.name
                              });
                            }
                            console.log(data);
                            this.setState({ data: data, unsaved: true });
                          }}
                        />{' '}
                        Luonti
                      </label>
                    </div>
                    <div className="col-md-2 mb-0">
                      <label>
                        <CheckboxUI
                          checked={
                            _.filter(data?.crudd, { id: v?.[0]?.dbtable?.id })
                              ?.length > 0
                          }
                          onChange={(e) => {
                            if (!data.crudd) data.crudd = [];
                            if (
                              _.filter(data?.crudd, { id: v?.[0]?.dbtable?.id })
                                ?.length > 0
                            ) {
                              _.remove(data.crudd, { id: v?.[0]?.dbtable?.id });
                            } else {
                              data.crudd.push({
                                id: v?.[0]?.dbtable?.id,
                                name: v?.[0]?.dbtable?.name
                              });
                            }
                            console.log(data);
                            this.setState({ data: data, unsaved: true });
                          }}
                        />{' '}
                        Poisto
                      </label>
                    </div>
                    <div className="col-md-2 mb-1">
                      <label>
                        <CheckboxUI
                          checked={
                            _.filter(
                              data?.crudr,
                              (f: any) => _.filter(v, { id: f?.id })?.length > 0
                            )?.length > 0
                          }
                          halfchecked={
                            _.filter(
                              data?.crudr,
                              (f: any) => _.filter(v, { id: f?.id })?.length > 0
                            )?.length < v?.length
                          }
                          onChange={(e) => {
                            if (!data.crudr) data.crudr = [];
                            _.remove(
                              data.crudr,
                              (f: any) => _.filter(v, { id: f?.id })?.length > 0
                            );

                            if (e.target.checked) {
                              _.map(v, (v2: any) => {
                                data.crudr.push({ id: v2?.id, name: v2?.name });
                              });
                            }

                            console.log(data);
                            this.setState({ data: data, unsaved: true });
                          }}
                        />{' '}
                        Luku
                      </label>
                    </div>
                    <div className="col-md-2 mb-0">
                      <label>
                        <CheckboxUI
                          checked={
                            _.filter(
                              data?.crudu,
                              (f: any) => _.filter(v, { id: f?.id })?.length > 0
                            )?.length > 0
                          }
                          halfchecked={
                            _.filter(
                              data?.crudu,
                              (f: any) => _.filter(v, { id: f?.id })?.length > 0
                            )?.length < v?.length
                          }
                          onChange={(e) => {
                            if (!data.crudu) data.crudu = [];
                            _.remove(
                              data.crudu,
                              (f: any) => _.filter(v, { id: f?.id })?.length > 0
                            );

                            if (e.target.checked) {
                              _.map(v, (v2: any) => {
                                data.crudu.push({ id: v2?.id, name: v2?.name });
                              });
                            }

                            console.log(data);
                            this.setState({ data: data, unsaved: true });
                          }}
                        />{' '}
                        Kirjoitus
                      </label>
                    </div>
                  </React.Fragment>
                </Row>
                <Fade
                  transition="slidedown"
                  show={showtables?.[k] === true}
                  content={(style) => (
                    <div style={style}>
                      {_.map(v, (v2: any, k2: any) => (
                        <div className="row" key={k2}>
                          <React.Fragment>
                            <div className="col-md-2 mb-2"></div>
                            <div className="col-md-2 mb-2">
                              <Lang>{v2?.name}</Lang>
                            </div>
                            <div className="col-md-2 mb-1"></div>
                            <div className="col-md-2 mb-1"></div>
                            <div className="col-md-2 mb-1">
                              <label>
                                <CheckboxUI
                                  checked={
                                    _.filter(data?.crudr, { id: v2?.id })
                                      ?.length > 0
                                  }
                                  onChange={(e) => {
                                    if (!data.crudr) data.crudr = [];
                                    if (
                                      _.filter(data?.crudr, { id: v2?.id })
                                        ?.length > 0
                                    ) {
                                      _.remove(data.crudr, { id: v2?.id });
                                    } else {
                                      data.crudr.push({
                                        id: v2.id,
                                        name: v2.name
                                      });
                                    }
                                    console.log(data);
                                    this.setState({
                                      data: data,
                                      unsaved: true
                                    });
                                  }}
                                />{' '}
                                Luku
                              </label>
                            </div>
                            <div className="col-md-2 mb-0">
                              <label>
                                <CheckboxUI
                                  checked={
                                    _.filter(data?.crudu, { id: v2?.id })
                                      ?.length > 0
                                  }
                                  onChange={(e) => {
                                    if (!data.crudu) data.crudu = [];
                                    if (
                                      _.filter(data?.crudu, { id: v2?.id })
                                        ?.length > 0
                                    ) {
                                      _.remove(data.crudu, { id: v2?.id });
                                    } else {
                                      data.crudu.push({
                                        id: v2.id,
                                        name: v2.name
                                      });
                                    }
                                    console.log(data);
                                    this.setState({
                                      data: data,
                                      unsaved: true
                                    });
                                  }}
                                />{' '}
                                Kirjoitus
                              </label>
                            </div>
                          </React.Fragment>
                        </div>
                      ))}
                    </div>
                  )}
                />
              </React.Fragment>
            )
          )}
        </div>
      </BaseModal>
    );
  }
}

export default connect(
  (state: any) => ({
    user: state?.payload?.user
  }),
  {
    setStore: (payload: any) => ({ type: 'set', payload: payload })
  }
)(Modal);
