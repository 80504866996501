import BaseDropdown from 'components/BaseDropdown/BaseDropdown';
import DatePickerV2 from 'components/DatePicker/DatePickerV2';
import DropdownV2 from 'components/Dropdown/DropdownV2';
import TypedInput from 'components/Forms/TypedInput';
import { Fade } from 'components/UI/Fade';
import { TableUI } from 'components/UI/TableUI';
import _, { wrap } from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { ButtonUI } from 'ui/Button';
import { ClearButton } from 'ui/ClearButton';
import { Loader } from 'ui/Loader';
import { Row } from 'ui/Row';
import { MinutesToString, TryDateFormat } from 'utils/Dates';
import Lang, { lang } from 'utils/Lang';
import BaseModal from './BaseModal';
import { dialog } from './Dialog';
import ModalFooter from './ModalFooter';
import ModalPage from './ModalPage';
import ModalTable from './ModalTable';

type Props = {
    user: any;
    data: any;
    onClose: () => void;
    onSave: (data: any) => void;
    style?: React.CSSProperties;
};
type State = {
    data: any;
    unsaved: boolean;
    loading: boolean;

    pricingModal:
    | {
        show: boolean;
        table: string;
        data: any;
        loading: boolean;
        onClose?: (data?: any) => void;
    }
    | undefined;
};

class Modal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            data: _.cloneDeep({
                ..._.mapValues(props.user?.types?.pricing, (v) => v?.default),
                ...props.data
            }),
            unsaved: false,
            loading: true,
            pricingModal: undefined
        };
    }

    render() {
        const { user, style, onClose, onSave } = this.props;
        const { unsaved, loading, data } = this.state;

        return (
            <BaseModal
                style={style}
                unsaved={unsaved}
                hasId={!!data?.id}
                title={<Lang>{'pricing'}</Lang>}
                onClose={() => {
                    onClose();
                }}
                footer={
                    <ModalFooter
                        table={'pricing'}
                        data={data}
                        loading={loading}
                        setLoading={(loading) => {
                            this.setState({ loading });
                        }}
                        onSave={(data: any)=>{
                            return onSave(data);
                        }}
                        onClose={() => {
                            onClose();
                        }}
                    />
                }
            >
                <ModalPage
                    title={<Lang>pricing</Lang>}
                    className="col-md-6 col-lg mt-2"
                    table="pricing"
                    data={data}
                    customRender={(v, k) => (
                        (k === 'resource' && true) 
                        // || (
                        //     (
                        //         (k === 'start' && data?.repeat === 'repeat_daily') ||
                        //         (k === 'ending' && data?.repeat === 'repeat_daily')
                        //     ) &&
                        //     <>
                        //         <DatePickerV2
                        //             onlyTime={true}
                        //             value={data?.[k] || ''}
                        //             onChange={(e: any) => {
                        //                 data[k] = e;
                        //                 this.setState({
                        //                     data,
                        //                     unsaved: true,
                        //                 });
                        //             }}
                        //         />
                        //     </>
                        // )
                    )}
                    onLoad={() => {
                        this.setState({ loading: false });
                    }}
                    onChange={(e: any) => {
                        this.setState({
                            data: e,
                            unsaved: true,
                        });
                    }}
                />
            </BaseModal>
        );
    }
}

export default connect(
    (state: any) => ({
        user: state?.payload?.user
    }),
    {
        setStore: (payload: any) => ({ type: 'set', payload: payload })
    }
)(Modal);
