import { dialog } from 'components/Modals/Dialog';
import { contextMenu } from 'components/ContextMenu';
import { printContract } from 'components/Modals/ModalSchedule';
import { toastError, toastSuccess } from 'components/Toasts/Success';
import _ from 'lodash';
import moment, { Moment, unitOfTime } from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { UserType } from 'store';
import styled from 'styled-components';
import { Row } from 'ui/Row';
import { Put } from 'utils/Api';
import { MinutesToString, RoundDate, SqlDate } from 'utils/Dates';
import Lang from 'utils/Lang';

import './Calendar.css';
import Backdrop from 'components/UI/Backdrop';
import { ModalBackdrop } from 'ui/ModalBackdrop';
import ModalTable from 'components/Modals/ModalTable';
import { ClearButton } from 'ui/ClearButton';
import { Fade } from 'components/UI/Fade';
import ModalPricing from 'components/Modals/ModalPricing';

export const TimeblockResizer = styled.div`
  position: absolute;
  right: 0px;
  width: 8px;
  height: 100%;
  top: 0px;
  cursor: e-resize;
  pointer-events: auto;

  :hover {
    background: #00000025;
  }
`;

export const EditPrice = styled.div`
  display: flex;
  align-items: center;
  padding: 1px;
  border-radius: 2px;
  cursor: pointer;
  i {
    display: none;
  }
  :hover {
    background: #00000011;
    i {
      display: block;
    }
  }
`;

const MonthBlock = styled.div`
  flex: 1;
  min-width: ${100 / 7}%;
  max-width: ${100 / 7}%;
  min-height: 100px;
  border: 1px solid #00000022;
  position: relative;
  > div {
    padding: 2px 4px;
  }
  @media (max-width: 600px) {
    min-width: 100%;
  }
`;

type Props = {
  date?: number;
  dateformat: string;
  range: { start: number; end: number; step: number };
  onSelect: (data: any, onDeselect?: VoidFunction) => void;
  options?: any;
  user: UserType;
  resource: any;
  schedules: any;
  onChange: () => void,
  setStore: any;
};

type State = {
  select: any;
  drag: any;
  now: number;

  pricingModal: {
    show: boolean;
    data: any;
    add?: boolean;
  } | undefined;
};

class PriceCalendar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      select: null,
      drag: null,
      now: +Date.now(),
      pricingModal: undefined
    };
  }

  render() {
    const {
      date,
      range,
      options,
      schedules,
      onChange,
      resource
    } = this.props;
    const { pricingModal } = this.state;

    const rangeStart =
      range?.start || new Date(date || Date.now()).setHours(6, 0, 0, 0);
    const rangeEnd =
      range?.end || new Date(date || Date.now()).setHours(20, 0, 0, 0);

    const pricing = resource?.pricing;

    return (
      <>
        <div className="container-fluid calendar">
          <div
            className="row"
            style={{ boxShadow: '0 1px 0 #ddd, 1px 0 0 #ddd' }}
          >
            <div
              className="col p-0"
              style={{
                overflow: 'hidden',
                display: 'flex',
                flex: 1,
                flexWrap: 'wrap'
              }}
            >
              {_.map(
                _.range(moment(rangeEnd).diff(moment(rangeStart), 'd')),
                (v) => {
                  const date = moment(rangeStart).add(v, 'd'); //moment().startOf('month').add(v, 'd');
                  return (
                    <MonthBlock key={v}>
                      <div
                        style={{
                          fontWeight: 'bold',
                          width: '100%',
                          background: '#00000005',
                          // background: '#007ac2',
                          // color: '#fff',
                          borderBottom: '1px solid #00000022'
                        }}
                      >
                        {date.format('dddd D.M.Y')}
                      </div>
                      <div>
                        {pricing?.length > 0 &&
                          _.map(pricing, (v2: any, k2) => {
                            return (
                              (v2?.repeat === 'repeat_daily' ||
                                moment(v2?.start).weekday() ===
                                  date.weekday()) && (
                                <EditPrice
                                  key={k2}
                                  onClick={() => {
                                    this.setState({
                                      pricingModal: {
                                        show: true,
                                        data: pricing[k2],
                                      }
                                    });
                                  }}
                                >
                                  {
                                    v2?.type === 'duration_over' &&
                                    'Ylimenevät '
                                  }
                                  {MinutesToString(v2?.duration)}
                                  <span
                                    style={{
                                      fontWeight: 'bold',
                                      margin: '0 5px',
                                    }}
                                  >
                                    <>{v2?.price} € </>
                                  </span>
                                  {
                                    v2?.description &&
                                    <> { v2.description }</>
                                  }

                                  <div style={{ marginLeft: 'auto' }}>
                                    <ClearButton small
                                      onClick={async (e) => {
                                        e.stopPropagation();
                                        const confirm = await dialog({
                                          title: (
                                            <>
                                              <div className="mb-2">
                                                Poistetaanko hinta?
                                              </div>
                                              <div>
                                                {MinutesToString(v2?.duration)}
                                                <span
                                                  style={{
                                                    fontWeight: 'bold',
                                                    marginLeft: '3px'
                                                  }}
                                                >
                                                  {v2?.price} €
                                                </span>
                                              </div>
                                            </>
                                          )
                                        });
                                        if (!confirm) return;
                                        pricing.splice(k2, 1);
                                      }}
                                    >
                                      <i className="icon-cross text-danger" />
                                    </ClearButton>
                                  </div>
                                </EditPrice>
                              )
                            );
                          })}
                        <EditPrice
                          onClick={() => {
                            if (!resource?.pricing) resource.pricing = [];
                            this.setState({
                              pricingModal: {
                                show: true,
                                data: {
                                  resource: resource?.id,
                                },
                                add: true,
                              }
                            });
                          }}
                        >
                          <ClearButton small style={{ marginRight: '5px' }}>
                          <i
                            className="icon-plus"
                            style={{
                              color: '#5a5',
                              display: 'block'
                            }}
                          />
                          </ClearButton>
                          Lisää uusi hinta
                        </EditPrice>
                      </div>
                    </MonthBlock>
                  );
                }
              )}
            </div>
          </div>
        </div>
        <Fade
          transition="fade"
          show={!!pricingModal?.show}
          onExited={() => {
            this.setState({ pricingModal: undefined });
          }}
          content={(style) =>
            pricingModal ? (
              <ModalPricing
                style={style}
                data={pricingModal.data}
                onSave={(changes: any)=>{
                  if (pricingModal?.add) {
                    resource.pricing.push(changes);
                  } else {
                    _.map(changes, (v,k)=>{ pricingModal.data[k] = changes[k]; });
                  }
                  pricingModal.show = false;
                  this.setState({ pricingModal });
                }}
                onClose={()=>{
                  if (pricingModal) pricingModal.show = false;
                  this.setState({ pricingModal });
                }}
              />
            ) : (
              <></>
            )
          }
        />
      </>
    );
  }
}

export default connect(
  (state: any) => ({
    user: state?.payload?.user
  }),
  {
    setStore: (payload: any) => ({ type: 'set', payload: payload })
  }
)(PriceCalendar);
