import React from 'react';
import _ from 'lodash';
import { Fade } from 'components/UI/Fade';
import Backdrop from 'components/UI/Backdrop';

type Props = { children: any; title: (data: any) => void };
type State = { show: boolean; lock: boolean };

export default class Tooltip extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      show: false,
      lock: false
    };
  }

  render() {
    const { children, title } = this.props;
    const { show, lock } = this.state;

    return (
      <span
        onMouseOver={() => {
          this.setState({ show: true });
        }}
        onMouseOut={() => {
          if (!lock) this.setState({ show: false });
        }}
      >
        {show && lock && (
          <Backdrop
            onClick={() => {
              this.setState({ lock: false, show: false });
            }}
          />
        )}
        <Fade
          show={show}
          transition="fade"
          content={(style) => (
            <div
              className="tooltip bs-tooltip-top"
              role="tooltip"
              style={{
                minWidth: '200px',
                ...style,
                ...(!lock && { pointerEvents: 'none' })
              }}
            >
              <div className="arrow"></div>
              <div className="tooltip-inner">
                {(title instanceof Function &&
                  title(() => {
                    this.setState({ show: false, lock: false });
                  })) ||
                  ''}
              </div>
            </div>
          )}
        />
        <div
          onClick={() => {
            this.setState({ lock: !lock });
          }}
          style={{
            transition: 'all 100ms ease-in-out',
            display: 'inline-block',
            ...(lock && { transform: 'scale(1.1)' })
          }}
        >
          {children}
        </div>
      </span>
    );
  }
}
